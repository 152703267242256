import { IAuth, IMerchantData } from "types";
import instance, { formDataInstance } from "../baseRequest";

export const authApi = {
  login: async (body: IAuth) => await instance.post("/v1/auth/signin", body),
  getUser: async () => await instance.get("/v1/users/user"),
  signUp: async (body: IMerchantData) =>
    await formDataInstance.post("/v1/auth/signup", body),
  verifyEmail: async (token: string, params: IAuth) =>
    await instance.get(`/v1/auth/verify/${token}`, { params }),
  forgotPassword: async (body: IAuth) =>
    await instance.post("/v1/auth/forgot-password", body),
  resetPassword: async (body: IAuth, token: string) =>
    instance.post(`/v1/auth/reset-password/${token}`, body),
  setPassword: async (body: IAuth, id: string) =>
    await instance.post(`/v1/auth/set-password/${id}`, body),
  enableTwoFa: async (id: string) =>
    await instance.post(`/v1/auth/enable-2fa/${id}`),
  verifyTwoFa: async (body: IAuth, id: string) =>
    await instance.post(`/v1/auth/verify-2fa/${id}`, body),
  disableTwoFa: async (id: string, body: IAuth) =>
    await instance.post(`/v1/auth/disable-2fa/${id}`, body),
  createRequest: async (body: IMerchantData) =>
    await formDataInstance.post("/v1/admin/merchants/create", body),
  requestForMerchant: async (body: IMerchantData) =>
    await formDataInstance.post("/v1/admin/merchants/request", body),
  changePassword: async (body: IAuth) =>
    await instance.post("/v1/auth/change-password", body),
  markUserAsLoggedIn: async () =>
    await instance.patch("/v1/users/make-logged-in"),
};
