import {
  IAuthData,
  IError,
  IMerchantTradesParams,
  ITradeParamsData,
  ITradeActivation,
  ICreateTrade,
  IMerchantRequestsParams,
  IRatingData,
} from "types";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { tradesApi } from "service";
import { TOAST_TYPES } from "constants/toast";
import { setToastData } from "reduxState/features/toast";

export const createTrade = createAsyncThunk(
  "createTrade",
  async ({ data, navigate }: ICreateTrade, { rejectWithValue, dispatch }) => {
    try {
      const res = await tradesApi.createTrade(data);
      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );
      if (navigate) navigate();

      return res.data;
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const getTrades = createAsyncThunk(
  "getTrades",
  async ({ params }: ITradeParamsData, { rejectWithValue }) => {
    try {
      const { data } = await tradesApi.getTrades(params);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const requestTrade = createAsyncThunk(
  "requestTrade",
  async ({ data, navigate }: IAuthData, { rejectWithValue, dispatch }) => {
    try {
      const res = await tradesApi.requestTrade(data);
      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );
      if (navigate) navigate();
      return res;
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const getMerchantTrades = createAsyncThunk(
  "getMerchantTrades",
  async ({ params }: IMerchantTradesParams, { rejectWithValue }) => {
    try {
      const { data } = await tradesApi.merchantTrades(params);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeTradeStatus = createAsyncThunk(
  "changeTradeStatus",
  async (
    { id, data, navigate, tab }: IAuthData & { tab: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await tradesApi.changeTradeStatus(Number(id), data);

      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );
      dispatch(
        getMerchantTrades({
          params: { filters: { status: tab, state: "open" } },
        })
      );
      if (navigate) navigate();
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );

      return rejectWithValue(error);
    }
  }
);

export const closeTrade = createAsyncThunk(
  "closeTrade",
  async (
    { id, data, navigate, tab }: IAuthData,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await tradesApi.closeTrade(Number(id), data);

      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );
      dispatch(
        getMerchantTrades({
          params: { filters: { status: tab as string, state: "open" } },
        })
      );
      if (navigate) navigate();
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );

      return rejectWithValue(error);
    }
  }
);

export const merchantTradeRequests = createAsyncThunk(
  "merchantTradeRequests",
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const { data } = await tradesApi.merchantTradeRequests(id);
      return data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getMerchantTradeRequests = createAsyncThunk(
  "getMerchantTradeRequests",
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const { data } = await tradesApi.getMerchantTradeRequests(id);
      return data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const acceptCancelRequest = createAsyncThunk(
  "acceptCancelRequest",
  async (
    { id, data, navigate, tradeId, params }: ITradeActivation,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await tradesApi.acceptCancelRequest(Number(id), data);

      if (params) {
        dispatch(getMerchantTrades({ params }));
      }
      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );
      if (tradeId) dispatch(merchantTradeRequests({ id: tradeId }));

      if (navigate) navigate();

      return data.status;
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );

      if (navigate) navigate();

      return rejectWithValue(error);
    }
  }
);

export const acceptCancelRequestUser = createAsyncThunk(
  "acceptCancelRequestUser",
  async (
    { id, data, navigate }: ITradeActivation,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await tradesApi.acceptCancelRequestUser(Number(id), data);

      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );

      if (navigate) navigate();
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );

      if (navigate) navigate();

      return rejectWithValue(error);
    }
  }
);

export const getUserTradeRequests = createAsyncThunk(
  "getUserTradeRequests",
  async ({ params }: IMerchantRequestsParams, { rejectWithValue }) => {
    try {
      const { data } = await tradesApi.getUserTradeRequests(params);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMerchantRequests = createAsyncThunk(
  "getMerchantRequests",
  async ({ params }: IMerchantRequestsParams, { rejectWithValue }) => {
    try {
      const { data } = await tradesApi.getMerchantRequests(params);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUserTradeRequestById = createAsyncThunk(
  "getUserTradeRequestById",
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const { data } = await tradesApi.getUserTradeRequestById(id);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const chatArbitrageRequest = createAsyncThunk(
  "chatArbitrageRequest",
  async ({ id }: { id: string }, { rejectWithValue, dispatch }) => {
    try {
      await tradesApi.chatArbitrageRequest(id);
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const userRateRequest = createAsyncThunk(
  "userRateRequest",
  async ({ data }: IRatingData, { rejectWithValue, dispatch }) => {
    const res = await tradesApi.userRateRequest(data);

    dispatch(
      setToastData({
        type: TOAST_TYPES.success,
        message: res.data.message,
        open: true,
      })
    );

    try {
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );

      return rejectWithValue(error);
    }
  }
);
