import { Dispatch, FC, SetStateAction } from "react";
import { Checkbox } from "./checkbox";
import { useTranslation } from "localization";

interface IAgreePolicy {
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
}

export const AgreeWithPolicy: FC<IAgreePolicy> = ({ checked, setChecked }) => {
  const translation = useTranslation();

  return (
    <div className="agreement_policy">
      <Checkbox checked={checked} setChecked={setChecked} />
      <div className="terms_and_policy">
        <p className="terms_policy_text">
          {translation.im_agree}{" "}
          <a
            href="/privacy-policy"
            className="clickable_policy"
            target="_blank"
          >
            {translation.privacy_policy}
          </a>{" "}
          {translation.and}{" "}
          <a href="/terms-of-use" className="clickable_policy" target="_blank">
            {translation.terms_of_use}
          </a>
        </p>
      </div>
    </div>
  );
};
