import { Dispatch, FC, SetStateAction, useState } from "react";

import { useTranslation } from "localization";
import { DateInput, FilterMultipleSelect } from "components";
import { ITableFilters } from "types";
import { SOURCE_FILTER } from "constants/table";

interface ITableFiltersTypes {
  setFilters: Dispatch<SetStateAction<ITableFilters>>;
  openFilters: boolean;
}

export const TableFilters: FC<ITableFiltersTypes> = ({
  setFilters,
  openFilters,
}) => {
  const [date, setDate] = useState<string>("");
  const [selectedSourceName, setSelectedSourceName] = useState<string[]>([]);
  const [selectedSourceId, setSelectedSourceId] = useState<number[]>([]);
  const [openSource, setOpenSource] = useState<boolean>(false);

  const translation = useTranslation();

  return (
    <div
      className={`table_filters balance_table_filter ${
        openFilters ? "filters_open balance_filters_open" : "filters_close"
      }`}
    >
      <div className="source_filter">
        <FilterMultipleSelect
          name="status"
          openSelect={openSource}
          setOpenSelect={setOpenSource}
          selectedNames={selectedSourceName}
          setSelectedNames={setSelectedSourceName}
          selectedIds={selectedSourceId}
          setSelectedIds={setSelectedSourceId}
          data={SOURCE_FILTER}
          setFilters={setFilters}
          isLocalIcon={true}
        />
      </div>
      <div className="date_filter">
        <DateInput
          name="date"
          value={date}
          setValue={setDate}
          placeholder={translation.date}
          setFilters={setFilters}
        />
      </div>
    </div>
  );
};
