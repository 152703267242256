import { useAppDispatch, useAppSelector } from "reduxState/store";
import { PolicyComponent } from "components";
import { useEffect } from "react";
import { getTermsOfUse } from "reduxState";

export const TermsOfUse = () => {
  const data = useAppSelector((state) => state.content.terms);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTermsOfUse());
  }, []);

  return <PolicyComponent title={data?.title} content={data?.content} />;
};
