import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "reduxState/store";
import { GLOBAL, USER_TYPES } from "constants/global";
import {
  removeMerchantRequestById,
  removeUserRequestById,
} from "reduxState/features/trades";

import timerIcon from "assets/images/timer.svg";
import timerRedIcon from "assets/images/timerRed.svg";

interface IRequestTimer {
  date: Date;
  customClass?: string;
  isArbitraged: boolean;
  elementId?: number | null;
}

export const RequestTimer: FC<IRequestTimer> = ({
  date,
  customClass = "",
  isArbitraged,
  elementId,
}) => {
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const targetDate: Date = new Date(date);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const querySearch = new URLSearchParams(useLocation().search);

  const user = useAppSelector((state) => state.auth.user);
  const isMerchant = user?.role === USER_TYPES.merchant;

  const getTimeRemaining = () => {
    const millisecondsTargetDate = targetDate.getTime();
    const millisecondsCurrentTime = currentTime.getTime();
    const totalTime = millisecondsTargetDate - millisecondsCurrentTime;

    const seconds = `${Math.floor((totalTime / 1000) % 60)}`;
    const minutes = `${Math.floor((totalTime / (1000 * 60)) % 60)}`;

    const checkedSeconds =
      totalTime > 1000
        ? seconds.length === 1
          ? `0${seconds}`
          : seconds
        : "00";
    const checkedMinutes =
      totalTime > 1000
        ? minutes.length === 1
          ? `0${minutes}`
          : minutes
        : "00";

    return {
      checkedMinutes: checkedMinutes,
      checkedSeconds: checkedSeconds,

      totalTime,
    };
  };
  const { totalTime, checkedMinutes, checkedSeconds } = getTimeRemaining();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    if (totalTime < 1000 || isArbitraged) {
      if (!isArbitraged) {
        isMerchant
          ? querySearch.delete(GLOBAL.request_id)
          : querySearch.delete(GLOBAL.id);

        if (elementId) {
          if (isMerchant) {
            dispatch(removeMerchantRequestById(elementId));
          } else {
            dispatch(removeUserRequestById(elementId));
            querySearch.delete(GLOBAL.request_id);
          }
        }
        navigate(`?${querySearch.toString()}`);
      }
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [totalTime]);

  return (
    <div className={`request_timer ${customClass}`}>
      <img
        src={customClass ? timerRedIcon : timerIcon}
        alt="Timer"
        className="timer_icon"
      />
      <div className="timer">
        <p className="timer_filed">{`${checkedMinutes} : ${checkedSeconds}`}</p>
      </div>
    </div>
  );
};
