import { UserWithdrawal } from "components";

import { ProfilePage } from "./Profile";

export const Withdraw = () => {
  return (
    <ProfilePage>
      <UserWithdrawal />
    </ProfilePage>
  );
};
