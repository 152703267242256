import { FC } from "react";

interface IStatistic {
  count: string;
  text: string;
  sub?: string;
}

export const StatisticElement: FC<IStatistic> = ({ count, text, sub }) => {
  return (
    <div className="statistic_element">
      <div className="statistic_count">
        {count}
        {sub && <sup className="sup_count">{sub}</sup>}
      </div>
      <div className="statistic_text">{text}</div>
    </div>
  );
};
