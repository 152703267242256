import { FC } from "react";
import ClipLoader from "react-spinners/ClipLoader";

interface IClipSpinner {
  color?: string;
  loading: boolean;
  customClass?: string;
}

export const PageLoading: FC<IClipSpinner> = ({
  color = "grey",
  loading,
  customClass = "",
}) => {
  return (
    <div className={`page_loading ${customClass}`}>
      <ClipLoader
        color={color}
        loading={loading}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};
