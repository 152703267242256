import { FC } from "react";
import { TowFactorLabel } from "./TwoFactorLables";
import { MainButton } from "components/buttons";
import { useAppSelector } from "reduxState/store";
import { useTranslation } from "localization";

interface IUserActions {
  icon: string;
  text: string;
  onClick: () => void;
  showLabel?: boolean;
  isDisabled?: boolean;
}

export const UserActions: FC<IUserActions> = ({
  icon,
  text,
  onClick,
  showLabel,
  isDisabled = false,
}) => {
  const user = useAppSelector((state) => state.auth.user);

  const translation = useTranslation();

  return (
    <div
      className={`user_action ${
        showLabel || isDisabled ? "cursor_mouse" : ""
      } ${isDisabled ? "disable_user_action" : ""}`}
      onClick={showLabel || isDisabled ? () => {} : onClick}
    >
      <div className="user_action_left_side">
        <div className="action_image_field">
          <img src={icon} alt="Change" className="user_action_icon" />
        </div>
        <div className="user_action_info">
          <p className="user_action_name">{text}</p>
        </div>
        {showLabel && <TowFactorLabel />}
      </div>
      {showLabel && (
        <MainButton
          name={
            user?.isEnabled2FA ? translation.inactivate : translation.activate
          }
          onClick={onClick}
          customClass={`${
            user?.isEnabled2FA ? "inactive_button" : "active_button"
          } width_fit`}
        />
      )}
    </div>
  );
};
