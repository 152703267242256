import { useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "localization";
import { MainButton, TextInput } from "components";
import { FormContainer } from "../FormContainer";
import { InfoSection } from "./InfoSection";
import { useAppDispatch } from "reduxState/store";
import { setOpenToast } from "reduxState/features/toast";
import { GLOBAL, PASSWORD_REGEX, PATHNAMES } from "constants/global";
import { setPassword, verifyEmail } from "reduxState";

export const SetPasswordForm = () => {
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const query = new URLSearchParams(useLocation().search);
  const token = query.get(GLOBAL.token);
  const id = query.get(GLOBAL.id);

  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSetPassword = () => {
    localStorage.removeItem(GLOBAL.verify_token);
    navigate(PATHNAMES.sign_in);
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    dispatch(
      setPassword({ data, id: id as string, navigate: handleSetPassword })
    ).finally(() => {
      setTimeout(() => {
        dispatch(setOpenToast(false));
      }, 3000);
    });
  };

  useEffect(() => {
    if (!sessionStorage.getItem(GLOBAL.verify_token) && !!token) {
      dispatch(
        verifyEmail({ data: { token }, params: { userId: id as string } })
      ).finally(() => {
        setTimeout(() => {
          dispatch(setOpenToast(false));
        }, 3000);
      });

      sessionStorage.setItem(GLOBAL.verify_token, token);
    }
  }, []);

  return (
    <div className="sign_up_form">
      <FormContainer>
        <div className="sign_up_content">
          <h1 className="form_title">{translation.set_password}</h1>
          <form className="form_content" onSubmit={handleSubmit(onSubmit)}>
            <div className="image_input">
              <TextInput
                type={showNewPassword ? "text" : "password"}
                name="password"
                label={translation.password}
                isPassword={true}
                showPassword={showNewPassword}
                handleShowPassword={handleShowNewPassword}
                register={register}
                pattern={PASSWORD_REGEX}
                patternError={translation.pattern_error}
                errors={errors}
                setValue={setValue}
              />
              {!errors.password && <InfoSection />}
            </div>
            <TextInput
              type={showConfirmPassword ? "text" : "password"}
              name="passwordConfirmation"
              label={translation.confirm_password}
              isPassword={true}
              showPassword={showConfirmPassword}
              handleShowPassword={handleShowConfirmPassword}
              register={register}
              errors={errors}
              setValue={setValue}
              validate={() =>
                watch("password") === watch("passwordConfirmation")
              }
            />
            <div className="sign_up_button">
              <MainButton
                name={translation.set_password}
                customClass="custom_button width_100"
                type="submit"
              />
            </div>
          </form>
        </div>
      </FormContainer>
    </div>
  );
};
