import { ITradeRequestResponse } from "types/trades";

export function isTradeResponse(
  response: unknown
): response is ITradeRequestResponse {
  if (
    typeof response === "object" &&
    response !== null &&
    "payload" in response
  ) {
    const payload = (response as any).payload;
    if (typeof payload === "object" && payload !== null && "data" in payload) {
      const dataLevel1 = payload.data;
      if (
        typeof dataLevel1 === "object" &&
        dataLevel1 !== null &&
        "data" in dataLevel1
      ) {
        const dataLevel2 = dataLevel1.data;
        if (
          typeof dataLevel2 === "object" &&
          dataLevel2 !== null &&
          "data" in dataLevel2
        ) {
          const dataLevel3 = dataLevel2.data;
          if (
            typeof dataLevel3 === "object" &&
            dataLevel3 !== null &&
            "id" in dataLevel3
          ) {
            return true;
          }
        }
      }
    }
  }
  return false;
}
