interface TranslationList {
  [key: string]: string;
}

export const localizationEnglish: TranslationList = {
  home: "Home",
  merchants: "Merchants",
  faq: "FAQ",
  login: "Log In",
  sign_up: "Sign Up",
  support: "Support",
  usa: "Eng",
  rus: "Rus",
  arm: "Arm",
  copyright: "Copyright ©",
  copyright_crypto: "Kobbex",
  about: "About",
  about_us: "About Us",
  ongoing_trades: "Ongoing Trades",
  legal: "Legal",
  terms_of_use: "Terms of Use",
  privacy_policy: "Privacy Policy",
  contact_us: "Contact Us",
  social_media: "Social Media",
  name_input: "Name/Surname",
  email: "Email*",
  message: "Message",
  all: "All",
  buy: "Buy",
  sell: "Sell",
  search: "Search",
  filter: "Filter",
  merchant: "Merchant",
  currency: "Currency",
  amount: "Amount",
  price_rate: "Price Rate (USD)",
  banks: "Banks",
  trade: "Trade",
  date_of_birth: "Date of Birth",
  phone_number: "Phone Number*",
  passport_number: "Passport Number",
  passport_series: "Passport Series",
  passport_image: "Passport/ ID Card Image*",
  passport_image_without_mandatory: "Passport/ ID Card Image",
  password: "Password*",
  confirm_password: "Confirm Password*",
  info_title:
    "Please upload an image of yourself holding your passport. Ensure that:",
  done: "Done",
  already_have_account: "Already have an account?",
  telegram_name: "Telegram Name",
  nickname: "Nickname*",
  first_name: "First Name*",
  last_name: "Last Name*",
  dont_have_account: "Don’t have an account yet?",
  create_trade: "Create Trade",
  my_wallet: "My Wallet",
  forgot_password: "Forgot password?",
  two_factor_auth: "Two-Factor Authentication",
  two_factor_auth_desc:
    "Download Microsoft’s or Google’s authenticator app on your mobile device and scan this QR code.",
  cant_scan_qr: "Can’t scan the QR?",
  qr_scan_desc:
    "In case you can’t scan the QR code above insert this code in your authenticator app",
  enter_verification_code: "Enter the Verification Code",
  account: "Account",
  wallet: "Wallet",
  withdrawal: "Withdrawal",
  balance_history: "Balance History",
  transaction_history: "Transaction History",
  logout: "Log Out",
  logout_modal_text:
    "Are you sure you want to log out of your account?Make sure you've saved any unsaved work before proceeding.",
  yes: "Yes",
  cancel: "Cancel",
  forgot_password_title: "Forgot Password",
  forgot_pass_modal_desc:
    "Enter your email address and we'll send you instructions to reset password.",
  email_address: "Email Address*",
  send_instruction: "Send Instruction",
  new_password: "New Password*",
  success: "Success",
  error: "Error",
  info: "Info",
  warning: "Warning",
  change_password: "Change Password",
  reset_password: "Reset Password",
  set_password: "Set Password",
  password_info:
    "Password must be a minimum of 6 characters and a maximum of 20 characters, with at least one uppercase letter, one lowercase letter, and one numeric character",
  language: "Language",
  required: "Required",
  pattern_error:
    "Password must be a minimum of 6 characters and a maximum of 20 characters, with at least one uppercase letter, one lowercase letter, and one numeric character",
  okay: "Okay",
  thank_you_for_register: "Thank you for registering",
  congrats_for_register: "Congratulations on registering.",
  email_check_message: "Please check your email to verify your registration.",
  password_matches: "Passwords don't match",
  email_pattern_error: "Not Valid email",
  example: "Example",
  upload_image: "Upload Image or Drag and Drop",
  become_merchant: "Become a Merchant",
  submit: "Submit",
  balance: "Balance",
  save_changes: "Save Changes",
  inactive: "Inactive",
  activated: "Activated",
  activate: "Activate",
  inactivate: "Inactivate",
  old_password: "Old Password*",
  current_password: "Current Password*",
  im_agree: "I’m agree with",
  and: "and",
  load_more: "Load More",
  price_rate_without: "Price Rate",
  create: "Create",
  home_table_title: "Sell & Buy Crypto with Ease",
  rows_per_page: "Rows per page:",
  request_trade: "Request Trade",
  request_trade_text:
    "Enter the amount. Your request will be sent to the merchant. If approved within 10 minutes, a chat will open. Otherwise the request will be automatically  lost.",
  request_trade_info: "Amount can’t exceed the quantity offered by merchant.",
  active: "Active",
  trade_id: "Trade ID",
  activate_trade:
    "Are you sure you want to activate trade? Activating the trade will show it in the trade table.",
  inactivate_trade:
    "Are you sure you want to Inactivate trade? Inactivating the trade will remove it from the trade table.",
  close_trade: "Close Trade",
  close_trade_text:
    "Are you sure you want to close the trade? Closing the trade will remove it from the trade table.",
  id: "ID",
  requests: "Requests",
  select_trade: "Select Trade",
  select_trade_text: "Choose trade from the list to see all requests",
  pending: "Pending",
  requested_amount: "Requested Amount:",
  user_equest_time: "User Request time:",
  accept: "Accept",
  accept_request: "Accept Request",
  accept_request_text:
    "Are you sure you want to accept the request?Accepting the request starts a chat with the user.",
  cancel_request: "Cancel Request",
  cancel_request_text:
    "Are you sure you want to cancel this request? This action cannot be undone.",
  in_progress: "In Progress",
  request_id: "Request ID",
  go_to_chat: "Go to Chat",
  min_amount: "Min Amount",
  max_amount: "Max Amount",
  arbitrage: "Arbitrage",
  confirm: "Confirm",
  merchant_cancel_deal: " has canceled the deal",
  canceled_deal_message:
    "This chat will be closed, and the trade will be moved to the transaction history.",
  user_conform_deal: " has confirmed the deal",
  sender_conform_deal: "You have confirmed the deal",
  sender_cancel_deal: "You have canceled the deal",
  merchant_conform_deal: " has confirmed the deal",
  confirm_deal_message:
    "If you also confirm, the chat will close, and the trade will be completed.",
  cancel_trade: "Cancel Trade",
  cancel_trade_message:
    "Are you sure you want to cancel the trade? Canceling the trade will close the chat, and the trade will be moved to the transaction history.",
  initiate_arbitrage: "Initiate Arbitrage",
  initiate_arbitrage_message:
    "Do you wish to engage in arbitrage? If you select 'Yes', an admin will join your chat to assist with the process.",
  currencies: "Currency",
  confirm_trade: "Confirm Trade",
  confirm_trade_text: "Are you sure you want to Confirm the trade?",
  closed: "Closed",
  trade_time: "Trade time:",
  deal_id: "Deal ID",
  rate: "Rate",
  status: "Status",
  date: "Date",
  action: "Action",
  id_nickname: "ID/ Nickname",
  view_details: "View Details",
  canceled: "Canceled",
  completed: "Completed",
  source: "Source",
  type: "Type",
  outcome: "Outcome",
  income: "Income",
  type_message: "Type a message...",
  currency_mandatory: "Currency*",
  amount_mandatory: "Amount*",
  price_rate_mandatory: "Price Rate*",
  banks_mandatory: "Banks*",
  quantity: "Quantity",
  please_scan_qr:
    "Please scan the QR code or copy the generated address to deposit your crypto assets.",
  wallet_address: "Wallet Address",
  withdraw: "Withdraw",
  step: "Step",
  step_one_desc:
    "Easily transfer funds from your wallet to your desired account using our streamlined withdrawal process",
  step_two_desc:
    "To initiate the withdrawal process, account verification is required. Please complete the verification process to proceed with your withdrawal.",
  withdrawal_amount_mandatory: "Withdrawal Amount*",
  destination_address_mandatory: "Destination Address*",
  next: "Next",
  proceed_withdrawal: "Proceed Withdrawal",
  accepted: "In Progress",
  rejected: "Canceled",
  trade_details: "Trade Details",
  no_requests: "No Requests",
  no_available_requests: "No available requests for this trade.",
  value_must_be_greater: "Value must be greater than 0",
  confirm_pass: "Confirm Password",
  ongoing_requests: "Ongoing Requests",
  request: "Request",
  for_withdrawals_please: "For Withdrawals please",
  two_factor_authentication: "two-factor authentication (2FA)",
  two_fa_error_message: "Two-factor authentication (2FA) is required.",
  empty_message_active: "No active trades found",
  empty_message_inactive: "No inactive trades found",
  empty_message_accepted: "No in progress requests found",
  empty_message_pending: "No pending requests found",
  empty_message_trade: "No available trades",
  empty_message_request: "No available requests",
  sender_arbitraged: "You did arbitrage.",
  user_arbitraged: " did arbitrage.",
  arbitrage_message: "An admin has been invited to the chat.",
  trade_completed: "Trade Completed",
  trade_rate_modal_text:
    "The trade has been successfully completed. Thank you for your cooperation. Please take a minute to rate each other.",
  value_must_be_greater_than: "Value must be greater than or equal to 0.000001",
  two_factor_disable_message:
    "To continue, please enter the key provided by the Google Authenticator app",
  address_pattern_error:
    "Address must contain only English letters and numbers.",
  no_results_match: "No Results Match",
  no_item_matched: "No item matched.",
  please_try_with_other_options: "Please try with other options.",
  no_trades: "No Trades",
  no_available_trades: "No available trades",
  no_requests_available: "No available requests",
  verified: "Verified",
  usd_mandatory: "USD*",
  crypto_coin: "Crypto Coin",
  crypto_coin_mandatory: "Crypto Coin*",
  usd_price_rate: "USD Rate / 1$ ~ AMD*",
  verify_checkbox_text: "Unverified users can also send requests.",
  verify_your_account: "Verify Your Account",
  verify_account_modal_message:
    "To send a request for this trade, you should be a verified user. To complete the process, go to your profile and upload the passport image. If you have already uploaded it but have not received verification yet, please contact us with details.",
  go_to_profile: "Go To Profile",
  one_usd_equivalent: "One U.S. dollar is equivalent to",
  amd: "AMD",
  usd: "USD",
  usd_to_amd: "USD Rate/ AMD",
  upload_image_profile: "Upload Passport Image",
  satisfied_customers: "Satisfied Customers",
  platform_uptime: "Platform Uptime",
  armenian_market: "Armenian Market",
  no_transactions_found: "No transactions found",
  no_data: "No Data",
  no_balance_history: "No balance history",
};
