import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Label } from "components";
import { useTranslation } from "localization";
import { IUserRequest } from "types";
import { GLOBAL } from "constants/global";
import { TABLE_BUY_SELL } from "constants/table";
import { useAppDispatch } from "reduxState/store";
import { setChatMessages } from "reduxState/features/trades";

import amdIcon from "assets/images/armenianCurrency.svg";

interface IUserTradeItem {
  element: IUserRequest;
}

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

export const UserTradeItem: FC<IUserTradeItem> = ({ element }) => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const query = new URLSearchParams(useLocation().search);
  const queryId = query.get(GLOBAL.request_id);

  const handleOpenRequests = () => {
    dispatch(setChatMessages([]));
    if (queryId !== `${element.id}`) {
      query.set(GLOBAL.request_id, `${element.id}`);
      navigate(`?${query.toString()}`);
    } else {
      query.delete(GLOBAL.request_id);
      navigate(`?${query.toString()}`);
    }
  };

  return (
    <>
      <div
        className={`trade_item ${
          queryId === `${element.id}` ? "selected_trade_id" : ""
        }`}
        onClick={handleOpenRequests}
      >
        <div className="item_header">
          <div className="item_name_type">
            <p className="trade_id">{`${translation.request_id} ${element.id}`}</p>
            <Label
              text={TABLE_BUY_SELL[element.type]}
              type={TABLE_BUY_SELL[element.type] === "sell" ? "red" : "green"}
            />
          </div>
        </div>
        <div className="item_body">
          <div className="body_element_field">
            <h5 className="element_title">{translation.currency}</h5>
            <div className="element_info_field">
              <img
                src={`${imageUrl}${element.currencyIcon}`}
                alt="Currency"
                className="currency_icon"
                crossOrigin="anonymous"
              />
              <div className="currency_element_field">
                <p className="element_info">{element.currencyTitle}</p>
                <span className="currency_line"></span>
                <p className="element_info">{element.currencyLongTitle}</p>
              </div>
            </div>
          </div>
          <div className="body_element_field">
            <h5 className="element_title">{translation.crypto_coin}</h5>
            <div className="element_info_field">
              <p className="element_info">{element.amount}</p>
            </div>
          </div>
          <div className="body_element_field">
            <h5 className="element_title">{translation.usd_to_amd}</h5>
            <div className="element_info_field">
              <div className="price_rate_section">
                <p className="element_info">{element.priceRate}</p>
                <img
                  src={amdIcon}
                  alt="Armenian Valuta"
                  className="rate_icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
