import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "reduxState/store";
import { getTrades } from "reduxState";
import { IMainTable, ITradeModalData } from "types";
import { PageLoading, Pagination, TradeRequestModal } from "components";
import { DesktopTable } from "./desktoptable/DesktopTable";
import { MobileTable } from "./mobileTable/MobileTable";
import { GLOBAL } from "constants/global";
import { EmptyTablePage } from "../../EmptyPage";
import { useTranslation } from "localization";

export const TableBody: FC<IMainTable> = ({
  headerData,
  filters,
  refresh,
  activeTab,
}) => {
  const querySearch = new URLSearchParams(useLocation().search);
  const queryPage = querySearch.get(GLOBAL.page);
  const queryPerPage = querySearch.get(GLOBAL.per_page);

  const [page, setPage] = useState<number>(
    queryPage ? Number(queryPage) : GLOBAL.default_page
  );
  const [perPage, setPerPage] = useState<number>(
    queryPerPage ? Number(queryPerPage) : GLOBAL.default_per_page
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [tradeId, setTradeId] = useState<string>("");
  const [tradeData, setTradeData] = useState<ITradeModalData>({
    priceRate: "",
    banks: [],
    currencyName: "",
  });

  const total = useAppSelector((state) => state.trades.tradesTotal);
  const loading = useAppSelector((state) => state.trades.tradesLoading);

  const translation = useTranslation();

  const dispatch = useAppDispatch();

  const handleScrollToTop = () => {
    const homeBanner = document
      .getElementById("home_banner_id")
      ?.getBoundingClientRect();

    const homeBannerHeight = homeBanner?.height;

    window?.scrollTo({
      behavior: "smooth",
      top: homeBannerHeight,
    });
  };

  useEffect(() => {
    const params = {
      page: `${page}`,
      per_page: `${perPage}`,
      filters: filters ? { ...filters, type: activeTab } : {},
    };
    dispatch(getTrades({ params }));
  }, [page, perPage, filters, refresh, activeTab]);

  useEffect(() => {
    if (!showModal) {
      document.body.style.overflow = "unset";
    }
  }, [showModal]);

  useEffect(() => {
    if (queryPage && Number(queryPage) !== page) {
      setPage(Number(queryPage));
    }
  }, [queryPage]);

  return (
    <div className="table_body">
      {loading ? (
        <PageLoading loading={loading} customClass="table_loading" />
      ) : (
        <>
          {showModal && (
            <TradeRequestModal
              tradeId={tradeId}
              tradeData={tradeData}
              setShowModal={setShowModal}
            />
          )}
          {!!total ? (
            <>
              <DesktopTable
                headerData={headerData}
                setShowModal={setShowModal}
                setTradeId={setTradeId}
                setTradeData={setTradeData}
              />
              <MobileTable
                setShowModal={setShowModal}
                setTradeId={setTradeId}
                setTradeData={setTradeData}
              />
            </>
          ) : (
            <EmptyTablePage
              filters={filters}
              message={translation.no_available_trades}
            />
          )}
          <div className="table_pagination">
            <Pagination
              perPage={perPage}
              total={total}
              page={page}
              setPage={setPage}
              setPerPage={setPerPage}
              handleAction={handleScrollToTop}
            />
          </div>
        </>
      )}
    </div>
  );
};
