import { FC, useEffect } from "react";

import { IChildrenType } from "types";

export const ModalContainer: FC<IChildrenType> = ({ children }) => {
  useEffect(() => {
    const modalElement = document.getElementById("modal_container_field");
    if (modalElement) {
      document.body.style.overflow = "hidden";
    }
  }, []);

  return (
    <div className="modal_container" id="modal_container_field">
      {children}
    </div>
  );
};
