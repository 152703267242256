import { FC } from "react";

import { useTranslation } from "localization";

import infoIcon from "assets/images/infoIcon.svg";

interface IInfoSection {
  priceRate: string;
}

export const InfoSection: FC<IInfoSection> = ({ priceRate }) => {
  const translation = useTranslation();

  return (
    <div className="info_section">
      <div className="image_section">
        <img src={infoIcon} alt="Info" />
      </div>
      <div className="text_section">
        <p className="title">{`${translation.one_usd_equivalent} ${priceRate} ${translation.amd}.`}</p>
      </div>
    </div>
  );
};
