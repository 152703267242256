import facebook from "assets/images/social/facebook.svg";
import instagram from "assets/images/social/instagram.svg";
import linkedIn from "assets/images/social/linkedin.svg";
import twitter from "assets/images/social/twitter.svg";

export const FOOTER = [
  {
    id: 1,
    title: "about",
    components: [
      {
        id: 3,
        title: "faq",
        path: "/faq",
        type: "",
      },
      {
        id: 4,
        title: "merchants",
        path: "/for-merchants",
        type: "",
      },
    ],
  },
  {
    id: 6,
    title: "legal",
    components: [
      {
        id: 7,
        title: "terms_of_use",
        path: "/terms-of-use",
        type: "",
      },
      {
        id: 8,
        title: "privacy_policy",
        path: "/privacy-policy",
        type: "",
      },
    ],
  },
];

export const MAIL_TYPE = "mail";
export const PHONE_TYPE = "phone";
