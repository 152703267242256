import { useAppSelector } from "reduxState/store";
import { useTranslation } from "localization";

import checkedIcon from "assets/images/profile/checked.svg";
import checkedActiveIcon from "assets/images/profile/checkedActive.svg";

export const TowFactorLabel = () => {
  const user = useAppSelector((state) => state.auth.user);

  const translation = useTranslation();

  return (
    <div className="two_factor_label">
      <img
        src={user?.isEnabled2FA ? checkedActiveIcon : checkedIcon}
        alt="Checked"
        className="checked_icon"
      />
      <p
        className={`two_factor_state ${
          user?.isEnabled2FA ? "activated" : "inactivated"
        }`}
      >
        {user?.isEnabled2FA ? translation.activated : translation.inactive}
      </p>
    </div>
  );
};
