import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "localization";
import { RequestTimer } from "../components/tradeRequests/RequestTimer";
import {
  ARBITRAGE,
  DEFAULT_TIMER_MINUTES,
  MESSAGE_NOTIFICATION_TYPES,
  REQUEST_MERCHANT_STATUSES,
  TRADE_REQUEST_STATUSES,
} from "constants/trade";
import { AboutTrade, ChatSection } from "./components";
import { GLOBAL, USER_TYPES } from "constants/global";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { MainButton } from "components/buttons";
import { setTradeModalData } from "reduxState/features/main";
import { TradeRateModal } from "components/modals";

import backIcon from "assets/images/arrow-down.svg";
import starIcon from "assets/images/starIcon.svg";
import arbitrageIcon from "assets/images/chat/arbitrage.svg";
import arbitrageHoveredIcon from "assets/images/chat/arbitrageHovered.svg";
import inactivateIcon from "assets/images/trades/inactivatePopup.svg";
import activateIcon from "assets/images/trades/activatePopup.svg";
import arbitrageModalIcon from "assets/images/chat/arbitrageModal.svg";

export const Chat = () => {
  const [disableArbitrage, setDisableArbitrage] = useState<boolean>(false);
  const [disableConfirmCancel, setDisableConfirmCancel] =
    useState<boolean>(false);

  const requestData = useAppSelector((state) => state.trades.userRequestData);
  const user = useAppSelector((state) => state.auth.user);
  const showUserRateModal = useAppSelector(
    (state) => state.trades.showUserRateModal
  );

  const isMerchant = user?.role === USER_TYPES.merchant;
  const disableButtons = isMerchant
    ? REQUEST_MERCHANT_STATUSES.includes(requestData.merchant_status)
    : REQUEST_MERCHANT_STATUSES.includes(requestData.user_status);

  const disableArbitrageButton =
    requestData.merchant_status === MESSAGE_NOTIFICATION_TYPES.REJECTED;

  const dispatch = useAppDispatch();
  const translation = useTranslation();
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const requestId = query.get(GLOBAL.request_id);

  const date = new Date(requestData?.timer_updated_at);
  date.setMinutes(date.getMinutes() + DEFAULT_TIMER_MINUTES);

  const closeChat = () => {
    query.delete(GLOBAL.request_id);
    navigate(`?${query.toString()}`);
  };

  const handleClose = () => {
    dispatch(
      setTradeModalData({
        title: translation.cancel_trade,
        description: translation.cancel_trade_message,
        showTradeModal: true,
        icon: inactivateIcon,
        type: TRADE_REQUEST_STATUSES.rejected,
        id: `${requestId}`,
      })
    );
  };

  const handleConfirm = () => {
    dispatch(
      setTradeModalData({
        title: translation.confirm_trade,
        description: translation.confirm_trade_text,
        showTradeModal: true,
        icon: activateIcon,
        type: TRADE_REQUEST_STATUSES.completed,
        id: `${requestId}`,
      })
    );
  };

  const handleArbitrage = () => {
    dispatch(
      setTradeModalData({
        title: translation.initiate_arbitrage,
        description: translation.initiate_arbitrage_message,
        showTradeModal: true,
        icon: arbitrageModalIcon,
        type: ARBITRAGE,
        id: `${requestId}`,
      })
    );
  };

  useEffect(() => {
    if (!showUserRateModal) document.body.style.overflow = "unset";
  }, [showUserRateModal]);

  return (
    <div className="chat_container" id="chat_container_id">
      {showUserRateModal && (
        <TradeRateModal
          userId={
            isMerchant
              ? Number(requestData?.requester_id)
              : Number(requestData.merchant_id)
          }
        />
      )}
      <div className="chat_information" id="chat_information_id">
        <div className="chat_header">
          <div className="right_side">
            <img
              src={backIcon}
              alt="Back"
              className="back_icon"
              onClick={closeChat}
            />
            <div className="user_info">
              {!!requestData?.requester_rating && (
                <div className="user_rating">
                  <img src={starIcon} alt="Star" className="star_icon" />
                  <p className="rating">{`${requestData?.requester_rating}/5`}</p>
                </div>
              )}
              <div className="user_name_section">
                <p className="user_name">{requestData?.requester}</p>
              </div>
            </div>
          </div>
          {!(requestData?.is_arbitraged || disableArbitrage) && (
            <div className="left_side">
              <RequestTimer
                date={date}
                customClass="chat_timer"
                isArbitraged={requestData?.is_arbitraged}
                elementId={requestData?.id}
              />
            </div>
          )}
        </div>
        <div className="trade_id_section">
          <p className="trade_info">{`${translation.trade_id} ${requestData?.trade_id}`}</p>
        </div>
        <div className="chat_line"></div>
        <AboutTrade
          currencyTitle={requestData?.currencyTitle}
          currencyIcon={requestData?.currencyIcon}
          currencyLongTitle={requestData?.currencyLongTitle}
          amount={requestData?.amount}
          priceRate={requestData?.priceRate}
        />
        <div className="chat_actions">
          <div
            className={`arbitrage_field ${
              requestData?.is_arbitraged ||
              disableArbitrageButton ||
              disableArbitrage
                ? "disable_arbitrage_field"
                : ""
            }`}
          >
            <img
              src={arbitrageIcon}
              alt="Arbitrage"
              className={`arbitrage_icon ${
                requestData?.is_arbitraged ||
                disableArbitrageButton ||
                disableArbitrage
                  ? ""
                  : "arbitrage_icon_disabled"
              }`}
            />
            {!(
              requestData?.is_arbitraged ||
              disableArbitrageButton ||
              disableArbitrage
            ) && (
              <img
                src={arbitrageHoveredIcon}
                alt="Arbitrage"
                className="arbitrage_icon_hovered"
              />
            )}
            <button
              className="arbitrage"
              onClick={handleArbitrage}
              disabled={
                requestData?.is_arbitraged ||
                disableArbitrageButton ||
                disableArbitrage
              }
            >
              {translation.arbitrage}
            </button>
          </div>
          <div className="chat_action_buttons">
            {user?.role === USER_TYPES.merchant && (
              <MainButton
                name={translation.cancel}
                customClass="log_in_button chat_button"
                onClick={handleClose}
                disabled={disableButtons || disableConfirmCancel}
              />
            )}
            <MainButton
              name={translation.confirm}
              customClass="custom_button chat_button"
              onClick={handleConfirm}
              disabled={disableButtons || disableConfirmCancel}
            />
          </div>
        </div>
      </div>
      <ChatSection
        setDisableArbitrage={setDisableArbitrage}
        setDisableConfirmCancel={setDisableConfirmCancel}
      />
    </div>
  );
};
