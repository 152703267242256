import { FC } from "react";
import DOMPurify from "dompurify";
import { PageTitle } from "../pageTitle";

interface IPolicy {
  title: string;
  content: string;
}

export const PolicyComponent: FC<IPolicy> = ({ title, content }) => {
  const clean = DOMPurify.sanitize(content);
  return (
    <div className="policy_component">
      <PageTitle title={title} />
      <div
        className="privacy_content"
        dangerouslySetInnerHTML={{ __html: clean }}
      ></div>
    </div>
  );
};
