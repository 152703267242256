import { IAuth } from "types";
import instance from "../baseRequest";
import { ICreateRoom } from "types/auth";

export const chatApi = {
  roomCreate: async (body: ICreateRoom) =>
    await instance.post("/v1/chat/rooms", body),
  getChatMessages: async (id: string) =>
    instance.get(`/v1/chat/messages/room/${id}`),
};
