import { FC, useState } from "react";

import { TableBody, TableFilters, TableHeader } from "./components";
import { IMainTable, ITableFilters } from "types";

export const TransactionTable: FC<IMainTable> = ({ headerData }) => {
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<ITableFilters>({});

  return (
    <div className="main_table_container padding_0">
      <div className="main_table_content">
        <TableHeader
          setOpenFilters={setOpenFilters}
          openFilters={openFilters}
        />
        <div
          className={`filters_container ${
            openFilters ? "filter_container_show" : ""
          }`}
        >
          <TableFilters setFilters={setFilters} openFilters={openFilters} />
        </div>
        <TableBody headerData={headerData} filters={filters} />
      </div>
    </div>
  );
};
