import { FC, useEffect, useState } from "react";

import { Pagination } from "components";
import { DesktopTable } from "./desktoptable/DesktopTable";
import { MobileTable } from "./mobileTable/MobileTable";
import { GLOBAL, MOBILE_WINDOW_WIDTH } from "constants/global";
import { IMainTableElement, ITableFilters } from "types";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { getBalanceHistory } from "reduxState";
import { EmptyTablePage } from "../../EmptyPage";
import { useTranslation } from "localization";

interface ITransactionTable {
  headerData: IMainTableElement[];
  filters: ITableFilters;
}

export const TableBody: FC<ITransactionTable> = ({ headerData, filters }) => {
  const [page, setPage] = useState<number>(GLOBAL.default_page);
  const [perPage, setPerPage] = useState<number>(GLOBAL.default_per_page);

  const total = useAppSelector((state) => state.userData.balanceTotal);
  const data = useAppSelector((state) => state.userData.balanceHistory);

  const dispatch = useAppDispatch();
  const translation = useTranslation();

  const handleScrollToTop = () => {
    if (window.innerWidth > MOBILE_WINDOW_WIDTH) {
      window?.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    } else {
      const accountSection = document
        .getElementById("profile_menu")
        ?.getBoundingClientRect();

      const mobileTableContainer = document.getElementById(
        "mobile_table_container"
      );

      const accountSectionHeight = accountSection?.height;

      window?.scrollTo({
        behavior: "smooth",
        top: accountSectionHeight,
      });

      mobileTableContainer?.scrollTo({
        top: 0,
      });
    }
  };

  useEffect(() => {
    const params = {
      page,
      count: perPage,
      startDate: filters?.startDate,
      endDate: filters?.endDate,
    };

    if (!filters?.startDate) {
      delete params.startDate;
      delete params.endDate;
    }

    dispatch(getBalanceHistory({ params }));
  }, [filters, page, perPage]);

  return (
    <div className="table_body">
      {!!data?.length ? (
        <>
          <DesktopTable headerData={headerData} />
          <MobileTable />
        </>
      ) : (
        <EmptyTablePage
          filters={filters}
          message={translation.no_balance_history}
        />
      )}

      <div className="table_pagination">
        <Pagination
          perPage={perPage}
          total={total}
          page={page}
          setPage={setPage}
          setPerPage={setPerPage}
          handleAction={handleScrollToTop}
        />
      </div>
    </div>
  );
};
