import { createAsyncThunk } from "@reduxjs/toolkit";

import { profileApi } from "service";
import { IAuthData, IError, IPassportImageChange } from "types";
import { setToastData } from "reduxState/features/toast";
import { TOAST_TYPES } from "constants/toast";
import { getUser } from "../auth";

export const updateAccountData = createAsyncThunk(
  "updateAccountData",
  async ({ data, id }: IAuthData, { rejectWithValue, dispatch }) => {
    try {
      const res = await profileApi.changeAccountData(data, id as string);
      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );
      dispatch(getUser({}));
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const updateAccountFormData = createAsyncThunk(
  "updatePassportImage",
  async ({ data, id }: IPassportImageChange, { rejectWithValue, dispatch }) => {
    try {
      const res = await profileApi.changePassportImage(data, id as string);
      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );
      dispatch(getUser({}));
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);
