import { FC, FormEvent, useRef, useState } from "react";

import { ModalContainer } from "../ModalAuth";
import useOutsideClick from "hooks/useOutsideClick";
import { useTranslation } from "localization";
import { STAR_ICONS_COUNT } from "constants/global";
import { MainButton } from "components/buttons";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { setShowUserRateModal } from "reduxState/features/trades";
import { setOpenToast } from "reduxState/features/toast";
import { userRateRequest } from "reduxState";

import closeIcon from "assets/images/close.svg";
import rateModalIcon from "assets/images/rate/rateModal.svg";
import starFill from "assets/images/rate/starFill.svg";
import starIcon from "assets/images/rate/starIcon.svg";

interface IRateTrade {
  userId: number;
}

export const TradeRateModal: FC<IRateTrade> = ({ userId }) => {
  const [rate, setRate] = useState<number | null>(null);
  const translation = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state) => state.auth.changePasswordLoading);

  const handleClose = () => {
    dispatch(setShowUserRateModal(false));
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    const data = {
      rating: Number(rate),
      userId: userId,
    };

    dispatch(userRateRequest({ data })).finally(() => {
      dispatch(setOpenToast(false));
      handleClose();
    });
  };

  const handleRate = (index: number) => {
    setRate(index);
  };

  useOutsideClick({ ref, handler: handleClose });

  return (
    <ModalContainer>
      <div className="modal change_password_modal" ref={ref}>
        <div className="logout_header">
          <div className="close_modal">
            <img src={closeIcon} alt="Close" onClick={handleClose} />
          </div>
          <div className="logout_header_element">
            <img
              src={rateModalIcon}
              alt="Change Password"
              className="logout_image"
            />
            <p className="logout_title">{translation.trade_completed}</p>
          </div>
        </div>
        <div className="logout_body">
          <p className="logout_description">
            {translation.trade_rate_modal_text}
          </p>
        </div>
        <form className="modal_form" onSubmit={onSubmit}>
          <div className="rate_stars_section">
            <div className="stars_section">
              {STAR_ICONS_COUNT.map((element) => (
                <img
                  src={
                    rate !== null && Number(rate) >= element
                      ? starFill
                      : starIcon
                  }
                  key={element}
                  alt="Star"
                  className="rate_star_icon"
                  onClick={() => handleRate(element)}
                />
              ))}
            </div>
          </div>
          <div className="logout_actions">
            <MainButton
              name={translation.submit}
              customClass="custom_button width_100"
              type="submit"
              loading={loading}
              disabled={loading || !rate}
            />
          </div>
        </form>
      </div>
    </ModalContainer>
  );
};
