import { Fragment } from "react";
import { useLocation, useNavigate } from "react-router";

import { useTranslation } from "localization";
import { PROFILE_MENU_LIST } from "constants/profile";

export const MenuList = () => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <div className="profile_menu_list">
      <ul className="profile_list">
        {PROFILE_MENU_LIST.map((element) => (
          <Fragment key={element.id}>
            <li
              className="list_element"
              onClick={() => handleNavigate(element.path)}
            >
              <img
                src={
                  pathname === element.path ? element.activeIcon : element.icon
                }
                alt="Profile"
              />
              <p
                className={`element_name ${
                  pathname === element.path ? "active_element_name" : ""
                }`}
              >
                {translation[element.name]}
              </p>
            </li>
            <li className="list_element_line"></li>
          </Fragment>
        ))}
      </ul>
    </div>
  );
};
