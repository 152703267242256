import { FC } from "react";

import { IMainButton } from "types";
import { ClipSpinner } from "components";

export const MainButton: FC<IMainButton> = ({
  customClass = "",
  name,
  onClick,
  icon,
  iconClass,
  type = "button",
  loading = false,
  disabled,
}) => {
  return (
    <button
      className={`main_button ${customClass}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      <ClipSpinner color="#000" loading={loading} />
      {!loading && (
        <>
          {!!icon && <img src={icon} alt="Icon" className={iconClass} />}
          <span>{name}</span>
        </>
      )}
    </button>
  );
};
