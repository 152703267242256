import { IAuth, IParamsFaq } from "types";
import instance from "../baseRequest";

export const contentApi = {
  getForMerchants: async () =>
    await instance.get("/v1/content/merchant-page/get"),
  getHomeContent: async () => await instance.get("/v1/content/home-page/get"),
  getAboutUs: async () => await instance.get("/v1/content/about-us/get"),
  getPrivacyPolicy: async () =>
    await instance.get("/v1/content/privacy-policies/get"),
  getTermsOfUse: async () => await instance.get("/v1/content/terms/get"),
  getFaqData: async (params: IParamsFaq) =>
    await instance.get("/v1/content/faqs", { params }),
  getContactUs: async () => await instance.get("/v1/content/contacts/get"),
  getSocialLinks: async () => await instance.get("/v1/content/social-links"),
  sendContactInfo: async (body: IAuth) =>
    await instance.post("/v1/contact-us", body),
};
