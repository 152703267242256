import {
  Dispatch,
  FC,
  MouseEvent,
  SetStateAction,
  memo,
  useEffect,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";

import closeIcon from "assets/images/inputs/close.svg";

const imageBase = process.env.REACT_APP_API_IMAGE_URL;

interface IImages {
  setUploadedFiles: Dispatch<SetStateAction<File[]>>;
  uploadedFiles: File[];
  passportImages?: string[];
}
export const ImagesGrid: FC<IImages> = memo(
  ({ uploadedFiles, setUploadedFiles, passportImages }) => {
    const [objectURLs, setObjectURLs] = useState<string[]>([]);

    const handleRemove = (e: MouseEvent<HTMLElement>, index: number) => {
      e.stopPropagation();
      const newElements = uploadedFiles.filter(
        (elem: File, idx: number) => idx !== index
      );
      setUploadedFiles(newElements);
    };

    useEffect(() => {
      const urls = uploadedFiles.map((file) => URL.createObjectURL(file));
      setObjectURLs(urls);

      return () => {
        urls.forEach((url) => URL.revokeObjectURL(url));
      };
    }, [uploadedFiles]);

    return (
      <div className="images_grid">
        {objectURLs.map((url, index) => (
          <div className="grid_element" key={uuidv4()}>
            <div className="remove_icon">
              <img
                src={closeIcon}
                alt="Close"
                className="close_icon"
                onClick={(e) => handleRemove(e, index)}
              />
            </div>
            <div className="image_grid_field">
              <img
                src={url}
                className="grid_image"
                alt={`Uploaded file ${index + 1}`}
              />
            </div>
          </div>
        ))}
        {passportImages?.map((url, index) => (
          <div className="grid_element" key={uuidv4()}>
            <div className="image_grid_field">
              <img
                src={`${imageBase}${url}`}
                className="grid_image"
                alt={`Uploaded file ${index + 1}`}
                crossOrigin="anonymous"
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
);
