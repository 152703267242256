import { useState } from "react";

import { TradeRequest, TradesList } from "../components";
import { ONGOING_TRADES_TABS } from "constants/trade";

export const MerchantsTrades = () => {
  const [activeTab, setActiveTab] = useState<string>(
    ONGOING_TRADES_TABS[0].value
  );
  return (
    <div className="trades_container">
      <TradesList
        isMerchant={true}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
      />
      <TradeRequest activeTab={activeTab} />
    </div>
  );
};
