import { IAuth, IMerchantRequestsFilter, IRating } from "types";
import instance from "../baseRequest";
import { ICreateTradeData, IMerchantTradesFilter, ITradeParams } from "types";

export const tradesApi = {
  createTrade: async (body: ICreateTradeData) =>
    await instance.post(`/v1/merchant/trades`, body),
  getTrades: async (params: ITradeParams) =>
    await instance.get("/v1/trades", { params }),
  requestTrade: async (body: IAuth) =>
    await instance.post("/v1/trades/requests", body),
  merchantTrades: async (params: IMerchantTradesFilter) =>
    await instance.get(`/v1/merchant/trades`, { params }),
  changeTradeStatus: async (id: number, body: IAuth) =>
    await instance.put(`/v1/merchant/trades/status/${id}`, body),
  closeTrade: async (id: number, body: IAuth) =>
    await instance.put(`/v1/merchant/trades/state/${id}`, body),
  merchantTradeRequests: async (id: number) =>
    await instance.get(`/v1/merchant/trades/requests/${id}`),
  acceptCancelRequest: async (id: number, body: IAuth) =>
    await instance.put(`/v1/merchant/trades/requests/status/${id}`, body),
  acceptCancelRequestUser: async (id: number, body: IAuth) =>
    await instance.put(`/v1/trades/requests/status/${id}`, body),
  getUserTradeRequests: async (params: IMerchantRequestsFilter) =>
    await instance.get("/v1/trades/requests", { params }),
  getUserTradeRequestById: async (id: number) =>
    await instance.get(`/v1/trades/requests/${id}`),
  getMerchantTradeRequests: async (id: number) =>
    await instance.get(`/v1/merchant/trades/requests/show/${id}`),
  chatArbitrageRequest: async (id: string) =>
    await instance.post(`/v1/chat/arbitrage-request/${id}`),
  getMerchantRequests: async (params: IMerchantRequestsFilter) =>
    await instance.get("/v1/merchant/trades/get/requests", { params }),
  userRateRequest: async (body: IRating) =>
    await instance.post("/v1/users/ratings", body),
};
