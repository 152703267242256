import { LANGUAGE } from "constants/header";
import axios from "axios";

import { GLOBAL } from "constants/global";
import HttpStatusCode from "constants/httpStatusCodes";

const base_url = process.env.REACT_APP_API_BASE_URL;

const instance = axios.create({
  baseURL: base_url,
});

export const formDataInstance = axios.create({
  baseURL: base_url,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === HttpStatusCode.UNAUTHORIZED) {
      const isLogined =
        localStorage.getItem(GLOBAL.access_token) ||
        sessionStorage.getItem(GLOBAL.access_token);

      localStorage.removeItem(GLOBAL.access_token);
      sessionStorage.removeItem(GLOBAL.access_token);

      if (isLogined) window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  (config) => {
    const token =
      localStorage.getItem(GLOBAL.access_token) ||
      sessionStorage.getItem(GLOBAL.access_token);
    const language = localStorage.getItem(LANGUAGE);

    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Accept-Language"] = language;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

formDataInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === HttpStatusCode.UNAUTHORIZED) {
      localStorage.removeItem(GLOBAL.access_token);
      sessionStorage.removeItem(GLOBAL.access_token);
    }
    return Promise.reject(error);
  }
);

formDataInstance.interceptors.request.use(
  (config) => {
    const token =
      localStorage.getItem(GLOBAL.access_token) ||
      sessionStorage.getItem(GLOBAL.access_token);
    const language = localStorage.getItem(LANGUAGE);

    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Content-Type"] = "multipart/form-data";
    config.headers["Accept-Language"] = language;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
