import {
  ChangeEvent,
  ClipboardEvent,
  FormEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import { useTranslation } from "localization";
import { StepContainer } from "./StepContainer";
import {
  AUTH_CODE_LENGTH,
  ENGLISH_AND_NUMERIC_REGEX,
  GLOBAL,
  KEY_NAMES,
  VERIFICATION_CODE_INPUTS,
} from "constants/global";
import { MainButton, SelectBox, TextInput } from "components";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { setShowTwoFactor } from "reduxState/features/auth";
import { setOpenToast, setToastData } from "reduxState/features/toast";
import { TOAST_TYPES } from "constants/toast";
import {
  setIsPassedFirstStep,
  setWithdrawalData,
} from "reduxState/features/user";
import { withdrawCurrency } from "reduxState";
import { IWithdrawalData } from "types";

import stepOneIcon from "assets/images/profile/stepOne.svg";
import stepTwoIcon from "assets/images/profile/stepTwo.svg";
import infoIcon from "assets/images/toast/info.svg";

export const WalletSteps = () => {
  const [openStepOne, setOpenStepOne] = useState<boolean>(true);
  const [openStepTwo, setOpenStepTwo] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");

  const user = useAppSelector((state) => state.auth.user);
  const stepData = useAppSelector((state) => state.userData.withdrawalData);
  const isPassedFirstStep = useAppSelector(
    (state) => state.userData.isPassedFirstStep
  );
  const loading = useAppSelector((state) => state.userData.withdrawLoading);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    setError,
    reset,
    watch,
    formState: { errors, isSubmitted },
  } = useForm();

  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const resetCodeFields = () => {
    inputRefs.forEach((ref) => {
      if (ref.current) {
        ref.current.value = "";
      }
    });
  };

  const handleOpenStepOne = () => {
    setOpenStepOne(!openStepOne);
  };

  const handleOpenStepTwo = () => {
    if (isPassedFirstStep) setOpenStepTwo(!openStepTwo);
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    if (data.amount === "0") {
      setError("amount", {
        type: GLOBAL.custom,
        message: translation.value_must_be_greater,
      });
      return;
    }

    if (!user?.isEnabled2FA) {
      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: translation.two_fa_error_message,
          open: true,
        })
      );

      setTimeout(() => {
        dispatch(setOpenToast(false));
      }, 3000);

      return;
    }

    dispatch(setWithdrawalData({ ...data, secret: "" }));
    dispatch(setIsPassedFirstStep(true));
    setOpenStepOne(false);
    setOpenStepTwo(true);
  };

  const handleConfirmAction = () => {
    setCode("");
    setOpenStepTwo(false);
    reset();
    resetCodeFields();
    dispatch(setIsPassedFirstStep(false));
    dispatch(
      setWithdrawalData({
        address: "",
        amount: "",
        currency: null,
        secret: "",
      })
    );
  };

  const handleWithdraw = (e: FormEvent) => {
    e.preventDefault();
    const data = { ...stepData, secret: code };
    dispatch(withdrawCurrency({ data, handleConfirmAction })).finally(() => {
      setTimeout(() => {
        dispatch(setOpenToast(false));
      }, 3000);
    });
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const input = e.target;
    if (/[^0-9]/.test(input.value)) {
      input.value = "";
      return;
    }

    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];

    const newCode = code.split("");
    newCode[index] = input.value;

    setCode(newCode.join(""));

    input.select();

    if (input.value === "") {
      if (previousInput && previousInput.current) {
        previousInput.current.focus();
      }
    } else if (nextInput && nextInput.current) {
      nextInput.current.select();
    }
  };

  const handleFocus = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    const input = e.target as HTMLInputElement;

    const previousInput = inputRefs[index - 1];

    if (
      (e.key === KEY_NAMES.backspace || e.key === KEY_NAMES.delete) &&
      input.value === ""
    ) {
      e.preventDefault();
      setCode(
        (prevCode) => prevCode.slice(0, index) + prevCode.slice(index + 1)
      );
      if (previousInput && previousInput.current) {
        previousInput.current.focus();
      }
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const pastedCode = e.clipboardData.getData("text");
    if (/^[0-9]+$/.test(pastedCode) && pastedCode.length === AUTH_CODE_LENGTH) {
      setCode(pastedCode);
      inputRefs.forEach((inputRef, index) => {
        if (inputRef.current !== null) {
          inputRef.current.value = pastedCode.charAt(index);
        }
      });
    }
  };

  const handleActivateTwoFactor = () => {
    dispatch(setShowTwoFactor(true));
  };

  useEffect(() => {
    const data = watch();

    for (let key in data) {
      if (data[key] !== stepData[key as keyof IWithdrawalData]) {
        setCode("");
        setIsPassedFirstStep(false);
        resetCodeFields();
        setOpenStepTwo(false);
      }
    }
  }, [watch()]);

  return (
    <div className="wallet_steps">
      {!user?.isEnabled2FA && (
        <div className="two_factor_enabled">
          <img src={infoIcon} alt="Info" className="info_icon" />
          <div className="two_factor_info">
            <span className="info_text">
              {translation.for_withdrawals_please}
            </span>
            <span
              className="info_text activate_two_factor"
              onClick={handleActivateTwoFactor}
            >
              {translation.activate}
            </span>
            <span className="info_text">
              {translation.two_factor_authentication}
            </span>
          </div>
        </div>
      )}
      <StepContainer
        step="1"
        icon={stepOneIcon}
        handleOpen={handleOpenStepOne}
        openStep={openStepOne}
      >
        <div className={`step_content show_step`}>
          <p className="step_description">{translation.step_one_desc}</p>
          <form onSubmit={handleSubmit(onSubmit)} className="step_form">
            <div className="step_fields">
              <SelectBox
                name="currency"
                label={translation.currency_mandatory}
                register={register}
                errors={errors}
                setValue={setValue}
                isMultiple={false}
                getValues={getValues}
                clearErrors={clearErrors}
                setError={setError}
                isSubmitted={isSubmitted}
              />
              <TextInput
                name="amount"
                label={translation.withdrawal_amount_mandatory}
                register={register}
                setValue={setValue}
                isNumber
                required
                errors={errors}
              />
              <TextInput
                name="address"
                label={translation.destination_address_mandatory}
                register={register}
                setValue={setValue}
                required
                errors={errors}
                pattern={ENGLISH_AND_NUMERIC_REGEX}
                patternError={translation.address_pattern_error}
              />
            </div>
            <MainButton
              name={translation.next}
              customClass="width_fit custom_button"
              type="submit"
            />
          </form>
        </div>
      </StepContainer>
      <StepContainer
        step="2"
        icon={stepTwoIcon}
        handleOpen={handleOpenStepTwo}
        openStep={openStepTwo}
      >
        <div className={`step_content ${openStepTwo ? "show_step" : ""}`}>
          <p className="step_description">{translation.step_two_desc}</p>
          <div className="second_step_field">
            <p className="verify_code_text">
              {translation.enter_verification_code}
            </p>
            <form className="verify_component" onSubmit={handleWithdraw}>
              <div className="verify_inputs">
                {VERIFICATION_CODE_INPUTS.slice(0, 3).map((index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    onChange={(e) => handleInput(e, index)}
                    ref={inputRefs[index]}
                    onFocus={handleFocus}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={handlePaste}
                    className="verify_input"
                    placeholder="-"
                  />
                ))}
                <div className="verify_line"></div>
                {VERIFICATION_CODE_INPUTS.slice(3, 6).map((index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    onChange={(e) => handleInput(e, index)}
                    ref={inputRefs[index]}
                    onFocus={handleFocus}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={handlePaste}
                    className="verify_input"
                    placeholder="-"
                  />
                ))}
              </div>
              <MainButton
                name={translation.withdrawal}
                customClass="custom_button width_fit"
                disabled={code?.length < AUTH_CODE_LENGTH || loading}
                loading={loading}
                type="submit"
              />
            </form>
          </div>
        </div>
      </StepContainer>
    </div>
  );
};
