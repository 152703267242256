import { FC } from "react";
import ClipLoader from "react-spinners/ClipLoader";

interface IClipSpinner {
  color?: string;
  loading: boolean;
}

export const ClipSpinner: FC<IClipSpinner> = ({ color = "#000", loading }) => {
  return (
    <ClipLoader
      color={color}
      loading={loading}
      size={20}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};
