import { useTranslation } from "localization";
import { INFO_LIST } from "constants/global";

import infoIcon from "assets/images/infoIcon.svg";

export const InfoSection = () => {
  const translation = useTranslation();

  return (
    <div className="info_section">
      <div className="image_section">
        <img src={infoIcon} alt="Info" />
      </div>
      <div className="text_section">
        <p className="title">{translation.info_title}</p>
        <ul className="info_list">
          {INFO_LIST.map((element) => (
            <li key={element.id}>
              {!element.list.length ? (
                element.text
              ) : (
                <div className="list_section">
                  {element.list.map((item) => (
                    <div key={item.id} className="list_content">
                      <span className="list_title">{item.title}</span>
                      <span className="list_text">{item.text}</span>
                    </div>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
