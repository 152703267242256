import { FC, ReactNode } from "react";

import { useTranslation } from "localization";

import arrowIcon from "assets/images/arrow-down.svg";

interface IStep {
  step: string;
  children: ReactNode;
  icon: string;
  handleOpen: () => void;
  openStep: boolean;
}

export const StepContainer: FC<IStep> = ({
  step,
  children,
  icon,
  handleOpen,
  openStep,
}) => {
  const translation = useTranslation();
  return (
    <div className="steps_container">
      <div className="steps_header" onClick={handleOpen}>
        <div className="step_info">
          <img src={icon} alt="Step" className="step_icon" />
          <p className="step_name">{translation.step}</p>
        </div>
        <img
          src={arrowIcon}
          className={`arrow_icon ${openStep ? "rotate_icon" : ""}`}
          alt="Arrow"
        />
      </div>
      {children}
    </div>
  );
};
