import { BALANCE_TABLE_HEADER } from "constants/table";
import { BalanceHistoryTable } from "components";
import { useTranslation } from "localization";

export const UserBalanceHistory = () => {
  const translation = useTranslation();

  return (
    <div className="profile_right_side">
      <div className="profile_title_field">
        <h2 className="profile_element_title">{translation.balance_history}</h2>
      </div>
      <div className="transaction_container">
        <BalanceHistoryTable headerData={BALANCE_TABLE_HEADER} />
      </div>
    </div>
  );
};
