import { useTranslation } from "localization";
import { WalletSteps } from "./components";

export const UserWithdrawal = () => {
  const translation = useTranslation();
  return (
    <div className="profile_right_side">
      <div className="profile_title_field">
        <h2 className="profile_element_title">{translation.withdrawal}</h2>
      </div>
      <WalletSteps />
    </div>
  );
};
