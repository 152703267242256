import { useTranslation } from "localization";
import { FC } from "react";

import amdIcon from "assets/images/armenianCurrency.svg";

interface IAboutTrade {
  currencyTitle: string;
  amount: string;
  priceRate: string;
  currencyIcon: string;
  currencyLongTitle: string;
}

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

export const AboutTrade: FC<IAboutTrade> = ({
  currencyTitle,
  currencyIcon,
  currencyLongTitle,
  amount,
  priceRate,
}) => {
  const translation = useTranslation();
  return (
    <div className="about_trade">
      <div className="about_item">
        <div className="item_title">{translation.currency}</div>
        <div className="item_value value_with_image">
          <img
            src={`${imageUrl}${currencyIcon}`}
            alt="Currency"
            className="about_trade_icon"
            crossOrigin="anonymous"
          />
          <div className="currency_name_field">
            <span>{currencyLongTitle}</span>
            <span className="currency_line"></span>
            <span>{currencyTitle}</span>
          </div>
        </div>
      </div>
      <div className="about_item">
        <div className="item_title">{translation.amount}</div>
        <div className="item_value">{amount}</div>
      </div>
      <div className="about_item">
        <div className="item_title">{translation.usd_to_amd}</div>
        <div className="item_value">
          <div className="price_rate_section">
            <p className="element_info">{priceRate}</p>
            <img src={amdIcon} alt="Armenian Valuta" className="rate_icon" />
          </div>
        </div>
      </div>
    </div>
  );
};
