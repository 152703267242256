import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { MerchantsTrades, UserTrades } from "components";
import { useTranslation } from "localization";
import { useAppSelector } from "reduxState/store";
import { GLOBAL, USER_TYPES } from "constants/global";

import arrowIcon from "assets/images/arrow-down.svg";

export const OngoingTrades = () => {
  const user = useAppSelector((state) => state.auth.user);

  const query = new URLSearchParams(useLocation().search);
  const tradeId = query.get(GLOBAL.id);
  const requestId = query.get(GLOBAL.request_id);

  const translation = useTranslation();
  const navigate = useNavigate();

  const handleBack = () => {
    query.delete(GLOBAL.id);
    navigate("");
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [tradeId, requestId]);

  return (
    <div className="ongoing_trades">
      <div className="trades_title_section">
        <img
          src={arrowIcon}
          className={`arrow_back ${
            tradeId || (!tradeId && requestId) ? "show_back_icon" : ""
          }`}
          alt="Arrow"
          onClick={handleBack}
        />
        <h1
          className={`trades_title ${
            tradeId || (!tradeId && requestId) ? "hide_trades_title" : ""
          }`}
        >
          {user?.role === USER_TYPES.merchant
            ? translation.ongoing_trades
            : translation.ongoing_requests}
        </h1>
      </div>
      {user?.role === USER_TYPES.merchant ? (
        <MerchantsTrades />
      ) : (
        <UserTrades />
      )}
    </div>
  );
};
