import { useRef, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ModalContainer } from "../ModalAuth";
import useOutsideClick from "hooks/useOutsideClick";
import { useTranslation } from "localization";
import { TextInput } from "components/inputs";
import { GLOBAL, PASSWORD_REGEX, PATHNAMES } from "constants/global";
import { InfoSection } from "./InfoSection";
import { MainButton } from "components/buttons";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { setShowChangePassword } from "reduxState/features/main";
import { changePassword, getUser } from "reduxState";
import { setOpenToast } from "reduxState/features/toast";

import closeIcon from "assets/images/close.svg";
import changePasswordIcon from "assets/images/changePassword.svg";

export const ChangePasswordModal = () => {
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const translation = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loading = useAppSelector((state) => state.auth.changePasswordLoading);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    dispatch(setShowChangePassword(false));
  };

  const handleNavigateAfterSuccess = () => {
    localStorage.removeItem(GLOBAL.access_token);
    dispatch(getUser({}));
    navigate(PATHNAMES.home);
    navigate(0);
    handleClose();
  };

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    dispatch(
      changePassword({ data, navigate: handleNavigateAfterSuccess })
    ).finally(() => {
      setTimeout(() => {
        dispatch(setOpenToast(false));
      }, 3000);
    });
  };

  useOutsideClick({ ref, handler: handleClose });

  return (
    <ModalContainer>
      <div className="modal change_password_modal" ref={ref}>
        <div className="logout_header">
          <div className="close_modal">
            <img src={closeIcon} alt="Close" onClick={handleClose} />
          </div>
          <div className="logout_header_element">
            <img
              src={changePasswordIcon}
              alt="Change Password"
              className="logout_image"
            />
            <p className="logout_title">{translation.change_password}</p>
          </div>
        </div>
        <form className="modal_form" onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            type={showOldPassword ? "text" : "password"}
            name="oldPassword"
            label={translation.current_password}
            isPassword={true}
            showPassword={showOldPassword}
            handleShowPassword={handleShowOldPassword}
            register={register}
            errors={errors}
            setValue={setValue}
          />

          <div className="image_input">
            <TextInput
              type={showNewPassword ? "text" : "password"}
              name="newPassword"
              label={translation.new_password}
              isPassword={true}
              showPassword={showNewPassword}
              handleShowPassword={handleShowNewPassword}
              register={register}
              pattern={PASSWORD_REGEX}
              patternError={translation.pattern_error}
              errors={errors}
              setValue={setValue}
            />
            {!errors.newPassword && <InfoSection />}
          </div>
          <TextInput
            type={showConfirmPassword ? "text" : "password"}
            name="passwordConfirm"
            label={translation.confirm_password}
            isPassword={true}
            showPassword={showConfirmPassword}
            handleShowPassword={handleShowConfirmPassword}
            register={register}
            errors={errors}
            setValue={setValue}
            validate={() => watch("newPassword") === watch("passwordConfirm")}
          />
          <div className="logout_actions">
            <MainButton
              name={translation.change_password}
              customClass="custom_button width_100"
              type="submit"
              loading={loading}
            />
          </div>
        </form>
      </div>
    </ModalContainer>
  );
};
