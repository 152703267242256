import { ForMerchantForm, ForMerchantsInfo } from "components";

export const ForMerchants = () => {
  return (
    <div className="for_merchants_page">
      <ForMerchantsInfo />
      <div className="sign_up_container merchant_form_container">
        <ForMerchantForm />
      </div>
    </div>
  );
};
