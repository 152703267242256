import { FOOTER } from "constants/footer";
import { FooterContent, FooterSocial } from "./elements";
import { FooterContactUs } from "./elements/FooterContactUs";

export const FooterBody = () => {
  return (
    <div className="footer_body">
      {FOOTER.map((element) => (
        <FooterContent
          key={element.id}
          id={element.id}
          title={element.title}
          components={element.components}
        />
      ))}
      <FooterContactUs />
      <FooterSocial />
    </div>
  );
};
