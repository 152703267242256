import { FC } from "react";

import { TableActions } from "./TableActions";
import { ITransactionTableHeader } from "types";

export const TableHeader: FC<ITransactionTableHeader> = ({
  openFilters,
  setOpenFilters,
}) => {
  return (
    <div className="table_header transactions_table_header">
      <TableActions openFilters={openFilters} setOpenFilters={setOpenFilters} />
    </div>
  );
};
