import { createAsyncThunk } from "@reduxjs/toolkit";

import { banksCurrenciesApi } from "service";

export const getBanks = createAsyncThunk(
  "getBanks",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await banksCurrenciesApi.getBanks();
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCurrencies = createAsyncThunk(
  "getCurrencies",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await banksCurrenciesApi.getCurrencies();
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
