import { useTranslation } from "localization";
import { UserInfo } from "./UserInfo";
import { UserActions } from "./UserActions";
import { useAppDispatch } from "reduxState/store";
import { setShowTwoFactor } from "reduxState/features/auth";
import { setShowChangePassword } from "reduxState/features/main";

import changePasswordIcon from "assets/images/profile/changePassword.svg";

export const UserProfileAccount = () => {
  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const handleTwoFactor = () => {
    dispatch(setShowTwoFactor(true));
  };

  const handleChangePassword = () => {
    dispatch(setShowChangePassword(true));
  };

  return (
    <div className="profile_right_side">
      <div className="profile_title_field">
        <h2 className="profile_element_title">{translation.account}</h2>
      </div>
      <div className="profile_account">
        <UserInfo />
        <UserActions
          icon={changePasswordIcon}
          text={translation.two_factor_auth}
          onClick={handleTwoFactor}
          showLabel={true}
        />
        <UserActions
          icon={changePasswordIcon}
          text={translation.change_password}
          onClick={handleChangePassword}
        />
      </div>
    </div>
  );
};
