import { FC, useState } from "react";

import { TableBody, TableFilters, TableHeader } from "./components";
import { IMainTable, ITableFilters } from "types";
import { BALANCE_TABLE_TABS } from "constants/table";

export const BalanceHistoryTable: FC<IMainTable> = ({ headerData }) => {
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<ITableFilters>({});
  const [activeTab, setActiveTab] = useState<string>(
    BALANCE_TABLE_TABS[0].value
  );

  return (
    <div className="main_table_container padding_0">
      <div className="main_table_content">
        <TableHeader
          setOpenFilters={setOpenFilters}
          openFilters={openFilters}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div
          className={`filters_container ${
            openFilters ? "filter_container_show" : ""
          }`}
        >
          <TableFilters setFilters={setFilters} openFilters={openFilters} />
        </div>
        <TableBody headerData={headerData} filters={filters} />
      </div>
    </div>
  );
};
