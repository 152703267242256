import { Dispatch, FC, MouseEvent, SetStateAction, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { SelectElement } from "./SelectElement";

import arrowIcon from "assets/images/arrow-down-grey.svg";
import { IBank, ITableFilters } from "types";
import { useTranslation } from "localization";
import useOutsideClick from "hooks/useOutsideClick";
import { GLOBAL } from "constants/global";

interface ISelectItem {
  name: string;
  openSelect: boolean;
  setOpenSelect: Dispatch<SetStateAction<boolean>>;
  selectedNames: string[];
  setSelectedNames: Dispatch<SetStateAction<string[]>>;
  selectedIds: number[];
  setSelectedIds: Dispatch<SetStateAction<number[]>>;
  data: IBank[];
  setFilters: Dispatch<SetStateAction<ITableFilters>>;
  icon?: string;
  isLocalIcon?: boolean;
}

export const FilterMultipleSelect: FC<ISelectItem> = ({
  name,
  openSelect,
  setOpenSelect,
  selectedNames,
  setSelectedNames,
  selectedIds,
  setSelectedIds,
  data,
  setFilters,
  isLocalIcon = false,
}) => {
  const translation = useTranslation();
  const querySearch = new URLSearchParams(useLocation().search);

  const ref = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const handleSelect = (
    e: MouseEvent<HTMLElement>,
    id: number,
    value: string,
    setChecked: Dispatch<SetStateAction<boolean>>
  ) => {
    e.preventDefault();

    if (selectedIds.includes(id)) {
      const newSelectedItemName = selectedNames.filter(
        (elem) => elem !== value
      );
      const newSelectedItemId = selectedIds.filter((elem) => elem !== id);

      setSelectedIds(newSelectedItemId);
      setSelectedNames(newSelectedItemName);

      querySearch.set(GLOBAL.page, `${GLOBAL.default_page}`);
      navigate(`?${querySearch.toString()}`);
      setFilters((prev) => ({ ...prev, [name]: newSelectedItemId }));

      setChecked(false);
    } else {
      const newSelectedItemName = [...selectedNames, value];
      const newSelectedItemId = [...selectedIds, id];

      setSelectedNames(newSelectedItemName);
      setSelectedIds(newSelectedItemId);

      querySearch.set(GLOBAL.page, `${GLOBAL.default_page}`);
      navigate(`?${querySearch.toString()}`);
      setFilters((prev) => ({ ...prev, [name]: newSelectedItemId }));

      setChecked(true);
    }
  };

  const handleShowDropdown = () => {
    setOpenSelect(!openSelect);
  };

  const handleCloseDropdown = () => {
    setOpenSelect(false);
  };

  useOutsideClick({ ref: ref, handler: handleCloseDropdown });

  return (
    <div className="input_filed_content" ref={ref}>
      <input className="select_input" id={name} name={name} />
      <div
        className={`custom_select_box ${openSelect ? "select_box_open" : ""}`}
        onClick={handleShowDropdown}
      >
        <p
          className={`select_item_name ${
            selectedNames.length ? "" : "select_placeholder"
          }`}
        >
          {selectedNames.length ? selectedNames?.join(", ") : translation[name]}
        </p>
        <img
          src={arrowIcon}
          alt="Search"
          className={
            openSelect ? "rotate_select_adornment" : "select_adornment"
          }
        />
      </div>
      {openSelect && (
        <div className="select_dropdown filter_select">
          {data.map((element) => (
            <SelectElement
              handleSelect={handleSelect}
              element={element}
              key={element.id}
              isChecked={selectedIds.includes(element.id)}
              icon={element?.icon}
              isLocalIcon={isLocalIcon}
            />
          ))}
        </div>
      )}
    </div>
  );
};
