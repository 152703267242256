import accountIcon from "assets/images/profile/account.svg";
import balanceIcon from "assets/images/profile/balance.svg";
import logoutIcon from "assets/images/profile/logout.svg";
import walletIcon from "assets/images/profile/wallet.svg";
import withdrawalIcon from "assets/images/profile/withdrawal.svg";
import activeAccountIcon from "assets/images/profile/activeAccount.svg";
import activeBalanceIcon from "assets/images/profile/activeBalance.svg";
import activeWalletIcon from "assets/images/profile/activeWallet.svg";
import activeWithdrawalIcon from "assets/images/profile/activeWithdrawal.svg";

export const PROFILE_LIST = [
  {
    id: 1,
    name: "account",
    icon: accountIcon,
    path: "/profile/account",
  },
  {
    id: 2,
    name: "wallet",
    icon: walletIcon,
    path: "/profile/wallet",
  },
  {
    id: 3,
    name: "withdrawal",
    icon: withdrawalIcon,
    path: "/profile/withdrawal",
  },
  {
    id: 4,
    name: "balance_history",
    icon: balanceIcon,
    path: "/profile/balance",
  },
  {
    id: 5,
    name: "transaction_history",
    icon: balanceIcon,
    path: "/profile/transaction",
  },
  {
    id: 6,
    name: "logout",
    icon: logoutIcon,
    path: "",
  },
];

export const PROFILE_MENU_LIST = [
  {
    id: 1,
    name: "account",
    icon: accountIcon,
    activeIcon: activeAccountIcon,
    path: "/profile/account",
  },
  {
    id: 2,
    name: "wallet",
    icon: walletIcon,
    activeIcon: activeWalletIcon,
    path: "/profile/wallet",
  },
  {
    id: 3,
    name: "withdrawal",
    icon: withdrawalIcon,
    activeIcon: activeWithdrawalIcon,
    path: "/profile/withdrawal",
  },
  {
    id: 4,
    name: "balance_history",
    icon: balanceIcon,
    activeIcon: activeBalanceIcon,
    path: "/profile/balance",
  },
  {
    id: 5,
    name: "transaction_history",
    icon: balanceIcon,
    activeIcon: activeBalanceIcon,
    path: "/profile/transaction",
  },
];

export const ACCOUNT_INPUTS = [
  {
    id: 1,
    name: "firstName",
    label: "first_name",
    isRequired: true,
  },
  {
    id: 2,
    name: "lastName",
    label: "last_name",
    isRequired: true,
  },
  {
    id: 3,
    name: "telegramUserName",
    label: "telegram_name",
    isRequired: false,
  },
  {
    id: 4,
    name: "nickname",
    label: "nickname",
    isRequired: false,
    disable: true,
    hideForMerchant: true,
  },
  {
    id: 5,
    name: "phoneNumber",
    label: "phone_number",
    isMerchant: true,
    isRequired: true,
    isNumber: true,
  },
  {
    id: 6,
    name: "email",
    label: "email",
    type: "email",
    isRequired: true,
    disable: true,
  },
];

export const ACCOUNT_DISABLED_FIELDS = ["nickname", "email"];
