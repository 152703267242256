import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "reduxState/store";
import { PolicyComponent } from "components";
import { getPrivacyPolicy } from "reduxState";

export const PrivacyPolicy = () => {
  const data = useAppSelector((state) => state.content.privacyPolicy);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPrivacyPolicy());
  }, []);

  return <PolicyComponent title={data?.title} content={data?.content} />;
};
