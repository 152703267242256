import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  ONGOING_TRADES_TABS,
  ONGOING_TRADES_USER_TABS,
  TRADE_REQUEST_STATUSES,
} from "constants/trade";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { getMerchantTrades, getUserTradeRequests } from "reduxState";
import { TradeItem } from "./TradeItem";
import { GLOBAL } from "constants/global";
import { UserTradeItem } from "./UserTradeItem";
import { Pagination, MainTab, PageLoading } from "components";
import { EmptyPageMessage } from "./EmptyPageMessage";
import { useTranslation } from "localization";

interface ITradeList {
  isMerchant: boolean;
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
}

export const TradesList: FC<ITradeList> = ({
  isMerchant,
  activeTab,
  setActiveTab,
}) => {
  const queryParams = new URLSearchParams(useLocation().search);
  const queryPage = queryParams.get(GLOBAL.page);
  const queryPerPage = queryParams.get(GLOBAL.per_page);
  const tradeId = queryParams.get(GLOBAL.id);
  const requestId = queryParams.get(GLOBAL.request_id);

  const [page, setPage] = useState<number>(
    queryPage ? Number(queryPage) : GLOBAL.default_page
  );
  const [perPage, setPerPage] = useState<number>(
    queryPerPage ? Number(queryPerPage) : GLOBAL.default_per_page
  );
  const [isTabChanged, setIsTabChanged] = useState<boolean>(false);

  const merchantData = useAppSelector((state) => state.trades.tradesList);
  const merchantDataTotal = useAppSelector(
    (state) => state.trades.tradesListTotal
  );
  const userData = useAppSelector((state) => state.trades.userRequests);
  const userDataTotal = useAppSelector(
    (state) => state.trades.userRequestsTotal
  );
  const loading = useAppSelector((state) =>
    isMerchant
      ? state.trades.tradesListLoading
      : state.trades.userRequestsLoading
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const translation = useTranslation();

  useEffect(() => {
    if (isMerchant) {
      const params = {
        filters: {
          status: `${activeTab}`,
          state: "open",
        },
        page: `${page}`,
        per_page: `${perPage}`,
      };
      dispatch(getMerchantTrades({ params }));
    } else {
      const params = {
        filters: {
          statuses: activeTab
            ? [`${activeTab}`]
            : [
                TRADE_REQUEST_STATUSES.accepted,
                TRADE_REQUEST_STATUSES.pending,
                TRADE_REQUEST_STATUSES.completed,
              ],
        },
        page: `${page}`,
        per_page: `${perPage}`,
      };
      dispatch(getUserTradeRequests({ params }));
    }
  }, [activeTab, page, perPage]);

  useEffect(() => {
    if (queryPage) {
      setPage(Number(queryPage));

      if (Number(queryPage) !== page) {
        queryParams.delete(GLOBAL.id);
        navigate(`?${queryParams.toString()}`);
      }
    }
  }, [queryPage]);

  useEffect(() => {
    if (isTabChanged) {
      if (isMerchant) {
        queryParams.delete(GLOBAL.id);
      } else {
        queryParams.delete(GLOBAL.request_id);
      }
      navigate(`?${queryParams.toString()}`);
      setIsTabChanged(false);
    }
  }, [isTabChanged]);

  return (
    <div
      className={`ongoing_trades_list ${
        tradeId ? "hide_requests_mobile" : ""
      } ${!isMerchant && requestId ? "hide_requests_mobile" : ""}`}
    >
      <MainTab
        data={isMerchant ? ONGOING_TRADES_TABS : ONGOING_TRADES_USER_TABS}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        customClass="trade_tabs"
        setIsTabChanged={setIsTabChanged}
      />

      {loading ? (
        <PageLoading color="#fff" loading={loading} />
      ) : (
        <>
          {isMerchant && !merchantData.length && (
            <EmptyPageMessage
              title={translation.no_trades}
              message={translation.no_available_trades}
            />
          )}
          {!isMerchant && !userData.length && (
            <EmptyPageMessage
              title={translation.no_requests}
              message={translation.no_requests_available}
            />
          )}
          <div className="trades_section">
            {isMerchant
              ? merchantData.map((element) => (
                  <TradeItem
                    key={element.id}
                    element={element}
                    activeTab={activeTab}
                  />
                ))
              : userData.map((element) => (
                  <UserTradeItem key={element.id} element={element} />
                ))}
          </div>
        </>
      )}
      <div className="trade_pagination">
        <Pagination
          perPage={perPage}
          total={isMerchant ? merchantDataTotal : userDataTotal}
          page={page}
          setPage={setPage}
          setPerPage={setPerPage}
          isDropDownUp={true}
        />
      </div>
    </div>
  );
};
