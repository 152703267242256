import { FC } from "react";

interface ILabel {
  text: string;
  type: string;
}

export const Label: FC<ILabel> = ({ text, type }) => {
  return (
    <div className={`label label_${type}`}>
      <div className={`circle circle_${type}`}></div>
      <div className="label_name">{text}</div>
    </div>
  );
};
