import { Fragment } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import { MainButton, PageTitle, TextAreaInput, TextInput } from "components";
import { useTranslation } from "localization";
import { CONTACT_US_FORMS } from "constants/forms";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { sendContactInfo } from "reduxState";
import { setOpenToast } from "reduxState/features/toast";

export const ContactUs = () => {
  const loading = useAppSelector((state) => state.content.sendContactUsLoading);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    dispatch(sendContactInfo({ data }))
      .then(() => reset())
      .finally(() => {
        setTimeout(() => {
          dispatch(setOpenToast(false));
        }, 3000);
      });
  };

  return (
    <div className="contact_us_container">
      <div className="contact_us_content">
        <div className="contact_us_form">
          <PageTitle title={translation.contact_us} />
          <form className="form_section" onSubmit={handleSubmit(onSubmit)}>
            {CONTACT_US_FORMS.map((field) => (
              <Fragment key={field.id}>
                {field.type === "textarea" ? (
                  <TextAreaInput
                    name="message"
                    label={translation.message}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                  />
                ) : field.label === "email" ? (
                  <TextInput
                    name={field.name}
                    label={translation[field.label]}
                    type={field.type || "text"}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                    pattern={field.regex}
                    patternError={translation.email_pattern_error}
                  />
                ) : (
                  <TextInput
                    name={field.name}
                    label={translation[field.label]}
                    type={field.type || "text"}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                  />
                )}
              </Fragment>
            ))}
            <MainButton
              customClass="custom_button submit_button"
              name="Submit"
              type="submit"
              loading={loading}
              disabled={loading}
            />
          </form>
        </div>
      </div>
    </div>
  );
};
