import { UserProfileAccount } from "components";

import { ProfilePage } from "./Profile";

export const ProfileAccount = () => {
  return (
    <ProfilePage>
      <UserProfileAccount />
    </ProfilePage>
  );
};
