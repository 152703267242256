import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { GLOBAL, TABLET_WINDOW_WIDTH } from "constants/global";
import { CurrencyElement } from "./CurrencyElement";
import { useAppSelector } from "reduxState/store";

export const CurrenciesField = () => {
  const query = new URLSearchParams(useLocation().search);
  const selectedWalletId = query.get(GLOBAL.wallet_id);

  const user = useAppSelector((state) => state.auth.user);

  const navigate = useNavigate();
  const wallets = user?.wallets;

  useEffect(() => {
    if (window.innerWidth > TABLET_WINDOW_WIDTH) {
      query.set(GLOBAL.wallet_id, `${wallets?.[0].id}`);
      navigate(`?${query.toString()}`);
    }
  }, []);

  return (
    <div
      className={`wallet_currencies ${
        selectedWalletId ? "hide_wallet_currencies" : ""
      }`}
    >
      {wallets?.map((element) => (
        <CurrencyElement element={element} key={element.id} />
      ))}
    </div>
  );
};
