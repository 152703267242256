import { useEffect, useState } from "react";

import { getFaqData } from "reduxState";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { useTranslation } from "localization";
import { FaqElement, MainButton, PageTitle } from "components";
import { GLOBAL, PER_PAGE } from "constants/global";

export const FaqPage = () => {
  const [page, setPage] = useState<number>(GLOBAL.default_page);

  const data = useAppSelector((state) => state.content.faqData);
  const total = useAppSelector((state) => state.content.faqTotal);
  const loading = useAppSelector((state) => state.content.faqLoading);

  const dispatch = useAppDispatch();
  const translation = useTranslation();

  const showLoadButton =
    Number(total) > PER_PAGE && PER_PAGE * page < Number(total);

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
  };

  useEffect(() => {
    const params = {
      page,
      per_page: PER_PAGE,
    };
    dispatch(getFaqData({ params }));
  }, [page]);

  return (
    <div className="faq_page">
      <PageTitle title={translation.faq} />
      <div className="faq_body">
        {data.map((elem) => (
          <FaqElement
            id={elem?.id}
            key={elem?.id}
            answer={elem.answer}
            question={elem.question}
          />
        ))}
        {showLoadButton && (
          <MainButton
            customClass="custom_button submit_button"
            name={translation.load_more}
            loading={loading}
            onClick={handleLoadMore}
          />
        )}
      </div>
    </div>
  );
};
