import { FC } from "react";
import { Link } from "react-router-dom";

import { IFooterElement } from "types";
import { useTranslation } from "localization";
import { MAIL_TYPE, PHONE_TYPE } from "constants/footer";

export const FooterContent: FC<IFooterElement> = ({
  id,
  title,
  components,
}) => {
  const translation = useTranslation();

  return (
    <div className="footer_component_element">
      <h4 className="element_title">{translation[title]}</h4>
      <ul className="footer_list">
        {components.map((item) => (
          <li key={item.id} className="item">
            {item.type === MAIL_TYPE ? (
              <a href={`mailto::${item.path}`} className="item_with_tag">
                {item.title}
              </a>
            ) : item.type === PHONE_TYPE ? (
              <a href={`tel::${item.path}`} className="item_with_tag">
                {item.title}
              </a>
            ) : (
              <Link to={item.path} className="item_with_tag">
                {translation[item.title]}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
