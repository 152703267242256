import { FC } from "react";

import { useTranslation } from "localization";
import { RequestTimer } from "./RequestTimer";
import { IUserRequest } from "types";
import { DEFAULT_TIMER_MINUTES } from "constants/trade";

interface IRequestElement {
  element: IUserRequest;
}

export const UserRequestElement: FC<IRequestElement> = ({ element }) => {
  const translation = useTranslation();

  const dateNow = new Date(element?.timer_updated_at);

  dateNow.setMinutes(dateNow.getMinutes() + DEFAULT_TIMER_MINUTES);

  return (
    <div className="request_trade_element">
      <div className="request_section">
        <div className="request_info_section">
          <div className="amount_section">
            <p className="amount_field">
              {translation.requested_amount}{" "}
              <span className="request_amount">{element?.amount}</span>
            </p>
          </div>
          <div className="request_time">
            <p className="request_time_text">{translation.user_equest_time}</p>
            <RequestTimer
              date={dateNow}
              isArbitraged={element?.is_arbitraged}
              elementId={element?.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
