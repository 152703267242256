import { useAppDispatch, useAppSelector } from "reduxState/store";

import { BalanceSection } from "./BalanceSection";
import { MenuList } from "./MenuList";
import { useTranslation } from "localization";
import { setShowLogoutPopup } from "reduxState/features/main";

import avatarIcon from "assets/images/avatar.svg";
import logoutIcon from "assets/images/profile/logout.svg";
import verifyIcon from "assets/images/profile/verified.svg";

export const ProfileMenu = () => {
  const user = useAppSelector((state) => state.auth.user);

  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(setShowLogoutPopup(true));
  };

  return (
    <div className="profile_menu_container" id="profile_menu">
      <div className="profile_menu">
        <div className="user_info">
          <div className="user_avatar_field">
            <img src={avatarIcon} alt="Avatar" className="user_avatar" />
            {user?.isVerifiedByAdmin && (
              <div className="verify_field">
                <img src={verifyIcon} alt="Verify" className="verify_icon" />
                <p className="verify_text">{translation.verified}</p>
              </div>
            )}
          </div>
          <p className="user_name">{`${user?.firstName} ${user?.lastName}`}</p>
          {!!user?.nickname && (
            <p className="user_nickname">{user?.nickname}</p>
          )}
        </div>
        <BalanceSection />
        <MenuList />
      </div>
      <div className="profile_logout" onClick={handleLogout}>
        <img src={logoutIcon} alt="Logout" className="logout_icon" />
        <p className="logout_name">{translation.logout}</p>
      </div>
    </div>
  );
};
