import { Copyright, FooterBody } from "./components";
import lightIcon from "assets/images/social/light.svg";

export const Footer = () => {
  return (
    <div className="footer_container">
      <FooterBody />
      <Copyright />
      <div className="background_animation">
        <img src={lightIcon} alt="Light" className="light_background" />
      </div>
    </div>
  );
};
