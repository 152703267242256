import successIcon from "assets/images/toast/success.svg";
import errorIcon from "assets/images/toast/error.svg";
import infoIcon from "assets/images/toast/info.svg";
import warningIcon from "assets/images/toast/warning.svg";

interface IToast {
  [key: string]: string;
}

export const TOAST_ICONS: IToast = {
  success: successIcon,
  error: errorIcon,
  info: infoIcon,
  warning: warningIcon,
};

export const TOAST_TYPES = {
  success: "success",
  error: "error",
  info: "info",
  warning: "warning",
};

export const MESSAGE = "This is a message for success.";

export const TOAST_MESSAGES = {
  set_password: "You are successfully changed your password",
  forgot_password: "Mail has been sent to your email",
  reset_password: "Password changed successfully",
  two_factor_authentication: "Successfully authentication",
  copy_success: "QR code copied to clipboard",
  copy_code_success: "Code copied to clipboard",
};
