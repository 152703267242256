import { RefObject, useEffect } from "react";

interface IOutsideClick {
  ref: RefObject<HTMLDivElement>;
  handler: () => void;
}

const useOutsideClick = ({ ref, handler }: IOutsideClick) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler();
    };

    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

export default useOutsideClick;
