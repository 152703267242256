import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ITabs } from "types";
import { useTranslation } from "localization";
import { GLOBAL } from "constants/global";

export const MainTab: FC<ITabs> = ({
  data,
  setActiveTab,
  activeTab,
  customClass = "",
  setIsTabChanged,
}) => {
  const translation = useTranslation();
  const navigate = useNavigate();

  const querySearch = new URLSearchParams(useLocation().search);

  const handleActiveTab = (value: string) => {
    setActiveTab(value);

    if (setIsTabChanged) {
      setIsTabChanged(true);
    }

    if (querySearch) {
      querySearch.set(GLOBAL.page, `${GLOBAL.default_page}`);
      navigate(`?${querySearch.toString()}`);
    }
  };

  return (
    <div className={`main_tab ${customClass}`}>
      {data.map((element) => (
        <div
          key={element.id}
          className={`tab_element ${
            activeTab === element.value ? "active_tab" : ""
          }`}
          onClick={() => handleActiveTab(element.value)}
        >
          {translation[element.title]}
        </div>
      ))}
    </div>
  );
};
