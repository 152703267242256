import { useState } from "react";

import { useTranslation } from "localization";
import { useAppSelector } from "reduxState/store";

import balanceIcon from "assets/images/profile/profileBalance.svg";
import eyeSlashIcon from "assets/images/profile/eye-slash.svg";
import eyeIcon from "assets/images/profile/eye.svg";

export const BalanceSection = () => {
  const [showBalance, setShowBalance] = useState<boolean>(true);

  const user = useAppSelector((state) => state.auth.user);

  const translation = useTranslation();

  const handleEyeClick = () => {
    setShowBalance(!showBalance);
  };

  return (
    <div className="balance_section">
      <div className="balance_header">
        <img src={balanceIcon} alt="Balance" className="balance_icon" />
        <p className="balance_title">{translation.balance}</p>
        {showBalance ? (
          <img
            className="show_hide_icon"
            src={eyeIcon}
            alt="Eye"
            onClick={handleEyeClick}
          />
        ) : (
          <img
            className="show_hide_icon"
            src={eyeSlashIcon}
            alt="Eye"
            onClick={handleEyeClick}
          />
        )}
      </div>
      <div className="user_balance">
        <p className={`balance ${showBalance ? "hide_balance" : ""}`}>
          {`$${user?.balance?.toFixed(2)}`}
        </p>
      </div>
    </div>
  );
};
