import { EMAIL_REGEX } from "./global";

import idImage from "assets/images/inputs/passportImg.webp";
import passportImage from "assets/images/inputs/idImg.png";

export const SIGN_UP_FORMS = [
  {
    id: 1,
    name: "email",
    label: "email",
    type: "email",
    isRequired: true,
    regex: EMAIL_REGEX,
  },
  {
    id: 2,
    name: "firstName",
    label: "first_name",
    isRequired: true,
  },
  {
    id: 3,
    name: "lastName",
    label: "last_name",
    isRequired: true,
  },
  {
    id: 4,
    name: "telegramUserName",
    label: "telegram_name",
    isRequired: false,
  },
  {
    id: 5,
    name: "nickname",
    label: "nickname",
    isRequired: true,
  },
  {
    id: 6,
    name: "passportImage",
    label: "passport_image_without_mandatory",
    type: "file",
    isRequired: true,
  },
];

export const CONTACT_US_FORMS = [
  {
    id: 1,
    name: "fullName",
    label: "name_input",
    type: "text",
  },
  {
    id: 2,
    name: "email",
    label: "email",
    type: "email",
    regex: EMAIL_REGEX,
  },
  {
    id: 3,
    name: "message",
    label: "message",
    type: "textarea",
  },
];

export const ERROR_MESSAGES = {
  email_exist: "Email already exists",
};

export const MERCHANT_SIGN_UP_FORMS = [
  {
    id: 1,
    name: "firstName",
    label: "first_name",
    isRequired: true,
  },
  {
    id: 2,
    name: "lastName",
    label: "last_name",
    isRequired: true,
  },
  {
    id: 3,
    name: "telegramUserName",
    label: "telegram_name",
    isRequired: false,
  },
  {
    id: 4,
    name: "email",
    label: "email",
    type: "email",
    isRequired: true,
    regex: EMAIL_REGEX,
  },
  {
    id: 5,
    name: "phoneNumber",
    label: "phone_number",
    isMerchant: true,
    isRequired: true,
    isNumber: true,
  },
  {
    id: 6,
    name: "passportImage",
    label: "passport_image",
    type: "file",
    isRequired: true,
  },
  {
    id: 7,
    name: "password",
    label: "password",
    type: "password",
    isRequired: true,
  },
  {
    id: 8,
    name: "passwordConfirmation",
    label: "confirm_password",
    type: "password",
    isRequired: true,
  },
];

export const BECOME_MERCHANT_FORM = [
  {
    id: 1,
    name: "firstName",
    label: "first_name",
    isRequired: true,
  },
  {
    id: 2,
    name: "lastName",
    label: "last_name",
    isRequired: true,
  },
  {
    id: 3,
    name: "telegramUserName",
    label: "telegram_name",
    isRequired: false,
  },
  {
    id: 4,
    name: "email",
    label: "email",
    type: "email",
    isRequired: true,
    regex: EMAIL_REGEX,
  },
  {
    id: 5,
    name: "phoneNumber",
    label: "phone_number",
    isMerchant: true,
    isRequired: true,
    isNumber: true,
  },
  {
    id: 6,
    name: "passportImage",
    label: "passport_image",
    type: "file",
    isRequired: true,
  },
];

export const PASSPORT_IMAGES = [
  {
    id: 1,
    image: idImage,
  },
  {
    id: 2,
    image: passportImage,
  },
];
