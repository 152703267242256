import { useEffect } from "react";

import { MerchantSignUpForm } from "components";
import { GLOBAL } from "constants/global";

export const MerchantSignUp = () => {
  useEffect(() => {
    localStorage.removeItem(GLOBAL.access_token);
  }, []);

  return (
    <div className="sign_up_container">
      <MerchantSignUpForm />
    </div>
  );
};
