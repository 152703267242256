import { ICreateRoom } from "types/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatApi } from "service";

export const roomCreateAction = createAsyncThunk(
  "roomCreateAction",
  async ({ data }: { data: ICreateRoom }, { rejectWithValue }) => {
    try {
      const res = await chatApi.roomCreate(data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getChatMessages = createAsyncThunk(
  "getChatMessages",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const { data } = await chatApi.getChatMessages(id);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
