import { FC, Fragment, MouseEvent, memo } from "react";
import { v4 as uuidv4 } from "uuid";

import { IChatMessages } from "types";

interface IChatImages {
  element: IChatMessages;
  shownImagesCount: number;
  showAllImages: boolean;
  handleImagePreview: (images: string[] | File[], index: number) => void;
  handleShowAllImages: (e: MouseEvent<HTMLElement>) => void;
}

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

export const ChatImages: FC<IChatImages> = memo(
  ({
    element,
    shownImagesCount,
    showAllImages,
    handleImagePreview,
    handleShowAllImages,
  }) => {
    return (
      <div className="chat_image">
        {element?.attachmentPaths.map((image, index) =>
          index > shownImagesCount && !showAllImages ? (
            <Fragment key={index}></Fragment>
          ) : (
            <div
              className={`images_grid_element ${
                element.attachmentPaths.length === 1
                  ? "one_images_grid_element"
                  : ""
              }`}
              key={index}
            >
              <img
                src={
                  typeof image === "string"
                    ? `${imageUrl}${image}`
                    : URL.createObjectURL(image)
                }
                alt="Attach"
                crossOrigin="anonymous"
                className="attach_image"
                onClick={() =>
                  handleImagePreview(element.attachmentPaths, index)
                }
              />
              {index === shownImagesCount && !showAllImages && (
                <div className="last_element" onClick={handleShowAllImages}>
                  {`+${
                    element.attachmentPaths.length - (shownImagesCount + 1)
                  }`}
                </div>
              )}
            </div>
          )
        )}
      </div>
    );
  }
);
