import { useTranslation } from "localization";
import {
  ContactUs,
  HomeAboutUs,
  HomeBanner,
  MainTable,
  PageTitle,
} from "components";
import {
  HOME_PAGE_MERCHANT_TABLE_HEADER,
  HOME_PAGE_TABLE_HEADER,
} from "constants/table";
import { useAppSelector } from "reduxState/store";
import { USER_TYPES } from "constants/global";

export const HomePage = () => {
  const translation = useTranslation();

  const user = useAppSelector((state) => state.auth.user);
  const isMerchant = user?.role === USER_TYPES.merchant;

  return (
    <div className="home_page_container">
      <HomeBanner />
      <div className="home_trades_table">
        <PageTitle title={translation.home_table_title} />
        <MainTable
          headerData={
            isMerchant
              ? HOME_PAGE_MERCHANT_TABLE_HEADER
              : HOME_PAGE_TABLE_HEADER
          }
        />
      </div>
      <HomeAboutUs />
      <ContactUs />
    </div>
  );
};
