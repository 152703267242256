import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { ModalContainer } from "../ModalAuth";
import useOutsideClick from "hooks/useOutsideClick";
import { useTranslation } from "localization";
import { MainButton } from "components/buttons";
import { useAppDispatch } from "reduxState/store";
import { PATHNAMES } from "constants/global";
import { setShowVerifyModal } from "reduxState/features/main";

import closeIcon from "assets/images/close.svg";

export const VerifyInformModal = () => {
  const ref = useRef<HTMLDivElement>(null);

  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(setShowVerifyModal(false));
  };

  const handleNavigate = () => {
    navigate(PATHNAMES.account);
    dispatch(setShowVerifyModal(false));
  };

  useOutsideClick({ ref, handler: handleClose });

  return (
    <ModalContainer>
      <div className="modal change_password_modal" ref={ref}>
        <div className="logout_header">
          <div className="close_modal">
            <img src={closeIcon} alt="Close" onClick={handleClose} />
          </div>
          <div className="logout_header_element">
            <p className="logout_title">{translation.verify_your_account}</p>
          </div>
        </div>
        <div className="logout_body">
          <p className="logout_description">
            {translation.verify_account_modal_message}
          </p>
        </div>
        <div className="logout_actions">
          <MainButton
            name={translation.go_to_profile}
            customClass="custom_button width_100"
            onClick={handleNavigate}
          />
        </div>
      </div>
    </ModalContainer>
  );
};
