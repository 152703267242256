import { Dispatch, FC, SetStateAction } from "react";

import { IMainTableElement, IUserRequest } from "types";
import { useTranslation } from "localization";
import { Label } from "components";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { formatDate } from "helpers";
import { setChatMessages } from "reduxState/features/trades";

import amdCurrency from "assets/images/armenianCurrency.svg";

interface ITable {
  headerData: IMainTableElement[];
  setSelectedRequest: Dispatch<SetStateAction<IUserRequest>>;
  setOpenChatHistory: Dispatch<SetStateAction<boolean>>;
}

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

export const DesktopTable: FC<ITable> = ({
  headerData,
  setSelectedRequest,
  setOpenChatHistory,
}) => {
  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const data = useAppSelector((state) => state.trades.userRequests);

  const handleViewDetails = (transaction: IUserRequest) => {
    setSelectedRequest(transaction);
    setOpenChatHistory(true);
    dispatch(setChatMessages([]));
  };

  return (
    <div className="table_container_desktop">
      <table
        cellPadding="1"
        cellSpacing="1"
        bgcolor="#20212c"
        className="table"
      >
        <thead>
          <tr>
            {headerData.map((element) => (
              <th key={element.id} className="header_element">
                {translation[element.name]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((transaction) => (
            <tr key={transaction.id}>
              <td className="table_body_element">{transaction.id}</td>
              <td className="table_body_element">
                <div className="currency_row">
                  <img
                    src={`${imageUrl}${transaction?.currencyIcon}`}
                    alt="Currency"
                    className="currency_icon"
                    crossOrigin="anonymous"
                  />
                  <div className="currency_title_field">
                    <span>{transaction?.currencyLongTitle}</span>
                    <span className="currency_line"></span>
                    <span>{transaction?.currencyTitle}</span>
                  </div>
                </div>
              </td>
              <td className="table_body_element">{transaction.amount}</td>
              <td className="table_body_element">
                <div className="price_row">
                  <span>{transaction.priceRate}</span>
                  <img
                    src={amdCurrency}
                    alt="Currency"
                    className="amd_currency_icon"
                  />
                </div>
              </td>
              <td className="table_body_element">
                <Label
                  type={transaction.merchant_status}
                  text={translation[transaction.merchant_status]}
                />
              </td>
              <td className="table_body_element">
                {formatDate(transaction.created_at)}
              </td>
              <td className="table_body_element">
                <button
                  className={`view_details ${
                    !transaction?.hasMessage ? "disable_view_button" : ""
                  }`}
                  onClick={() => handleViewDetails(transaction)}
                  disabled={!transaction?.hasMessage}
                >
                  {translation.view_details}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
