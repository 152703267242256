import { FC } from "react";
import { useAppSelector } from "reduxState/store";

interface ICurrencyField {
  currencyTitle: string;
  amount: string;
}

export const CurrencyField: FC<ICurrencyField> = ({
  currencyTitle,
  amount,
}) => {
  const currenciesRate = useAppSelector(
    (state) => state.banksCurrencies.currenciesRate
  );

  const getUsdFieldValue = (title: string, amount: string) => {
    const currency = currenciesRate[title]?.currentRate;
    return `${(currency * Number(amount)).toFixed(2)} $`;
  };

  return (
    <div className="element_name_section">
      <span className="element_name">
        {getUsdFieldValue(currencyTitle, amount)}
      </span>
    </div>
  );
};
