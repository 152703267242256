import { FC, useRef, useState } from "react";

import { useTranslation } from "localization";
import { ISelectBox } from "types";
import { GLOBAL } from "constants/global";

import dangerIcon from "assets/images/danger.svg";

import useOutsideClick from "hooks/useOutsideClick";
import { SelectItem } from "./SelectItem";
import { SelectMultipleItem } from "./SelectMultipleItem";

export const SelectBox: FC<ISelectBox> = ({
  name,
  label,
  register,
  errors,
  setValue,
  isMultiple,
  getValues,
  clearErrors,
  setError,
  isSubmitted,
  existingBanks,
  setSelectedCurrencyShortName,
}) => {
  const [openSelect, setOpenSelect] = useState<boolean>(false);
  const [openMultipleSelect, setOpenMultipleSelect] = useState<boolean>(false);

  const translation = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const handleCloseDropdown = () => {
    setOpenSelect(false);
  };

  const handleCloseMultipleDropdown = () => {
    setOpenMultipleSelect(false);
  };

  useOutsideClick({ ref: ref, handler: handleCloseDropdown });
  useOutsideClick({ ref: ref, handler: handleCloseMultipleDropdown });

  return (
    <div className={`text_input_container ${!label ? "remove_gap" : ""} `}>
      <label className="input_label" htmlFor={name}>
        {label}
      </label>
      <div className="input_field" ref={ref}>
        {isMultiple ? (
          <SelectMultipleItem
            openSelect={openMultipleSelect}
            setOpenSelect={setOpenMultipleSelect}
            getValues={getValues}
            name={name}
            setValue={setValue}
            register={register}
            isSubmitted={isSubmitted}
            existingBanks={existingBanks}
          />
        ) : (
          <SelectItem
            openSelect={openSelect}
            setOpenSelect={setOpenSelect}
            getValues={getValues}
            name={name}
            handleCloseDropdown={handleCloseDropdown}
            setValue={setValue}
            isError={!!errors?.[name]}
            register={register}
            clearErrors={clearErrors}
            setError={setError}
            isSubmitted={isSubmitted}
            setSelectedCurrencyShortName={setSelectedCurrencyShortName}
          />
        )}
        {!!errors?.[name] && !isMultiple && (
          <div className="error_field">
            <img src={dangerIcon} alt="Danger" className="danger_icon" />
            <p className="error_message">
              {errors?.[name]?.type === GLOBAL.required
                ? translation.required
                : errors?.[name]?.type === GLOBAL.validate
                ? translation.password_matches
                : ""}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
