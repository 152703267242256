import { ChangeEvent, useEffect, useRef, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import { ModalContainer } from "../ModalAuth";
import useOutsideClick from "hooks/useOutsideClick";
import { useTranslation } from "localization";
import { Checkbox, SelectBox, TextInput } from "components";
import { MainButton } from "components/buttons";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { setShowCreateTrade } from "reduxState/features/main";
import { createTrade } from "reduxState";
import { setOpenToast } from "reduxState/features/toast";
import { MainTab } from "components/tabs";
import { TRADES_TABS } from "constants/trade";
import { ICreateTradeData } from "types";
import { COIN_USD_NAMES, GLOBAL, MIN_AMOUNT } from "constants/global";
import { socket } from "socket";
import { getNumericNumbers } from "helpers";

import closeIcon from "assets/images/close.svg";

export const CreateTradeModal = () => {
  const [activeTab, setActiveTab] = useState<string>(TRADES_TABS[0].value);
  const [priceRate, setPriceRate] = useState<string>("");
  const [checked, setChecked] = useState<boolean>(false);
  const [selectedCurrencyShortName, setSelectedCurrencyShortName] =
    useState<string>("");

  const translation = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state) => state.trades.createLoading);
  const banks = useAppSelector((state) => state.banksCurrencies.banks);
  const currenciesRate = useAppSelector(
    (state) => state.banksCurrencies.currenciesRate
  );

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    setError,
    control,
    formState: { errors, isSubmitted },
  } = useForm();

  const handleClose = () => {
    dispatch(setShowCreateTrade(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    const banksData = data?.banks?.length
      ? data?.banks
      : banks.map((elem) => elem.id);

    const transformedData = {
      ...data,
      banks: banksData,
      amount: Number(data.amount),
      priceRate: Number(data.priceRate),
      forVerifiedUsers: checked,
    };

    if (data.amount === "0" || data.priceRate === "0") {
      if (data.amount === "0") {
        setError("amount", {
          type: GLOBAL.custom,
          message: translation.value_must_be_greater,
        });
      }

      if (data.priceRate === "0") {
        setError("priceRate", {
          type: GLOBAL.custom,
          message: translation.value_must_be_greater,
        });
      }

      return;
    }

    if (data.amount < MIN_AMOUNT) {
      setError("amount", {
        type: GLOBAL.custom,
        message: translation.value_must_be_greater_than,
      });

      return;
    }

    dispatch(
      createTrade({
        data: transformedData as ICreateTradeData,
        navigate: handleClose,
      })
    )
      .then((res) => {
        const tradeId = res?.payload?.data?.data?.id;
        socket.emit("newTrade", { tradeId });
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setOpenToast(false));
        }, 3000);
      });
  };

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = getNumericNumbers(e.target.value);

    setPriceRate(value);
    if (value === "") {
      setValue(COIN_USD_NAMES.amount, "");
    } else {
      const currencyRate =
        currenciesRate[selectedCurrencyShortName]?.currentRate;
      const formatAmount = (Number(value) / currencyRate).toFixed(4);

      setValue(COIN_USD_NAMES.amount, `${formatAmount}`);
      clearErrors(COIN_USD_NAMES.amount);
    }
  };

  const handleAmountFieldChange = (value: string) => {
    if (value === "") {
      setPriceRate("");
    } else {
      const currencyRate =
        currenciesRate[selectedCurrencyShortName]?.currentRate;
      const formatPrice = (Number(value) * currencyRate).toFixed(2);

      setPriceRate(`${formatPrice}`);
    }
  };

  useEffect(() => {
    setValue("type", activeTab);
  }, [activeTab]);

  useOutsideClick({ ref, handler: handleClose });

  return (
    <ModalContainer>
      <div className="modal change_password_modal" ref={ref}>
        <div className="logout_header">
          <div className="close_modal">
            <img src={closeIcon} alt="Close" onClick={handleClose} />
          </div>
          <div className="logout_header_element">
            <p className="logout_title">{translation.create_trade}</p>
          </div>
        </div>
        <form className="modal_form" onSubmit={handleSubmit(onSubmit)}>
          <div className="trade_tabs">
            <MainTab
              data={TRADES_TABS}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
          </div>
          <SelectBox
            name="currency_id"
            label={translation.currency_mandatory}
            register={register}
            errors={errors}
            setValue={setValue}
            isMultiple={false}
            getValues={getValues}
            clearErrors={clearErrors}
            setError={setError}
            isSubmitted={isSubmitted}
            setSelectedCurrencyShortName={setSelectedCurrencyShortName}
          />
          <div className="request_trade_field">
            <div className={`text_input_container`}>
              <label className="input_label">{translation.usd}</label>
              <div className="input_field">
                <div className="input_filed_content">
                  <input
                    name={COIN_USD_NAMES.usd}
                    value={priceRate}
                    onChange={(e) => handlePriceChange(e)}
                    id={COIN_USD_NAMES.usd}
                    className="text_input disabled_text_input"
                    autoComplete="new-password"
                  />
                </div>
              </div>
            </div>
            <span className="equal_field">=</span>
            <TextInput
              name="amount"
              label={translation.crypto_coin_mandatory}
              register={register}
              errors={errors}
              setValue={setValue}
              isNumber
              required={true}
              setFieldValue={handleAmountFieldChange}
            />
          </div>
          <TextInput
            name="priceRate"
            label={translation.usd_price_rate}
            register={register}
            errors={errors}
            setValue={setValue}
            isNumber
          />
          <SelectBox
            name="banks"
            label={translation.banks_mandatory}
            register={register}
            errors={errors}
            setValue={setValue}
            isMultiple={true}
            getValues={getValues}
            clearErrors={clearErrors}
            setError={setError}
            isSubmitted={isSubmitted}
          />
          <div className="verify_checkbox_field">
            <Checkbox checked={checked} setChecked={setChecked} />
            <p className="verify_checkbox_text">
              {translation.verify_checkbox_text}
            </p>
          </div>
          <div className="logout_actions">
            <MainButton
              name={translation.create}
              customClass="custom_button width_100"
              type="submit"
              loading={loading}
              disabled={loading}
            />
          </div>
        </form>
      </div>
    </ModalContainer>
  );
};
