import armenia from "assets/images/countries/armenia.svg";
import usa from "assets/images/countries/usa.svg";
import russia from "assets/images/countries/russia.svg";
import accountIcon from "assets/images/profile/account.svg";
import balanceIcon from "assets/images/profile/balance.svg";
import walletIcon from "assets/images/profile/wallet.svg";
import withdrawalIcon from "assets/images/profile/withdrawal.svg";
import { IMenu } from "types";

export const MENU: IMenu = {
  guest: [
    {
      id: 1,
      name: "home",
      path: "/",
    },
    {
      id: 2,
      name: "merchants",
      path: "/for-merchants",
    },
    {
      id: 3,
      name: "faq",
      path: "/faq",
    },
  ],
  user: [
    {
      id: 1,
      name: "home",
      path: "/",
    },
    {
      id: 2,
      name: "merchants",
      path: "/for-merchants",
    },
    {
      id: 3,
      name: "faq",
      path: "/faq",
    },
    {
      id: 4,
      name: "ongoing_requests",
      path: "/ongoing-trades",
    },
  ],
  merchant: [
    {
      id: 1,
      name: "home",
      path: "/",
    },
    {
      id: 2,
      name: "merchants",
      path: "/for-merchants",
    },
    {
      id: 3,
      name: "faq",
      path: "/faq",
    },
    {
      id: 4,
      name: "ongoing_trades",
      path: "/ongoing-trades",
    },
  ],
};

export const COUNTRIES = [
  {
    id: 3,
    image: armenia,
    name: "arm",
    prefix: "hy",
  },
  {
    id: 1,
    image: usa,
    name: "usa",
    prefix: "en",
  },
  {
    id: 2,
    image: russia,
    name: "rus",
    prefix: "ru",
  },
];

export const LANGUAGE = "language";
export const DEFAULT_LANGUAGE = "en";

export const MOBILE_PROFILE_LIST = [
  {
    id: 1,
    name: "account",
    icon: accountIcon,
    path: "/profile/account",
  },
  {
    id: 2,
    name: "wallet",
    icon: walletIcon,
    path: "/profile/wallet",
  },
  {
    id: 3,
    name: "withdrawal",
    icon: withdrawalIcon,
    path: "/profile/withdrawal",
  },
  {
    id: 4,
    name: "balance_history",
    icon: balanceIcon,
    path: "/profile/balance",
  },
  {
    id: 5,
    name: "transaction_history",
    icon: balanceIcon,
    path: "/profile/transaction",
  },
];
