import { FC, useState } from "react";

import plusIcon from "assets/images/add.svg";

interface IFaqElement {
  answer: string;
  question: string;
  id: number;
}

export const FaqElement: FC<IFaqElement> = ({ answer, question, id }) => {
  const [showQuestion, setShowQuestion] = useState<boolean>(false);

  const handleShowHideQuestion = () => {
    setShowQuestion(!showQuestion);
  };

  return (
    <div
      className={`faq_element ${showQuestion ? "collapse_faq" : ""}`}
      onClick={handleShowHideQuestion}
    >
      <div className="faq_answer_section">
        <div className="faq_answer">{question}</div>
        <div className="plus_icon_field">
          <img
            src={plusIcon}
            alt="Plus"
            className={`plus_icon ${showQuestion ? "close_icon" : ""}`}
          />
        </div>
      </div>
      <div
        className={`faq_question ${
          showQuestion ? "show_question" : "hide_question"
        }`}
      >
        {answer}
      </div>
    </div>
  );
};
