import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "reduxState/store";
import { getAboutUs } from "reduxState";
import { StatisticElement } from "./Statistic";
import { useTranslation } from "localization";
import { STATISTICS_DATA } from "constants/global";

import aboutUsImage from "assets/images/banners/aboutUs.webp";

const imageBase = process.env.REACT_APP_API_IMAGE_URL;

export const HomeAboutUs = () => {
  const data = useAppSelector((state) => state.content.aboutUs);
  const currencies = useAppSelector(
    (state) => state.banksCurrencies.currencies
  );
  const currenciesRate = useAppSelector(
    (state) => state.banksCurrencies.currenciesRate
  );

  const dispatch = useAppDispatch();
  const translation = useTranslation();

  useEffect(() => {
    dispatch(getAboutUs());
  }, []);

  return (
    <div className="home_about_us">
      <div className="about_us_content">
        <div className="about_title">{data?.title}</div>
        <div className="about_description">{data?.content}</div>
        <div className="statistics_section">
          <StatisticElement
            count={STATISTICS_DATA.customers}
            text={translation.satisfied_customers}
          />
          <div className="line_between"></div>
          <StatisticElement
            count={STATISTICS_DATA.platform_uptime}
            text={translation.platform_uptime}
          />
          <div className="line_between"></div>
          <StatisticElement
            count={STATISTICS_DATA.market}
            text={translation.armenian_market}
            sub={STATISTICS_DATA.sup}
          />
        </div>
      </div>
      <div className="currencies_section">
        <img src={aboutUsImage} alt="About Us" className="about_us_image" />
        <div className="currencies_table">
          <ul className="currencies_list">
            {currencies?.map((currency) => (
              <li className="currency_element" key={currency?.id}>
                <div className="currency_info">
                  <img
                    src={`${imageBase}${currency?.icon}`}
                    alt="Currency"
                    crossOrigin="anonymous"
                    className="currency_icon"
                  />
                  <div className="currency_name_field">
                    <p className="name">{currency?.full_title}</p>
                    <span className="line"></span>
                    <p className="name">{currency?.title}</p>
                  </div>
                </div>
                <div className="currency_coast">
                  {`$${currenciesRate?.[currency?.title]?.currentRate?.toFixed(
                    2
                  )}`}
                </div>
                <div
                  className={`currency_percent ${
                    !currenciesRate?.[currency?.title].hasOwnProperty(
                      "direction"
                    )
                      ? ""
                      : currenciesRate?.[currency?.title]?.direction
                      ? "currency_percent_green"
                      : "currency_percent_red"
                  }`}
                >
                  {`${
                    !currenciesRate?.[currency?.title].hasOwnProperty(
                      "direction"
                    )
                      ? ""
                      : currenciesRate?.[currency?.title]?.direction
                      ? "+"
                      : ""
                  }${currenciesRate?.[currency?.title]?.differencePercent}%`}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
