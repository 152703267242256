import { FC } from "react";

import { ITableFilter } from "types";
import { TableFilter } from "components";
import { useAppSelector } from "reduxState/store";

export const TableActions: FC<ITableFilter> = ({
  openFilters,
  setOpenFilters,
  setRefresh,
  refresh,
}) => {
  const loading = useAppSelector((state) => state.trades.tradesLoading);

  return (
    <div className="table_actions">
      <TableFilter openFilters={openFilters} setOpenFilters={setOpenFilters} />
    </div>
  );
};
