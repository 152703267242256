import { IChatMessages, ITrades, ITradesSlice } from "types";
import { createSlice } from "@reduxjs/toolkit";

import {
  createTrade,
  getTrades,
  getMerchantTrades,
  changeTradeStatus,
  closeTrade,
  merchantTradeRequests,
  getUserTradeRequests,
  getUserTradeRequestById,
  getMerchantTradeRequests,
  getChatMessages,
  requestTrade,
  getMerchantRequests,
  chatArbitrageRequest,
  acceptCancelRequestUser,
  acceptCancelRequest,
} from "reduxState";
import { MESSAGE_NOTIFICATION_TYPES, REQUEST_DATA } from "constants/trade";

const initialState: ITradesSlice = {
  createLoading: false,
  tradesData: [],
  tradesLoading: false,
  tradesTotal: 0,
  tradesList: [],
  tradesListLoading: false,
  tradesListTotal: 0,
  changeStatusLoading: false,
  closeTradeLoading: false,
  merchantRequests: [],
  merchantRequestLoading: false,
  merchantRequestsTotal: 0,
  userRequests: [],
  userRequestsTotal: 0,
  userRequestsLoading: false,
  userRequestData: REQUEST_DATA,
  userRequestDataLoading: false,
  roomTitle: "",
  roomId: "",
  messages: [],
  messageTotal: null,
  requestTradeLoading: false,
  isChatArbitraged: false,
  isConfirmCancelRequest: "",
  showUserRateModal: false,
};

export const tradesSlice = createSlice({
  name: "Trades",
  initialState: initialState,
  reducers: {
    setChatMessages: (state, { payload }) => {
      state.messages = payload;
    },
    setTradesFromSocket: (state, { payload }) => {
      state.tradesData = [payload, ...state.tradesData];
    },
    popLastTrade: (state, { payload }) => {
      if (state.tradesData?.length >= payload.perPage) {
        state.tradesData.pop();
      }
    },
    setIsChatArbitraged: (state, { payload }) => {
      state.isChatArbitraged = payload;
    },
    setIsConfirmCancelRequest: (state, { payload }) => {
      state.isConfirmCancelRequest = payload;
    },
    setShowUserRateModal: (state, { payload }) => {
      state.showUserRateModal = payload;
    },
    setMerchantRequests: (state, { payload }) => {
      state.merchantRequests = payload;
    },
    removeMerchantRequestById: (state, { payload }) => {
      state.merchantRequests = state.merchantRequests.filter(
        (elem) => elem.id !== payload
      );
    },
    removeUserRequestById: (state, { payload }) => {
      state.userRequests = state.userRequests.filter(
        (elem) => elem.id !== payload
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createTrade.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(createTrade.fulfilled, (state, { payload }) => {
        state.createLoading = false;
      })
      .addCase(createTrade.rejected, (state) => {
        state.createLoading = false;
      });
    builder
      .addCase(getTrades.pending, (state) => {
        state.tradesLoading = true;
      })
      .addCase(getTrades.fulfilled, (state, { payload }) => {
        state.tradesData = payload.data;
        state.tradesTotal = Number(payload.total);
        state.tradesLoading = false;
      })
      .addCase(getTrades.rejected, (state) => {
        state.tradesLoading = false;
      });
    builder
      .addCase(getMerchantTrades.pending, (state) => {
        state.tradesListLoading = true;
      })
      .addCase(getMerchantTrades.fulfilled, (state, { payload }) => {
        state.tradesList = payload.data;
        state.tradesListTotal = Number(payload.total);
        state.tradesListLoading = false;
      })
      .addCase(getMerchantTrades.rejected, (state) => {
        state.tradesListLoading = false;
      });
    builder
      .addCase(changeTradeStatus.pending, (state) => {
        state.changeStatusLoading = true;
      })
      .addCase(changeTradeStatus.fulfilled, (state) => {
        state.changeStatusLoading = false;
      })
      .addCase(changeTradeStatus.rejected, (state) => {
        state.changeStatusLoading = false;
      });
    builder
      .addCase(closeTrade.pending, (state) => {
        state.closeTradeLoading = true;
      })
      .addCase(closeTrade.fulfilled, (state) => {
        state.closeTradeLoading = false;
      })
      .addCase(closeTrade.rejected, (state) => {
        state.closeTradeLoading = false;
      });
    builder
      .addCase(merchantTradeRequests.pending, (state) => {
        state.merchantRequestLoading = true;
      })
      .addCase(merchantTradeRequests.fulfilled, (state, { payload }) => {
        state.merchantRequestLoading = false;
        state.merchantRequests = payload?.data;
        state.merchantRequestsTotal = payload?.total;
      })
      .addCase(merchantTradeRequests.rejected, (state) => {
        state.merchantRequestLoading = false;
      });
    builder
      .addCase(getUserTradeRequests.pending, (state) => {
        state.userRequestsLoading = true;
      })
      .addCase(getUserTradeRequests.fulfilled, (state, { payload }) => {
        state.userRequestsLoading = false;
        state.userRequests = payload?.data;
        state.userRequestsTotal = Number(payload?.total);
      })
      .addCase(getUserTradeRequests.rejected, (state) => {
        state.userRequestsLoading = false;
      });
    builder
      .addCase(getMerchantRequests.pending, (state) => {
        state.userRequestsLoading = true;
      })
      .addCase(getMerchantRequests.fulfilled, (state, { payload }) => {
        state.userRequestsLoading = false;
        state.userRequests = payload?.data;
        state.userRequestsTotal = Number(payload?.total);
      })
      .addCase(getMerchantRequests.rejected, (state) => {
        state.userRequestsLoading = false;
      });
    builder
      .addCase(getUserTradeRequestById.pending, (state) => {
        state.userRequestDataLoading = true;
      })
      .addCase(getUserTradeRequestById.fulfilled, (state, { payload }) => {
        state.userRequestDataLoading = false;
        state.userRequestData = payload?.data;
        state.roomTitle = payload?.data?.roomTitle;
        state.roomId = payload?.data?.roomId;
      })
      .addCase(getUserTradeRequestById.rejected, (state) => {
        state.userRequestDataLoading = false;
      });
    builder
      .addCase(getMerchantTradeRequests.pending, (state) => {
        state.userRequestDataLoading = true;
      })
      .addCase(getMerchantTradeRequests.fulfilled, (state, { payload }) => {
        state.userRequestData = payload?.data;
        state.roomTitle = payload?.data?.roomTitle;
        state.roomId = payload?.data?.roomId;
        state.userRequestDataLoading = false;
      })
      .addCase(getMerchantTradeRequests.rejected, (state) => {
        state.userRequestDataLoading = false;
      });
    builder.addCase(getChatMessages.fulfilled, (state, { payload }) => {
      state.messageTotal = payload?.total;
      state.messages = payload?.data?.sort(
        (a: IChatMessages, b: IChatMessages) => {
          return (
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
          );
        }
      );
    });
    builder
      .addCase(requestTrade.pending, (state) => {
        state.requestTradeLoading = true;
      })
      .addCase(requestTrade.fulfilled, (state) => {
        state.requestTradeLoading = false;
      })
      .addCase(requestTrade.rejected, (state) => {
        state.requestTradeLoading = false;
      });
    builder.addCase(chatArbitrageRequest.fulfilled, (state) => {
      state.isChatArbitraged = true;
    });
    builder.addCase(acceptCancelRequestUser.fulfilled, (state) => {
      state.isConfirmCancelRequest = MESSAGE_NOTIFICATION_TYPES.COMPLETED;
    });
    builder.addCase(acceptCancelRequest.fulfilled, (state, { payload }) => {
      state.isConfirmCancelRequest =
        payload === MESSAGE_NOTIFICATION_TYPES.COMPLETED
          ? MESSAGE_NOTIFICATION_TYPES.COMPLETED
          : payload === MESSAGE_NOTIFICATION_TYPES.REJECTED
          ? MESSAGE_NOTIFICATION_TYPES.REJECTED
          : "";
    });
  },
});

export const {
  setChatMessages,
  setTradesFromSocket,
  popLastTrade,
  setIsChatArbitraged,
  setIsConfirmCancelRequest,
  setShowUserRateModal,
  setMerchantRequests,
  removeMerchantRequestById,
  removeUserRequestById,
} = tradesSlice.actions;

export default tradesSlice.reducer;
