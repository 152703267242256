import { FC } from "react";

import { ITextInput } from "types";
import { useTranslation } from "localization";

import dangerIcon from "assets/images/danger.svg";

export const TextAreaInput: FC<ITextInput> = ({
  name,
  label,
  register,
  setValue,
  required = true,
  errors,
}) => {
  const translation = useTranslation();

  return (
    <div className="text_input_container">
      <label className="input_label" htmlFor={name}>
        {label}
      </label>
      <div className="input_field">
        <div className="input_filed_content">
          <textarea
            {...register(name, {
              required,
              onChange: (e) => {
                if (e.target.value !== " ") {
                  return setValue(name, e.target.value);
                }
                return setValue(name, "");
              },
            })}
            name={name}
            id={name}
            rows={7}
            className={`text_input text_area_input ${
              !!errors?.[name] ? "input_error" : ""
            }`}
          />
        </div>
        {!!errors?.[name] && (
          <div className="error_field">
            <img src={dangerIcon} alt="Danger" className="danger_icon" />
            <p className="error_message">{translation.required}</p>
          </div>
        )}
      </div>
    </div>
  );
};
