import { FC } from "react";

import { useTranslation } from "localization";
import { ITransactionTableFilter } from "types";

import filterIcon from "assets/images/filter.svg";
import arrowGreen from "assets/images/arrowGreen.svg";

export const TableFilter: FC<ITransactionTableFilter> = ({
  openFilters,
  setOpenFilters,
}) => {
  const translation = useTranslation();

  const handleFilterClick = () => {
    setOpenFilters(!openFilters);
  };
  return (
    <div
      className={`table_filter ${openFilters ? "opened_table_filter" : ""}`}
      onClick={handleFilterClick}
    >
      <img src={filterIcon} alt="Filter" className="filter_icon" />
      <div className="filter_name">{translation.filter}</div>
      <img
        src={arrowGreen}
        alt="Arrow"
        className={`arrow_green ${openFilters ? "arrow_green_rotate" : ""}`}
      />
    </div>
  );
};
