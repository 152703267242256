import "assets/components.scss";
import { PagesRouter } from "./Router";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";

function App() {
  return (
    <div className="App">
      <PagesRouter />
    </div>
  );
}

export default App;
