import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "localization";
import { PROFILE_LIST } from "constants/profile";
import { useAppDispatch } from "reduxState/store";
import { setShowLogoutPopup } from "reduxState/features/main";

export const ProfileDropdown = () => {
  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(setShowLogoutPopup(true));
  };

  const handleRoute = (path: string) => {
    navigate(path);
  };

  return (
    <div className="profile_dropdown">
      <ul className="profile_list">
        {PROFILE_LIST.map((element, index) =>
          index + 1 === PROFILE_LIST.length ? (
            <Fragment key={element.id}>
              <li className="list_element_line"></li>
              <li className="list_element" onClick={handleLogout}>
                <img src={element.icon} alt="Profile" />
                <p className="element_name">{translation[element.name]}</p>
              </li>
            </Fragment>
          ) : (
            <li
              key={element.id}
              className="list_element"
              onClick={() => handleRoute(element.path)}
            >
              <img src={element.icon} alt="Profile" />
              <p className="element_name">{translation[element.name]}</p>
            </li>
          )
        )}
      </ul>
      <div></div>
    </div>
  );
};
