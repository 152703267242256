import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  IAuth,
  IAuthData,
  IDisableTwoFa,
  IError,
  IMerchantAuth,
  IUserAuth,
} from "types";
import { authApi } from "service/auth";
import { GLOBAL } from "constants/global";
import { setToastData } from "reduxState/features/toast";
import { TOAST_MESSAGES, TOAST_TYPES } from "constants/toast";
import { ERROR_MESSAGES } from "constants/forms";
import HttpStatusCode from "constants/httpStatusCodes";

export const getUser = createAsyncThunk(
  "getUser",
  async (
    { handleRefresh }: { handleRefresh?: () => void },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await authApi.getUser();
      return data?.data;
    } catch (error) {
      const typedError = error as IError;

      if (typedError.response.status === HttpStatusCode.UNAUTHORIZED) {
        if (handleRefresh) handleRefresh();
      }

      return rejectWithValue(error);
    }
  }
);

export const login = createAsyncThunk(
  "login",
  async ({ data, navigate }: IAuthData, { rejectWithValue, dispatch }) => {
    try {
      const res = await authApi.login(data);
      const token = res?.data?.data?.token;
      localStorage.setItem(GLOBAL.access_token, token);
      dispatch(getUser({}));
      if (navigate) navigate();
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message:
            typedError.response.data.message || ERROR_MESSAGES.email_exist,
          open: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const signUp = createAsyncThunk(
  "signUp",
  async ({ data, navigate }: IUserAuth, { rejectWithValue, dispatch }) => {
    try {
      await authApi.signUp(data);
      if (navigate) navigate();
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message:
            typedError.response.data.message || ERROR_MESSAGES.email_exist,
          open: true,
        })
      );

      return rejectWithValue(error);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "verifyEmail",
  async ({ data, params }: IAuthData, { rejectWithValue, dispatch }) => {
    try {
      const res = await authApi.verifyEmail(data.token, params as IAuth);
      return res.data;
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );

      return rejectWithValue(error);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async ({ data, navigate }: IAuthData, { rejectWithValue, dispatch }) => {
    try {
      const res = await authApi.forgotPassword(data);
      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );
      if (navigate) navigate();
      return res.data;
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (
    { data, token, navigate }: IAuthData,
    { rejectWithValue, dispatch }
  ) => {
    try {
      await authApi.resetPassword(data, token as string);
      if (navigate) navigate();
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );

      return rejectWithValue(error);
    }
  }
);

export const setPassword = createAsyncThunk(
  "setPassword",
  async ({ data, id, navigate }: IAuthData, { rejectWithValue, dispatch }) => {
    try {
      const res = await authApi.setPassword(data, id as string);

      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );

      if (navigate) navigate();
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );

      return rejectWithValue(error);
    }
  }
);

export const enableTwoFa = createAsyncThunk(
  "enableTwoFa",
  async ({ id }: IAuth, { rejectWithValue }) => {
    try {
      const res = await authApi.enableTwoFa(id as string);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyTwoFa = createAsyncThunk(
  "verifyTwoFa",
  async ({ data, id, navigate }: IAuthData, { rejectWithValue, dispatch }) => {
    try {
      const res = await authApi.verifyTwoFa(data, id as string);

      dispatch(getUser({}));

      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message || TOAST_MESSAGES.two_factor_authentication,
          open: true,
        })
      );

      if (navigate) navigate();
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );

      return rejectWithValue(error);
    }
  }
);

export const disableTwoFa = createAsyncThunk(
  "disableTwoFa",
  async (
    { id, navigate, data }: IDisableTwoFa,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await authApi.disableTwoFa(id as string, data);

      dispatch(getUser({}));

      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );

      if (navigate) navigate();
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );

      return rejectWithValue(error);
    }
  }
);

export const createRequest = createAsyncThunk(
  "createRequest",
  async (
    { data, handleSubmitNavigate }: IMerchantAuth,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await authApi.createRequest(data);

      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );
      handleSubmitNavigate();
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const requestForMerchant = createAsyncThunk(
  "requestForMerchant",
  async (
    { data, handleSubmitNavigate }: IMerchantAuth,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await authApi.requestForMerchant(data);

      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );
      handleSubmitNavigate();
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  "changePassword",
  async ({ data, navigate }: IAuthData, { rejectWithValue, dispatch }) => {
    try {
      const res = await authApi.changePassword(data);
      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );
      if (navigate) navigate();
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const markUserAsLoggedIn = createAsyncThunk(
  "markUserAsLoggedIn",
  async (_, { rejectWithValue }) => {
    try {
      await authApi.markUserAsLoggedIn();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
