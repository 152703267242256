import { Dispatch, FC, SetStateAction, useState } from "react";

import { useTranslation } from "localization";
import { FilterInput, FilterMultipleSelect } from "components";
import { ITableFilters } from "types";
import { useAppSelector } from "reduxState/store";

interface ITableFiltersTypes {
  setFilters: Dispatch<SetStateAction<ITableFilters>>;
  openFilters: boolean;
}

export const TableFilters: FC<ITableFiltersTypes> = ({
  setFilters,
  openFilters,
}) => {
  const [minAmount, setMinAmount] = useState<string>("");
  const [maxAmount, setMaxAmount] = useState<string>("");
  const [selectedBanksName, setSelectedBanksName] = useState<string[]>([]);
  const [selectedBanksId, setSelectedBanksId] = useState<number[]>([]);
  const [selectedICurrencyName, setSelectedICurrencyName] = useState<string[]>(
    []
  );
  const [selectedICurrencyId, setSelectedICurrencyId] = useState<number[]>([]);
  const [openCurrency, setOpenCurrency] = useState<boolean>(false);
  const [openBanks, setOpenBanks] = useState<boolean>(false);

  const banks = useAppSelector((state) => state.banksCurrencies.banks);
  const currencies = useAppSelector(
    (state) => state.banksCurrencies.currencies
  );

  const translation = useTranslation();

  return (
    <div
      className={`table_filters ${
        openFilters ? "filters_open home_filters_open" : "filters_close"
      }`}
    >
      <div className="currency_filter">
        <FilterMultipleSelect
          name="currencies"
          openSelect={openCurrency}
          setOpenSelect={setOpenCurrency}
          selectedNames={selectedICurrencyName}
          setSelectedNames={setSelectedICurrencyName}
          selectedIds={selectedICurrencyId}
          setSelectedIds={setSelectedICurrencyId}
          data={currencies}
          setFilters={setFilters}
        />
      </div>
      <div className="min_amount_filter">
        <FilterInput
          name="min_amount"
          value={minAmount}
          setValue={setMinAmount}
          placeholder={translation.min_amount}
          isNumber={true}
          setFilters={setFilters}
        />
      </div>
      <div className="max_amount_filter">
        <FilterInput
          name="max_amount"
          value={maxAmount}
          setValue={setMaxAmount}
          placeholder={translation.max_amount}
          isNumber={true}
          setFilters={setFilters}
        />
      </div>
      <div className="bank_filter">
        <FilterMultipleSelect
          name="banks"
          openSelect={openBanks}
          setOpenSelect={setOpenBanks}
          selectedNames={selectedBanksName}
          setSelectedNames={setSelectedBanksName}
          selectedIds={selectedBanksId}
          setSelectedIds={setSelectedBanksId}
          data={banks}
          setFilters={setFilters}
        />
      </div>
    </div>
  );
};
