import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import { useTranslation } from "localization";
import { ModalContainer } from "../ModalAuth";
import { MainButton } from "components/buttons";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { SelectBox, TextInput } from "components/inputs";
import { setOpenToast } from "reduxState/features/toast";

import closeIcon from "assets/images/close.svg";
import requestTradeIcon from "assets/images/requestTrade.svg";
import { requestTrade } from "reduxState";
import useOutsideClick from "hooks/useOutsideClick";
import { InfoSection } from "./InfoSection";
import { COIN_USD_NAMES, GLOBAL, MIN_AMOUNT } from "constants/global";
import { ITradeModalData } from "types";
import { socket } from "socket";
import { getNumericNumbers, isTradeResponse } from "helpers";

interface ITradesModal {
  tradeId: string;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  tradeData: ITradeModalData;
}

export const TradeRequestModal: FC<ITradesModal> = ({
  tradeId,
  setShowModal,
  tradeData,
}) => {
  const [priceRate, setPriceRate] = useState<string>("");

  const loading = useAppSelector((state) => state.trades.requestTradeLoading);
  const banks = useAppSelector((state) => state.banksCurrencies.banks);
  const currenciesRate = useAppSelector(
    (state) => state.banksCurrencies.currenciesRate
  );

  const existingBanks = banks.filter((elem) =>
    tradeData.banks?.includes(`${elem.id}`)
  );

  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const ref = useRef<HTMLDivElement>(null);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors, isSubmitted },
  } = useForm();

  const handleClose = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    setShowModal(false);
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    const newData = {
      ...data,
      banks: !!data.banks.length
        ? data.banks
        : existingBanks.map((elem) => elem.id),
    };

    if (data.amount === "0") {
      setError("amount", {
        type: GLOBAL.custom,
        message: translation.value_must_be_greater,
      });
      return;
    }

    if (data.amount < MIN_AMOUNT) {
      setError("amount", {
        type: GLOBAL.custom,
        message: translation.value_must_be_greater_than,
      });

      return;
    }

    dispatch(requestTrade({ data: newData, navigate: handleConfirm }))
      .finally(() => {
        setTimeout(() => {
          dispatch(setOpenToast(false));
        }, 3000);
      })
      .then((res: unknown) => {
        if (isTradeResponse(res)) {
          const tradeRequestId = res.payload.data.data.data.id;
          socket.emit("newTradeRequest", {
            tradeId,
            tradeRequestId,
          });
        }
      });
  };

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = getNumericNumbers(e.target.value);

    setPriceRate(value);
    if (value === "") {
      setValue(COIN_USD_NAMES.amount, "");
    } else {
      const currencyRate = currenciesRate[tradeData.currencyName]?.currentRate;
      const formatAmount = (Number(value) / currencyRate).toFixed(4);

      setValue(COIN_USD_NAMES.amount, `${formatAmount}`);
      clearErrors(COIN_USD_NAMES.amount);
    }
  };

  const handleAmountFieldChange = (value: string) => {
    if (value === "") {
      setPriceRate("");
    } else {
      const currencyRate = currenciesRate[tradeData.currencyName]?.currentRate;
      const formatPrice = (Number(value) * currencyRate).toFixed(2);

      setPriceRate(`${formatPrice}`);
    }
  };

  useOutsideClick({ ref, handler: handleClose });

  useEffect(() => {
    if (!!tradeId) setValue("trade_id", tradeId);
  }, [tradeId]);

  return (
    <ModalContainer>
      <div className="modal" ref={ref}>
        <div className="logout_header">
          <div className="close_modal">
            <img src={closeIcon} alt="Close" onClick={handleClose} />
          </div>
          <div className="logout_header_element">
            <img src={requestTradeIcon} alt="Logout" className="logout_image" />
            <p className="logout_title">{translation.request_trade}</p>
          </div>
        </div>
        <div className="logout_body">
          <p className="logout_description">{translation.request_trade_text}</p>
        </div>
        <form className="modal_form" onSubmit={handleSubmit(onSubmit)}>
          <div className="input_with_info">
            <div className="request_trade_field">
              <div className={`text_input_container`}>
                <label className="input_label">{translation.usd}</label>
                <div className="input_field">
                  <div className="input_filed_content">
                    <input
                      name={COIN_USD_NAMES.usd}
                      value={priceRate}
                      onChange={(e) => handlePriceChange(e)}
                      id={COIN_USD_NAMES.usd}
                      className="text_input disabled_text_input"
                      autoComplete="new-password"
                    />
                  </div>
                </div>
              </div>
              <span className="equal_field">=</span>
              <TextInput
                name="amount"
                label={translation.crypto_coin_mandatory}
                register={register}
                errors={errors}
                setValue={setValue}
                isNumber
                required={true}
                setFieldValue={handleAmountFieldChange}
              />
            </div>
            {!errors.amount && <InfoSection priceRate={tradeData.priceRate} />}
          </div>
          <SelectBox
            name="banks"
            label={translation.banks_mandatory}
            register={register}
            errors={errors}
            setValue={setValue}
            isMultiple={true}
            getValues={getValues}
            clearErrors={clearErrors}
            setError={setError}
            isSubmitted={isSubmitted}
            existingBanks={existingBanks}
          />
          <div className="logout_actions">
            <MainButton
              name={translation.request_trade}
              customClass="custom_button width_100"
              type="submit"
              loading={loading}
              disabled={loading}
            />
          </div>
        </form>
      </div>
    </ModalContainer>
  );
};
