import { FC } from "react";

import { IChildrenType } from "types";
import { ProfileMenu } from "components";

export const ProfilePage: FC<IChildrenType> = ({ children }) => {
  return (
    <div className="profile_page">
      <ProfileMenu />
      {children}
    </div>
  );
};
