import { FC } from "react";

import currencyIcon from "assets/images/Cryptocurrency.svg";
import amdCurrency from "assets/images/armenianCurrency.svg";
import { BanksMobileSection } from "./BanksMobileSection";

interface IInfoElement {
  title: string;
  name: string;
  isBanks?: boolean;
  banks?: string[];
  icon?: string;
  fullName?: string;
  backIcon?: string;
}

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

export const InfoElement: FC<IInfoElement> = ({
  title,
  name,
  isBanks,
  banks,
  icon,
  fullName,
  backIcon,
}) => {
  return (
    <div className="element_title_section">
      <div className="element_title">{title}</div>
      <div className="element_name_section">
        {!!icon && (
          <>
            <img
              src={`${imageUrl}${icon}`}
              alt="Currency"
              className="currency_icon"
              crossOrigin="anonymous"
            />
            <div className="currency_field">
              <span className="element_name">{name}</span>
              <span className="currency_line"></span>
              <span className="element_name">{fullName}</span>
            </div>
          </>
        )}
        {!icon &&
          (!isBanks ? (
            <div className="element_name_field">
              <span className="element_name">{name}</span>
              {backIcon && <img src={backIcon} alt="Valuta" />}
            </div>
          ) : banks?.length ? (
            <BanksMobileSection banks={banks} />
          ) : (
            <></>
          ))}
      </div>
    </div>
  );
};
