import { useTranslation } from "localization";

export const Copyright = () => {
  const translation = useTranslation();

  const date = new Date();
  const year = date.getFullYear();

  return (
    <div className="copyright_container">
      <div className="copyright_line"></div>
      <p className="copyright_text">{`${translation.copyright} ${year} ${translation.copyright_crypto}`}</p>
    </div>
  );
};
