import { useTranslation } from "localization";
import { TOAST_ICONS } from "constants/toast";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { setOpenToast } from "reduxState/features/toast";

import closeIcon from "assets/images/close.svg";

export const ActionMessages = () => {
  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const type = useAppSelector((state) => state.toast.type);
  const message = useAppSelector((state) => state.toast.message);

  const handleClose = () => {
    dispatch(setOpenToast(false));
  };

  return (
    <div className="messages_container">
      <div className={`toast_container ${type}`}>
        <div>
          <img src={TOAST_ICONS[type]} alt="Toast" />
        </div>
        <div className="toast_info">
          <p className="toast_type">{translation[type]}</p>
          <p className="toast_message">{message}</p>
        </div>
        <div className="close_toast">
          <img
            src={closeIcon}
            alt="Close"
            className="close_icon"
            onClick={handleClose}
          />
        </div>
      </div>
    </div>
  );
};
