import { useRef } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "localization";
import { MainButton, TextInput } from "components";
import { FormContainer } from "../FormContainer";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { setOpenToast } from "reduxState/features/toast";
import { EMAIL_REGEX } from "constants/global";
import { forgotPassword } from "reduxState";

export const ForgotForm = () => {
  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loading = useAppSelector((state) => state.auth.forgotPasswordLoading);

  const timeoutRef = useRef<NodeJS.Timeout>();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const handleConfirm = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      dispatch(setOpenToast(false));
    }, 3000);
    navigate("/");
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    dispatch(forgotPassword({ data, navigate: handleConfirm })).finally(() => {
      setTimeout(() => {
        dispatch(setOpenToast(false));
      }, 3000);
    });
  };

  return (
    <div className="sign_up_form">
      <FormContainer>
        <div className="sign_up_content">
          <h1 className="form_title">{translation.forgot_password_title}</h1>
          <form
            className="form_content"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <TextInput
              name="email"
              label={translation.email}
              type="email"
              register={register}
              setValue={setValue}
              required={true}
              pattern={EMAIL_REGEX}
              patternError={translation.email_pattern_error}
              errors={errors}
            />
            <div className="sign_up_button">
              <MainButton
                name={translation.send_instruction}
                customClass="custom_button width_100"
                type="submit"
                disabled={loading}
                loading={loading}
              />
            </div>
          </form>
        </div>
      </FormContainer>
    </div>
  );
};
