import { createSlice } from "@reduxjs/toolkit";

import { IBankCurrencySlice } from "types";
import { getBanks, getCurrencies } from "reduxState";

const initialState: IBankCurrencySlice = {
  banks: [],
  banksLoading: false,
  currencies: [],
  currenciesLoading: false,
  currenciesRate: {
    BTC: {
      currentRate: 0,
      differencePercent: 0,
      direction: 0,
    },
    ETH: {
      currentRate: 0,
      differencePercent: 0,
      direction: 0,
    },
  },
};

export const banksCurrenciesSlice = createSlice({
  name: "Auth",
  initialState: initialState,
  reducers: {
    setCurrenciesRate: (state, { payload }) => {
      state.currenciesRate = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getBanks.pending, (state) => {
        state.banksLoading = true;
      })
      .addCase(getBanks.fulfilled, (state, { payload }) => {
        state.banksLoading = false;
        state.banks = payload?.data;
      })
      .addCase(getBanks.rejected, (state) => {
        state.banksLoading = false;
      });
    builder
      .addCase(getCurrencies.pending, (state) => {
        state.currenciesLoading = true;
      })
      .addCase(getCurrencies.fulfilled, (state, { payload }) => {
        state.currenciesLoading = false;
        state.currencies = payload?.data;
      })
      .addCase(getCurrencies.rejected, (state) => {
        state.currenciesLoading = false;
      });
  },
});

export const { setCurrenciesRate } = banksCurrenciesSlice.actions;

export default banksCurrenciesSlice.reducer;
