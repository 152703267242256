import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { MOBILE_PROFILE_LIST } from "constants/header";
import { useTranslation } from "localization";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { setShowLogoutPopup } from "reduxState/features/main";

import logoutIcon from "assets/images/profile/logout.svg";
import avatar from "assets/images/avatar.svg";

const username = "Mark Spencer";

interface IUserActions {
  handleClose: () => void;
}

export const UserSection: FC<IUserActions> = ({ handleClose }) => {
  const user = useAppSelector((state) => state.auth.user);

  const translation = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleRoute = (path: string) => {
    navigate(path);
    handleClose();
  };

  const handleLogout = () => {
    dispatch(setShowLogoutPopup(true));
  };

  return (
    <div className="menu_user_section">
      <div className="user_data">
        <img src={avatar} alt="Avatar" className="avatar_icon" />
        <p className="user_name">{`${user?.firstName} ${user?.lastName}`}</p>
      </div>
      <div className="profile_menu">
        <ul className="profile_actions_list">
          {MOBILE_PROFILE_LIST.map((element) => (
            <li
              key={element.id}
              className="profile_list_element"
              onClick={() => handleRoute(element.path)}
            >
              <img src={element.icon} alt="Profile" />
              <p className="profile_element_name">
                {translation[element.name]}
              </p>
            </li>
          ))}
          <li className="profile_list_element" onClick={handleLogout}>
            <img src={logoutIcon} alt="Profile" />
            <p className="profile_element_name">{translation.logout}</p>
          </li>
        </ul>
      </div>
    </div>
  );
};
