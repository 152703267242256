import { createAsyncThunk } from "@reduxjs/toolkit";

import { contentApi } from "service";
import { setToastData } from "reduxState/features/toast";
import { TOAST_TYPES } from "constants/toast";
import { IParams } from "types/content";
import { IAuthData, IError } from "types";

export const getForMerchants = createAsyncThunk(
  "getForMerchants",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await contentApi.getForMerchants();
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getHomeContent = createAsyncThunk(
  "getHomeContent",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await contentApi.getHomeContent();
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAboutUs = createAsyncThunk(
  "getAboutUs",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await contentApi.getAboutUs();
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTermsOfUse = createAsyncThunk(
  "getTermsOfUse",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await contentApi.getTermsOfUse();
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPrivacyPolicy = createAsyncThunk(
  "getPrivacyPolicy",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await contentApi.getPrivacyPolicy();
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFaqData = createAsyncThunk(
  "getFaqData",
  async ({ params }: IParams, { rejectWithValue }) => {
    try {
      const { data } = await contentApi.getFaqData(params);
      return { data: data?.data, page: params.page };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getContactUs = createAsyncThunk(
  "getContactUs",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await contentApi.getContactUs();
      return data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSocialLinks = createAsyncThunk(
  "getSocialLinks",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await contentApi.getSocialLinks();
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendContactInfo = createAsyncThunk(
  "sendContactInfo",
  async ({ data }: IAuthData, { rejectWithValue, dispatch }) => {
    try {
      const res = await contentApi.sendContactInfo(data);

      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );

      return rejectWithValue(error);
    }
  }
);
