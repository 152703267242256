import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import { useTranslation } from "localization";
import {
  AgreeWithPolicy,
  MainButton,
  PassportImageInput,
  PassportModal,
  TextInput,
  ThankYouModal,
} from "components";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { SIGN_UP_FORMS } from "constants/forms";
import { signUp } from "reduxState";
import { setOpenToast } from "reduxState/features/toast";
import { PATHNAMES } from "constants/global";

import { InfoSection } from "./InfoSection";
import { FormContainer } from "../FormContainer";

import congratsIcon from "assets/images/congrats.svg";

export const SignUpForm = () => {
  const [showThankYou, setShowThankYou] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [showExample, setShowExample] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const loading = useAppSelector((state) => state.auth.signUpLoading);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setValue,
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const translation = useTranslation();

  const handleSubmitNavigate = () => {
    setShowThankYou(true);
  };

  const handleOpenCloseModal = () => {
    setShowExample(!showExample);
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    const formData = new FormData();

    const input = document.getElementsByTagName("input");

    for (let i = 0; i < input.length; i++) {
      input[i].blur();
    }

    for (let key in data) {
      formData.append(key, data[key].trim());
    }

    if (uploadedFiles?.length) {
      uploadedFiles.forEach((elem, index) => {
        formData.append(`passportImage[${index}]`, elem);
      });
    }

    const requestData = Object.fromEntries(formData);

    dispatch(
      signUp({ data: requestData, navigate: handleSubmitNavigate })
    ).then(() => {
      setTimeout(() => {
        dispatch(setOpenToast(false));
      }, 3000);
    });
  };

  const handleRouteHome = () => {
    setShowThankYou(false);
    navigate(PATHNAMES.home);
  };

  useEffect(() => {
    if (!showExample) {
      document.body.style.overflow = "unset";
    }
  }, [showExample]);

  return (
    <div className="sign_up_form">
      {showExample && <PassportModal onClose={handleOpenCloseModal} />}
      <FormContainer>
        <div className="sign_up_content">
          <h1 className="form_title">{translation.sign_up}</h1>
          <form
            className="form_content"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            {SIGN_UP_FORMS.map((field) => (
              <Fragment key={field.id}>
                {field.name === "email" ? (
                  <TextInput
                    name={field.name}
                    label={translation[field.label]}
                    type={field.type || "text"}
                    register={register}
                    errors={errors}
                    required={field.isRequired}
                    setValue={setValue}
                    pattern={field.regex}
                    patternError={translation.email_pattern_error}
                  />
                ) : field.name === "passportImage" ? (
                  <div className="image_input">
                    <PassportImageInput
                      name={field.name}
                      label={translation[field.label]}
                      type={field.type || "text"}
                      setShowExample={setShowExample}
                      setUploadedFiles={setUploadedFiles}
                      uploadedFiles={uploadedFiles}
                      passportErrors={false}
                    />
                    <InfoSection />
                  </div>
                ) : (
                  <TextInput
                    name={field.name}
                    label={translation[field.label]}
                    type={field.type || "text"}
                    register={register}
                    errors={errors}
                    required={field.isRequired}
                    setValue={setValue}
                  />
                )}
              </Fragment>
            ))}
            <AgreeWithPolicy checked={checked} setChecked={setChecked} />
            <div className="sign_up_button">
              <MainButton
                name={translation.sign_up}
                customClass="custom_button width_100"
                type="submit"
                disabled={!checked || loading}
                loading={loading}
              />
            </div>
          </form>
          <div className="sign_in_section">
            <p className="have_account_text">
              {translation.already_have_account}
            </p>
            <Link to="/sign-in" className="sign_in_link">
              {translation.login}
            </Link>
          </div>
        </div>
      </FormContainer>
      {showThankYou && (
        <ThankYouModal
          icon={congratsIcon}
          title={translation.thank_you_for_register}
          congratsMessage={translation.congrats_for_register}
          infoMessage={translation.email_check_message}
          onClose={handleRouteHome}
          onClick={handleRouteHome}
        />
      )}
    </div>
  );
};
