import { Dispatch, FC, MouseEvent, SetStateAction, useState } from "react";

import { Checkbox } from "../checkbox";
import { IBank } from "types";

interface IElement {
  id: number;
  currency: string;
}

interface ISelect {
  element: IBank;
  isChecked: boolean;
  handleSelect: (
    e: MouseEvent<HTMLElement>,
    id: number,
    value: string,
    setChecked: Dispatch<SetStateAction<boolean>>
  ) => void;
}

export const MultipleElement: FC<ISelect> = ({
  handleSelect,
  element,
  isChecked,
}) => {
  const [checked, setChecked] = useState<boolean>(isChecked);
  return (
    <div
      className="select_element"
      onClick={(e) => handleSelect(e, element.id, element.title, setChecked)}
    >
      <div className="select_left_section">
        <p className="element_name">{element.title}</p>
      </div>
      <div className="select_right_section">
        <Checkbox checked={checked} setChecked={setChecked} />
      </div>
    </div>
  );
};
