import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import { useTranslation } from "localization";
import {
  AgreeWithPolicy,
  MainButton,
  PassportImageInput,
  PassportModal,
  TextInput,
  ThankYouModal,
} from "components";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { BECOME_MERCHANT_FORM } from "constants/forms";
import { setOpenToast } from "reduxState/features/toast";

import { FormContainer } from "../FormContainer";
import { InfoSection } from "./InfoSection";

import congratsIcon from "assets/images/congrats.svg";
import { GLOBAL, PATHNAMES } from "constants/global";
import { requestForMerchant } from "reduxState";

export const ForMerchantForm = () => {
  const [showThankYou, setShowThankYou] = useState<boolean>(false);
  const [showExample, setShowExample] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [passportErrors, setPassportErrors] = useState<boolean>(false);

  const loading = useAppSelector((state) => state.auth.becomeMerchantLoading);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setValue,
  } = useForm();

  const query = new URLSearchParams(useLocation().search);
  const emailQuery = query.get(GLOBAL.email);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const translation = useTranslation();

  const handleOpenCloseModal = () => {
    setShowExample(!showExample);
  };

  const handleSubmitNavigate = () => {
    setShowThankYou(true);
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    const formData = new FormData();

    if (!uploadedFiles.length) {
      setPassportErrors(true);
      return;
    }

    for (let key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach((elem: string | Blob, index: any) => {
          formData.append(`${key}[${index}]`, elem);
        });
      } else {
        formData.append(key, data[key]);
      }
    }

    uploadedFiles.forEach((elem, index) => {
      formData.append(`passportImage[${index}]`, elem);
    });

    const requestData = Object.fromEntries(formData);

    dispatch(
      requestForMerchant({ data: requestData, handleSubmitNavigate })
    ).then(() => {
      setTimeout(() => {
        dispatch(setOpenToast(false));
      }, 3000);
    });
  };

  const handleRouteHome = () => {
    setShowThankYou(false);
    navigate(PATHNAMES.home);
  };

  useEffect(() => {
    if (emailQuery) {
      const email = atob(emailQuery);
      setValue(GLOBAL.email, email);
    }
  }, [emailQuery]);

  useEffect(() => {
    if (!showExample) {
      document.body.style.overflow = "unset";
    }
  }, [showExample]);

  useEffect(() => {
    if (isSubmitted && !uploadedFiles.length) {
      setPassportErrors(true);
    }
  }, [isSubmitted]);

  return (
    <div className="sign_up_form">
      {showExample && <PassportModal onClose={handleOpenCloseModal} />}
      <FormContainer customClass="merchant_forms">
        <div className="sign_up_content">
          <h1 className="form_title">{translation.become_merchant}</h1>
          <form
            className="form_content"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            {BECOME_MERCHANT_FORM.map((field) => (
              <Fragment key={field.id}>
                {field.label === "passport_image" ? (
                  <div className="image_input">
                    <PassportImageInput
                      name={field.name}
                      label={translation[field.label]}
                      type={field.type || "text"}
                      setShowExample={setShowExample}
                      setUploadedFiles={setUploadedFiles}
                      uploadedFiles={uploadedFiles}
                      passportErrors={passportErrors}
                    />
                    <InfoSection />
                  </div>
                ) : field.label === "email" ? (
                  <TextInput
                    name={field.name}
                    label={translation[field.label]}
                    type={field.type || "text"}
                    register={register}
                    errors={errors}
                    required={field.isRequired}
                    setValue={setValue}
                    pattern={field.regex}
                    patternError={translation.email_pattern_error}
                  />
                ) : (
                  <TextInput
                    name={field.name}
                    label={translation[field.label]}
                    type={field.type || "text"}
                    register={register}
                    errors={errors}
                    required={field.isRequired}
                    setValue={setValue}
                    isNumber={field.isNumber}
                  />
                )}
              </Fragment>
            ))}
            <AgreeWithPolicy checked={checked} setChecked={setChecked} />
            <div className="sign_up_button">
              <MainButton
                name={translation.submit}
                customClass="custom_button width_100"
                type="submit"
                disabled={!checked || loading}
                loading={loading}
              />
            </div>
          </form>
        </div>
      </FormContainer>
      {showThankYou && (
        <ThankYouModal
          icon={congratsIcon}
          title={translation.thank_you_for_register}
          congratsMessage={translation.congrats_for_register}
          infoMessage={translation.email_check_message}
          onClose={handleRouteHome}
          onClick={handleRouteHome}
        />
      )}
    </div>
  );
};
