import { Dispatch, FC, SetStateAction } from "react";

interface ICheckbox {
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
}

export const Checkbox: FC<ICheckbox> = ({ checked, setChecked }) => {
  return (
    <div className="checkbox_container">
      <label
        htmlFor="checkbox"
        className={`checkbox_label ${checked ? "checked_checkbox_label" : ""}`}
      ></label>
      <input
        type="checkbox"
        id="checkbox"
        name="checkbox"
        className="checkbox_input"
        onChange={(e) => setChecked(e.target.checked)}
        checked={checked}
      />
    </div>
  );
};
