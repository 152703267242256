import { useEffect, useState } from "react";

import { useAppSelector } from "reduxState/store";
import { ChatMessages } from "./ChatMessages";
import { IChatMessages } from "types";

export const ChatSection = () => {
  const messagesList = useAppSelector((state) => state.trades.messages);

  const [messages, setMessages] = useState<IChatMessages[]>(messagesList);

  useEffect(() => {
    setMessages(messagesList);
  }, [messagesList]);

  return (
    <div className="chat_section">
      <ChatMessages messages={messages} />
    </div>
  );
};
