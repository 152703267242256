import { useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "localization";
import { CurrenciesField, CurrencyInfo } from "./components";
import { GLOBAL } from "constants/global";

import arrowIcon from "assets/images/arrow-down.svg";

export const UserWallet = () => {
  const translation = useTranslation();
  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);
  const selectedWalletId = query.get(GLOBAL.wallet_id);

  const handleUnselect = () => {
    query.delete(GLOBAL.wallet_id);
    navigate(`?${query.toString()}`);
  };

  return (
    <div className="profile_right_side">
      <div className="profile_title_field">
        <h2
          className={`profile_element_title ${
            selectedWalletId ? "hide_profile_title" : ""
          }`}
        >
          {translation.wallet}
        </h2>
        <img
          src={arrowIcon}
          alt="Back"
          className={`back_icon ${selectedWalletId ? "show_back_icon" : ""}`}
          onClick={handleUnselect}
        />
      </div>
      <div className="wallet_container">
        <CurrenciesField />
        <CurrencyInfo />
      </div>
    </div>
  );
};
