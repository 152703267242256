import { FC } from "react";

interface IInfoElement {
  title: string;
  name: string;
  icon?: string;
}

export const InfoElement: FC<IInfoElement> = ({ title, name, icon }) => {
  return (
    <div className="element_title_section">
      <div className="element_title">{title}</div>
      <div className="element_name_section">
        {!!icon && (
          <img src={icon} alt="Currency" className="amd_currency_icon" />
        )}
        <span className="element_name">{name}</span>
      </div>
    </div>
  );
};
