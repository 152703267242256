import { Link } from "react-router-dom";

import { HeaderMenu } from "./headerMenu";

import logo from "assets/images/logo.svg";

export const LeftSide = () => {
  return (
    <div className="header_left_side">
      <div className="logo_container">
        <Link to="/">
          <img src={logo} alt="Logo" className="desktop_logo" />
        </Link>
      </div>
      <div className="menu_container">
        <HeaderMenu />
      </div>
    </div>
  );
};
