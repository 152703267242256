import { Dispatch, FC, SetStateAction } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Label, MainButton } from "components";
import { useTranslation } from "localization";
import { RequestTimer } from "./RequestTimer";
import { ITradeRequest } from "types";
import { useAppDispatch } from "reduxState/store";
import { setShowRequestPopup } from "reduxState/features/main";
import { setChatMessages } from "reduxState/features/trades";
import {
  DEFAULT_TIMER_MINUTES,
  GO_TO_CHAT_BUTTON_STATUSES,
  TRADE_REQUEST_STATUSES,
} from "constants/trade";
import { GLOBAL } from "constants/global";

import starIcon from "assets/images/starIcon.svg";
import verifyIcon from "assets/images/profile/verified.svg";

interface IRequestElement {
  element: ITradeRequest;
  activeTab: string;
  setSocketTradeRequests: Dispatch<SetStateAction<ITradeRequest[]>>;
}

export const RequestElement: FC<IRequestElement> = ({
  element,
  activeTab,
  setSocketTradeRequests,
}) => {
  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);

  const dateNow = new Date(element.timer_updated_at);

  dateNow.setMinutes(dateNow.getMinutes() + DEFAULT_TIMER_MINUTES);

  const handleRequestAction = (isActivate: boolean) => {
    setSocketTradeRequests([]);

    if (isActivate) dispatch(setChatMessages([]));

    dispatch(
      setShowRequestPopup({
        isOpen: true,
        selectedRequestData: {
          isActivate,
          id: element.id,
          requester_id: element.requester_id,
          activeTab,
        },
      })
    );
  };

  const handleGoToChat = () => {
    dispatch(setChatMessages([]));
    query.set(GLOBAL.request_id, `${element.id}`);
    navigate(`?${query.toString()}`);
  };

  return (
    <div className="request_trade_element">
      <div className="request_section">
        <div className="request_info_section">
          <div className="request_info">
            {!!element.requester_rating && (
              <div className="star_section">
                <img src={starIcon} alt="Star" className="star_icon" />
                <p className="star_text">{`${element.requester_rating}/5`}</p>
              </div>
            )}
            <p className="user_nickname">{element.requester}</p>
            {element?.requesterIsVerifiedByAdmin && (
              <img src={verifyIcon} alt="Verify" className="verify_icon" />
            )}
            <Label
              text={translation[element.merchant_status]}
              type={element.merchant_status}
            />
          </div>
          <div className="amount_section">
            <p className="amount_field">
              {translation.requested_amount}{" "}
              <span className="request_amount">{element.amount}</span>
            </p>
          </div>
          {element.merchant_status === TRADE_REQUEST_STATUSES.accepted ? (
            element.is_arbitraged ? (
              <></>
            ) : (
              <div className="request_time">
                <p className="request_time_text">{translation.trade_time}</p>
                <RequestTimer
                  date={dateNow}
                  customClass="chat_timer"
                  isArbitraged={element?.is_arbitraged}
                />
              </div>
            )
          ) : (
            <div className="request_time">
              <p className="request_time_text">
                {translation.user_equest_time}
              </p>
              <RequestTimer
                date={dateNow}
                isArbitraged={element?.is_arbitraged}
                elementId={element?.id}
              />
            </div>
          )}
        </div>
        <div className="request_actions">
          {element.merchant_status === TRADE_REQUEST_STATUSES.pending && (
            <>
              <MainButton
                customClass="log_in_button"
                name={translation.cancel}
                onClick={() => handleRequestAction(false)}
              />
              <MainButton
                customClass="custom_button"
                name={translation.accept}
                onClick={() => handleRequestAction(true)}
              />
            </>
          )}
          {GO_TO_CHAT_BUTTON_STATUSES.includes(element.merchant_status) && (
            <MainButton
              customClass="custom_button go_to_chat_button"
              name={translation.go_to_chat}
              onClick={handleGoToChat}
            />
          )}
        </div>
      </div>
    </div>
  );
};
