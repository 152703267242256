import { FC, useRef } from "react";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";

import { ModalContainer } from "../ModalAuth";

import { PASSPORT_IMAGES } from "constants/forms";

import closeIcon from "assets/images/close.svg";
import rightIcon from "assets/images/inputs/chevron-right.svg";
import leftIcon from "assets/images/inputs/chevron-left.svg";
import useOutsideClick from "hooks/useOutsideClick";

interface IPassport {
  onClose: () => void;
}

export const PassportModal: FC<IPassport> = ({ onClose }) => {
  const swiperRef = useRef<SwiperRef | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const swiperMainOptions = {
    slidesPerView: 1,
    loop: true,
  };

  const goNext = () => {
    if (swiperRef.current && swiperRef.current) {
      swiperRef.current.swiper.slideNext?.();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current) {
      swiperRef.current.swiper.slidePrev?.();
    }
  };

  useOutsideClick({ ref, handler: onClose });

  return (
    <ModalContainer>
      <div className="modal passport_modal" ref={ref}>
        <div className="logout_header">
          <div className="close_modal">
            <img src={closeIcon} alt="Close" onClick={onClose} />
          </div>
        </div>
        <div className="images_field">
          <Swiper {...swiperMainOptions} ref={swiperRef}>
            {PASSPORT_IMAGES.map((element) => (
              <SwiperSlide key={element.id}>
                <img
                  src={element.image}
                  alt="Passport"
                  className="swiper_image_element"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="arrow_container">
          <img
            src={leftIcon}
            alt="Left"
            className="chevron_icon"
            onClick={goPrev}
          />
          <img
            src={rightIcon}
            alt="Right"
            className="chevron_icon"
            onClick={goNext}
          />
        </div>
      </div>
    </ModalContainer>
  );
};
