import { createSlice } from "@reduxjs/toolkit";

import { IMessages } from "types";

const initialState: IMessages = {
  type: "warning",
  message: "This is a message for success.",
  openToast: false,
};

export const toastSlice = createSlice({
  name: "Toast",
  initialState: initialState,
  reducers: {
    setToastType: (state, { payload }) => {
      state.type = payload;
    },
    setToastMessage: (state, { payload }) => {
      state.message = payload;
    },
    setOpenToast: (state, { payload }) => {
      state.openToast = payload;
    },
    setToastData: (state, { payload }) => {
      state.type = payload.type;
      state.message = payload.message;
      state.openToast = payload.open;
    },
  },
});

export const { setToastType, setToastMessage, setOpenToast, setToastData } =
  toastSlice.actions;

export default toastSlice.reducer;
