import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "localization";
import { ModalContainer } from "../ModalAuth";
import { MainButton } from "components/buttons";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { setTradeModalData } from "reduxState/features/main";
import useOutsideClick from "hooks/useOutsideClick";
import { ARBITRAGE, TRADE_REQUEST_STATUSES } from "constants/trade";
import {
  acceptCancelRequest,
  acceptCancelRequestUser,
  chatArbitrageRequest,
} from "reduxState";
import { setOpenToast } from "reduxState/features/toast";
import { GLOBAL, USER_TYPES } from "constants/global";
import { setShowUserRateModal } from "reduxState/features/trades";

import closeIcon from "assets/images/close.svg";

export const CloseChatModal = () => {
  const tradeData = useAppSelector((state) => state.main.tradeModalData);
  const loading = useAppSelector((state) => state.trades.changeStatusLoading);
  const user = useAppSelector((state) => state.auth.user);

  const isMerchant = user?.role === USER_TYPES.merchant;

  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);
  const tradeId = query.get(GLOBAL.id);

  const ref = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    dispatch(
      setTradeModalData({
        type: "",
        title: "",
        description: "",
        showTradeModal: false,
        icon: "",
      })
    );
  };

  const handleConfirm = () => {
    if (tradeData.type === ARBITRAGE) {
      dispatch(chatArbitrageRequest({ id: `${tradeData?.id}` })).then(() => {
        handleClose();
        if (tradeData.type === TRADE_REQUEST_STATUSES.rejected) {
          query.delete(GLOBAL.request_id);
          navigate(`?${query.toString()}`);
        }
        setTimeout(() => {
          dispatch(setOpenToast(false));
        }, 3000);
      });
    } else {
      if (isMerchant) {
        dispatch(
          acceptCancelRequest({
            id: `${tradeData?.id}`,
            data: { status: tradeData.type },
            navigate: handleClose,
            tradeId: Number(tradeId),
          })
        ).then(() => {
          setTimeout(() => {
            dispatch(setOpenToast(false));
          }, 3000);
          dispatch(setShowUserRateModal(true));
        });
      } else {
        dispatch(
          acceptCancelRequestUser({
            id: `${tradeData?.id}`,
            data: { status: tradeData.type },
            navigate: handleClose,
          })
        ).then(() => {
          setTimeout(() => {
            dispatch(setOpenToast(false));
          }, 3000);
          dispatch(setShowUserRateModal(true));
        });
      }
    }
  };

  useOutsideClick({ ref, handler: handleClose });

  return (
    <ModalContainer>
      <div className="modal" ref={ref}>
        <div className="logout_header">
          <div className="close_modal">
            <img src={closeIcon} alt="Close" onClick={handleClose} />
          </div>
          <div className="logout_header_element">
            <img
              src={tradeData.icon}
              alt="Inactivate"
              className="logout_image"
            />
            <p className="logout_title">{tradeData.title}</p>
          </div>
        </div>
        <div className="logout_body">
          <p className="logout_description">{tradeData.description}</p>
        </div>
        <div className="logout_actions">
          <MainButton
            name={translation.yes}
            customClass="custom_button width_100"
            onClick={handleConfirm}
            loading={loading}
            disabled={loading}
          />
          <MainButton
            name={translation.cancel}
            customClass="log_in_button width_100"
            onClick={handleClose}
            disabled={loading}
          />
        </div>
      </div>
    </ModalContainer>
  );
};
