import { useState } from "react";

import { UserTradeRequest, TradesList } from "../components";
import { ONGOING_TRADES_USER_TABS } from "constants/trade";

export const UserTrades = () => {
  const [activeTab, setActiveTab] = useState<string>(
    ONGOING_TRADES_USER_TABS[0].value
  );

  return (
    <div className="trades_container">
      <TradesList
        isMerchant={false}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <UserTradeRequest />
    </div>
  );
};
