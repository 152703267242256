import { UserTransactions } from "components";

import { ProfilePage } from "./Profile";

export const Transactions = () => {
  return (
    <ProfilePage>
      <UserTransactions />
    </ProfilePage>
  );
};
