import { FC } from "react";

interface IEmptyPage {
  message: string;
  title: string;
}

export const EmptyPageMessage: FC<IEmptyPage> = ({ message, title }) => {
  return (
    <div className="empty_page_message">
      <p className="title">{title}</p>
      <p className="description">{message}</p>
    </div>
  );
};
