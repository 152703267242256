import { useEffect } from "react";

import { useTranslation } from "localization";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { getContactUs } from "reduxState";

export const FooterContactUs = () => {
  const contactUsData = useAppSelector((state) => state.content.contactUs);

  const translation = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getContactUs());
  }, []);

  return (
    <div className="footer_component_element">
      <h4 className="element_title">{translation.contact_us}</h4>
      <ul className="footer_list">
        <li className="item">
          <a href={`mailto::${contactUsData?.email}`} className="item_with_tag">
            {contactUsData?.email}
          </a>
        </li>
        <li className="item">
          <a href={`tel::${contactUsData?.phone}`} className="item_with_tag">
            {contactUsData?.phone}
          </a>
        </li>
        <li className="item">
          <a
            href="https://maps.app.goo.gl/cS1cxqsi6qdYwTp77"
            target="_blank"
            className="item_with_tag"
          >
            {contactUsData?.address}
          </a>
        </li>
      </ul>
    </div>
  );
};
