import { LeftSide, RightSide } from "./components";

export const Header = () => {
  return (
    <div className="header_container">
      <LeftSide />
      <RightSide />
    </div>
  );
};
