import { UserBalanceHistory } from "components";

import { ProfilePage } from "./Profile";

export const BalanceHistory = () => {
  return (
    <ProfilePage>
      <UserBalanceHistory />
    </ProfilePage>
  );
};
