import { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { IChildrenType } from "types";
import {
  ActionMessages,
  ActivateInActivateTrade,
  ChangePasswordModal,
  CloseChatModal,
  CloseTradeModal,
  CreateTradeModal,
  Footer,
  Header,
  LogoutModal,
  PageLoading,
  TradeRequestAccept,
  TwoFactorModal,
  VerifyInformModal,
} from "components";
import { PATHNAMES, WITHOUT_FOOTER_PATHNAMES } from "constants/global";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { MobileMenu } from "components/header/components/mobileMenu";
import { DEFAULT_LANGUAGE, LANGUAGE } from "constants/header";
import { getBanks, getCurrencies, getUser } from "reduxState";
import { GLOBAL } from "constants/global";
import { setShowTwoFactor } from "reduxState/features/auth";
import { markUserAsLoggedIn } from "reduxState/actions/auth";

export const Layout: FC<IChildrenType> = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const showLogoutPopup = useAppSelector((state) => state.main.showLogoutPopup);
  const showTwoFactor = useAppSelector((state) => state.auth.showTwoFactor);
  const showMobileMenu = useAppSelector((state) => state.main.showMobileMenu);
  const showCreateTrade = useAppSelector((state) => state.main.showCreateTrade);
  const showVerifyModal = useAppSelector((state) => state.main.showVerifyModal);
  const showToast = useAppSelector((state) => state.toast.openToast);
  const showChangePassword = useAppSelector(
    (state) => state.main.showChangePassword
  );
  const showActivate = useAppSelector((state) => state.main.showActivate);
  const showCloseTrade = useAppSelector((state) => state.main.showCloseTrade);
  const showTradeModal = useAppSelector(
    (state) => state.main.tradeModalData.showTradeModal
  );
  const showRequestPopup = useAppSelector(
    (state) => state.main.showRequestPopup
  );
  const userLoading = useAppSelector((state) => state.auth.userLoading);
  const user = useAppSelector((state) => state.auth.user);

  const hideFooter = WITHOUT_FOOTER_PATHNAMES.includes(pathname);

  useEffect(() => {
    if (!localStorage.getItem(LANGUAGE)) {
      localStorage.setItem(LANGUAGE, DEFAULT_LANGUAGE);
    }
  }, []);

  const handleRefresh = () => {
    navigate(PATHNAMES.home);
    navigate(0);
  };

  useEffect(() => {
    if (localStorage.getItem(GLOBAL.access_token)) {
      dispatch(getUser({ handleRefresh }));
    }
  }, [pathname]);

  useEffect(() => {
    if (
      !showLogoutPopup &&
      !showTwoFactor &&
      !showTwoFactor &&
      !showChangePassword &&
      !showMobileMenu &&
      !showMobileMenu &&
      !showCreateTrade &&
      !showActivate &&
      !showCloseTrade &&
      !showRequestPopup &&
      !showTradeModal &&
      !showVerifyModal
    ) {
      document.body.style.overflow = "unset";
    }
  }, [
    showLogoutPopup,
    showTwoFactor,
    showTwoFactor,
    showChangePassword,
    showMobileMenu,
    showCreateTrade,
    showActivate,
    showCloseTrade,
    showRequestPopup,
    showTradeModal,
    showVerifyModal,
  ]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  useEffect(() => {
    dispatch(getBanks());
    dispatch(getCurrencies());
  }, []);

  useEffect(() => {
    if (user && !user?.hasLoggedInBefore && !user?.isEnabled2FA) {
      dispatch(setShowTwoFactor(true));
    }

    if (user && !user?.hasLoggedInBefore) {
      dispatch(markUserAsLoggedIn());
    }
  }, [user]);

  return (
    <div className="layout_component">
      {userLoading ? (
        <PageLoading loading={true} />
      ) : (
        <>
          <Header />
          {children}
          {!hideFooter && <Footer />}
        </>
      )}
      {showLogoutPopup && <LogoutModal />}
      {showToast && <ActionMessages />}
      {showMobileMenu && <MobileMenu />}
      {showTwoFactor && <TwoFactorModal />}
      {showChangePassword && <ChangePasswordModal />}
      {showCreateTrade && <CreateTradeModal />}
      {showActivate && <ActivateInActivateTrade />}
      {showCloseTrade && <CloseTradeModal />}
      {showRequestPopup && <TradeRequestAccept />}
      {showTradeModal && <CloseChatModal />}
      {showVerifyModal && <VerifyInformModal />}
    </div>
  );
};
