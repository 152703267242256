import { FC, useRef } from "react";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";

import { ModalContainer } from "../ModalAuth";
import useOutsideClick from "hooks/useOutsideClick";
import { v4 as uuidv4 } from "uuid";

import closeIcon from "assets/images/close.svg";
import rightIcon from "assets/images/inputs/chevron-right.svg";
import leftIcon from "assets/images/inputs/chevron-left.svg";

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

interface IChatImagesModal {
  onClose: () => void;
  images: string[] | File[];
  selectedImageIndex: number;
}

export const ChatImagesModal: FC<IChatImagesModal> = ({
  onClose,
  images,
  selectedImageIndex,
}) => {
  const swiperRef = useRef<SwiperRef | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const swiperMainOptions = {
    slidesPerView: 1,
    loop: true,
    initialSlide: selectedImageIndex,
  };

  const goNext = () => {
    if (swiperRef.current && swiperRef.current) {
      swiperRef.current.swiper.slideNext?.();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current) {
      swiperRef.current.swiper.slidePrev?.();
    }
  };

  useOutsideClick({ ref, handler: onClose });

  return (
    <ModalContainer>
      <div className="modal passport_modal" ref={ref}>
        <div className="logout_header">
          <div className="close_modal">
            <img src={closeIcon} alt="Close" onClick={onClose} />
          </div>
        </div>
        <div className="images_field">
          <Swiper {...swiperMainOptions} ref={swiperRef}>
            {images.map((image) => (
              <SwiperSlide key={uuidv4()}>
                <img
                  src={
                    typeof image === "string"
                      ? `${imageUrl}${image}`
                      : URL.createObjectURL(image)
                  }
                  crossOrigin="anonymous"
                  alt="Passport"
                  className="swiper_image_element"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="arrow_container">
          <img
            src={leftIcon}
            alt="Left"
            className="chevron_icon"
            onClick={goPrev}
          />
          <img
            src={rightIcon}
            alt="Right"
            className="chevron_icon"
            onClick={goNext}
          />
        </div>
      </div>
    </ModalContainer>
  );
};
