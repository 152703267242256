import { FC } from "react";

import { IMainTableElement } from "types";
import { useTranslation } from "localization";
import { useAppSelector } from "reduxState/store";
import { formatDate } from "helpers";
import { IN_OUT_COME, IN_OUT_COME_NAME } from "constants/table";

import withdrawIcon from "assets/images/balance/withdraw.svg";

interface ITable {
  headerData: IMainTableElement[];
}

export const DesktopTable: FC<ITable> = ({ headerData }) => {
  const translation = useTranslation();

  const data = useAppSelector((state) => state.userData.balanceHistory);

  return (
    <div className="table_container_desktop">
      <table
        cellPadding="1"
        cellSpacing="1"
        bgcolor="#20212c"
        className="table"
      >
        <thead>
          <tr>
            {headerData.map((element) => (
              <th key={element.id} className="header_element">
                {translation[element.name]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((balance, index) => (
            <tr key={index}>
              <td className="table_body_element">{balance?.amount}</td>
              <td className="table_body_element">
                <div className="price_row">
                  <img
                    src={withdrawIcon}
                    alt="Currency"
                    className="amd_currency_icon"
                  />
                  <span>{balance?.type}</span>
                </div>
              </td>
              <td className="table_body_element">
                <div className="price_row">
                  <img
                    src={IN_OUT_COME[balance?.direction]}
                    alt="Currency"
                    className="amd_currency_icon"
                  />
                  <span>
                    {translation[IN_OUT_COME_NAME[balance?.direction]]}
                  </span>
                </div>
              </td>
              <td className="table_body_element">
                {formatDate(balance?.date)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
