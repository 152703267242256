import { Dispatch, FC, SetStateAction, useState } from "react";

import { useTranslation } from "localization";
import { FilterInput, FilterMultipleSelect } from "components";
import { ITableFilters } from "types";
import { STATUS_FILTER } from "constants/table";

interface ITableFiltersTypes {
  setFilters: Dispatch<SetStateAction<ITableFilters>>;
  openFilters: boolean;
}

export const TableFilters: FC<ITableFiltersTypes> = ({
  setFilters,
  openFilters,
}) => {
  const [date, setDate] = useState<string>("");
  const [nickname, setNickname] = useState<string>("");
  const [selectedBanksName, setSelectedBanksName] = useState<string[]>([]);
  const [selectedBanksId, setSelectedBanksId] = useState<number[]>([]);
  const [openBanks, setOpenBanks] = useState<boolean>(false);

  const translation = useTranslation();

  return (
    <div
      className={`table_filters transaction_table_filter ${
        openFilters ? "filters_open transaction_filters_open" : "filters_close"
      }`}
    >
      <div className="nickname_filter">
        <FilterInput
          name="id_nickname"
          value={nickname}
          setValue={setNickname}
          placeholder={translation.id}
          setFilters={setFilters}
        />
      </div>
      <div className="status_filter">
        <FilterMultipleSelect
          name="status"
          openSelect={openBanks}
          setOpenSelect={setOpenBanks}
          selectedNames={selectedBanksName}
          setSelectedNames={setSelectedBanksName}
          selectedIds={selectedBanksId}
          setSelectedIds={setSelectedBanksId}
          data={STATUS_FILTER}
          setFilters={setFilters}
        />
      </div>
      <div className="date_filter">
        <FilterInput
          name="date"
          value={date}
          setValue={setDate}
          placeholder={translation.date}
          setFilters={setFilters}
        />
      </div>
    </div>
  );
};
