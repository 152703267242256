import { useEffect } from "react";

import { useTranslation } from "localization";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { getSocialLinks } from "reduxState";

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

export const FooterSocial = () => {
  const data = useAppSelector((state) => state.content.socialLinks);

  const translation = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSocialLinks());
  }, []);

  return (
    <div className="footer_component_element">
      <h4 className="element_title">{translation.social_media}</h4>
      <ul className="footer_list social_list">
        {data?.map((item) => (
          <li className="item" key={item?.id}>
            <a href={item?.link} target="_blank" className="item_with_tag">
              <img
                src={`${imageUrl}${item?.icon}`}
                alt={item?.title}
                className="social_icon"
                crossOrigin="anonymous"
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
