import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { useTranslation } from "localization";
import { MENU } from "constants/header";
import { USER_TYPES } from "constants/global";
import { useAppSelector } from "reduxState/store";

export const HeaderMenu = () => {
  const [userType, setUserType] = useState<string>(USER_TYPES.guest);

  const user = useAppSelector((state) => state.auth.user);

  const translation = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleRoute = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    setUserType(!!user ? user?.role : USER_TYPES.guest);
  }, [user]);

  return (
    <ul className="menu_list">
      {MENU?.[userType]?.map((element) => (
        <li
          key={element.id}
          className={`list_item ${
            pathname === element.path ? "active_item" : ""
          }`}
          onClick={() => handleRoute(element.path)}
        >
          {translation[element.name]}
          {pathname === element.path && <div className="active_dot"></div>}
        </li>
      ))}
    </ul>
  );
};
