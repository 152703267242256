import { Dispatch, FC, MouseEvent, SetStateAction, useState } from "react";
import {
  FieldValues,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";

import { MultipleElement } from "./MultipleElement";
import { useAppSelector } from "reduxState/store";
import { IBank } from "types";

import arrowIcon from "assets/images/arrow-down-grey.svg";

interface ISelectItem {
  setValue: UseFormSetValue<FieldValues>;
  name: string;
  openSelect: boolean;
  setOpenSelect: Dispatch<SetStateAction<boolean>>;
  getValues: UseFormGetValues<FieldValues>;
  register: UseFormRegister<FieldValues>;
  isSubmitted: boolean;
  existingBanks?: IBank[];
}

export const SelectMultipleItem: FC<ISelectItem> = ({
  setValue,
  name,
  openSelect,
  setOpenSelect,
  getValues,
  register,
  isSubmitted,
  existingBanks,
}) => {
  const [selectedItemName, setSelectedItemName] = useState<string[]>([]);
  const [selectedItemId, setSelectedItemId] = useState<number[]>([]);

  const data = useAppSelector((state) => state.banksCurrencies.banks);

  const banksData = !!existingBanks ? existingBanks : data;

  const handleSelect = (
    e: MouseEvent<HTMLElement>,
    id: number,
    value: string,
    setChecked: Dispatch<SetStateAction<boolean>>
  ) => {
    e.preventDefault();

    if (selectedItemId.includes(id)) {
      const newSelectedItemName = selectedItemName.filter(
        (elem) => elem !== value
      );
      const newSelectedItemId = selectedItemId.filter((elem) => elem !== id);

      setSelectedItemId(newSelectedItemId);
      setSelectedItemName(newSelectedItemName);
      setValue(name, newSelectedItemId);
      setChecked(false);
    } else {
      const newSelectedItemName = [...selectedItemName, value];
      const newSelectedItemId = [...selectedItemId, id];

      setValue(name, newSelectedItemId);
      setSelectedItemName(newSelectedItemName);
      setSelectedItemId(newSelectedItemId);
      setChecked(true);
    }
  };

  const handleShowDropdown = () => {
    setOpenSelect(!openSelect);
  };

  return (
    <div className="input_filed_content">
      <input
        {...register(name, {})}
        className="select_input"
        id={name}
        name={name}
      />
      <div
        className={`custom_select_box ${openSelect ? "select_box_open" : ""}`}
        onClick={handleShowDropdown}
      >
        <p className="select_item_name">
          {Array.isArray(getValues(name)) && selectedItemName?.join(", ")}
        </p>
        <img
          src={arrowIcon}
          alt="Search"
          className={
            openSelect ? "rotate_select_adornment" : "select_adornment"
          }
        />
      </div>
      {openSelect && (
        <div className="select_dropdown multiple_select_dropdown">
          {banksData.map((element) => (
            <MultipleElement
              handleSelect={handleSelect}
              element={element}
              key={element.id}
              isChecked={selectedItemId.includes(element.id)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
