import { useEffect } from "react";

import { getForMerchants } from "reduxState";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { PageTitle } from "components";

export const ForMerchantsInfo = () => {
  const data = useAppSelector((state) => state.content.forMerchants);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getForMerchants());
  }, []);
  return (
    <div className="for_merchants_info">
      <PageTitle title={data.title} />
      <div className="info_description_content">
        <p className="info_description">{data.content}</p>
      </div>
    </div>
  );
};
