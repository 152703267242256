import { UserWallet } from "components";

import { ProfilePage } from "./Profile";

export const Wallets = () => {
  return (
    <ProfilePage>
      <UserWallet />
    </ProfilePage>
  );
};
