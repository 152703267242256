import { FC } from "react";

import { ITransactionTableFilter } from "types";
import { TableFilter, TextInput } from "components";
import { useTranslation } from "localization";
import { useForm } from "react-hook-form";

export const TableActions: FC<ITransactionTableFilter> = ({
  openFilters,
  setOpenFilters,
}) => {
  const translation = useTranslation();

  const { register, handleSubmit, setValue } = useForm();
  return (
    <div className="table_actions">
      <TextInput
        name="search"
        label=""
        isSearch={true}
        placeholder={translation.search}
        register={register}
        setValue={setValue}
      />
      <TableFilter openFilters={openFilters} setOpenFilters={setOpenFilters} />
    </div>
  );
};
