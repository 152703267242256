import withdrawIcon from "assets/images/balance/withdraw.svg";
import transferIcon from "assets/images/balance/transfer.svg";
import depositIcon from "assets/images/balance/deposit.svg";
import outcomeIcon from "assets/images/balance/outcome.svg";
import incomeIcon from "assets/images/balance/income.svg";

export const HOME_PAGE_TABS = [
  {
    id: 1,
    title: "all",
    value: "",
  },
  {
    id: 2,
    title: "buy",
    value: "buy",
  },
  {
    id: 3,
    title: "sell",
    value: "sell",
  },
];

export const HOME_PAGE_TABLE_HEADER = [
  {
    id: 1,
    name: "merchant",
  },
  {
    id: 2,
    name: "currency",
  },
  {
    id: 3,
    name: "crypto_coin",
  },
  {
    id: 4,
    name: "usd",
  },
  {
    id: 5,
    name: "usd_to_amd",
  },
  {
    id: 6,
    name: "banks",
  },
  {
    id: 7,
    name: "trade",
  },
];

export const HOME_PAGE_MERCHANT_TABLE_HEADER = [
  {
    id: 1,
    name: "merchant",
  },
  {
    id: 2,
    name: "currency",
  },
  {
    id: 3,
    name: "crypto_coin",
  },
  {
    id: 4,
    name: "usd",
  },
  {
    id: 5,
    name: "usd_to_amd",
  },
  {
    id: 6,
    name: "banks",
  },
];

export const CONTENT_DATA = {
  title: "",
  content: "",
};

export const CONTACT_US_DATA = {
  id: null,
  phone: "",
  address: "",
  email: "",
};

export const SOCIAL_LINKS_DATA = {
  id: null,
  title: "",
  link: "",
  icon: "",
};

export const BANKS_MAX_LENGTH = 3;

export const TABLE_BUY_SELL: { [key: string]: string } = {
  buy: "sell",
  sell: "buy",
};

export const PAGINATION_PER_PAGE = [
  {
    id: 1,
    count: 5,
  },
  {
    id: 2,
    count: 10,
  },
  {
    id: 3,
    count: 20,
  },
  {
    id: 4,
    count: 30,
  },
];

export const TRANSACTION_TABLE_HEADER = [
  {
    id: 1,
    name: "deal_id",
  },
  {
    id: 2,
    name: "currency",
  },
  {
    id: 3,
    name: "crypto_coin",
  },
  {
    id: 4,
    name: "usd_to_amd",
  },
  {
    id: 5,
    name: "status",
  },
  {
    id: 6,
    name: "date",
  },
  {
    id: 7,
    name: "action",
  },
];

export const BALANCE_TABLE_HEADER = [
  {
    id: 1,
    name: "amount",
  },
  {
    id: 2,
    name: "source",
  },
  {
    id: 3,
    name: "type",
  },
  {
    id: 4,
    name: "date",
  },
];

export const STATUS_FILTER = [
  {
    id: 1,
    title: "Canceled",
  },
  {
    id: 2,
    title: "Completed",
  },
  {
    id: 3,
    title: "Closed",
  },
];

export const BALANCE_TABLE_TABS = [
  {
    id: 1,
    title: "all",
    value: "",
  },
  {
    id: 2,
    title: "income",
    value: "income",
  },
  {
    id: 3,
    title: "outcome",
    value: "outcome",
  },
];

export const SOURCE_FILTER = [
  {
    id: 1,
    title: "Withdrawal",
    icon: withdrawIcon,
  },
  {
    id: 2,
    title: "Deposit",
    icon: depositIcon,
  },
  {
    id: 3,
    title: "Transfer",
    icon: transferIcon,
  },
];

export const IN_OUT_COME: { [key: string]: string } = {
  in: incomeIcon,
  out: outcomeIcon,
};

export const IN_OUT_COME_NAME: { [key: string]: string } = {
  in: "income",
  out: "outcome",
};
