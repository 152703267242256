import { useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "localization";
import { MainButton, TextInput } from "components";
import { FormContainer } from "../FormContainer";
import { InfoSection } from "./InfoSection";
import { useAppDispatch } from "reduxState/store";
import { setOpenToast, setToastData } from "reduxState/features/toast";
import { TOAST_MESSAGES, TOAST_TYPES } from "constants/toast";
import { GLOBAL, PASSWORD_REGEX, PATHNAMES } from "constants/global";
import { resetPassword } from "reduxState";

export const ResetForm = () => {
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [emailParam, setEmailParam] = useState<string>("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);
  const emailQuery = query.get(GLOBAL.email);
  const tokenQuery = query.get(GLOBAL.token);

  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleResetPassword = () => {
    dispatch(
      setToastData({
        type: TOAST_TYPES.success,
        message: TOAST_MESSAGES.reset_password,
        open: true,
      })
    );
  };

  const navigateHome = () => {
    handleResetPassword();
    navigate(PATHNAMES.sign_in);
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    dispatch(
      resetPassword({
        data: { ...data, email: emailParam },
        token: tokenQuery as string,
        navigate: navigateHome,
      })
    ).finally(() => {
      setTimeout(() => {
        dispatch(setOpenToast(false));
      }, 3000);
    });
  };

  useEffect(() => {
    if (emailQuery) {
      const email = atob(emailQuery);
      setEmailParam(email);
    }
  }, [emailQuery]);

  return (
    <div className="sign_up_form">
      <FormContainer>
        <div className="sign_up_content">
          <h1 className="form_title">{translation.reset_password}</h1>
          <form className="form_content" onSubmit={handleSubmit(onSubmit)}>
            <div className="image_input">
              <TextInput
                type={showNewPassword ? "text" : "password"}
                name="password"
                label={translation.new_password}
                isPassword={true}
                showPassword={showNewPassword}
                handleShowPassword={handleShowNewPassword}
                register={register}
                errors={errors}
                pattern={PASSWORD_REGEX}
                patternError={translation.pattern_error}
                setValue={setValue}
              />
              {!errors.password && <InfoSection />}
            </div>
            <TextInput
              type={showConfirmPassword ? "text" : "password"}
              name="passwordConfirm"
              label={translation.confirm_password}
              isPassword={true}
              showPassword={showConfirmPassword}
              handleShowPassword={handleShowConfirmPassword}
              register={register}
              errors={errors}
              setValue={setValue}
              validate={() => watch("password") === watch("passwordConfirm")}
            />
            <div className="sign_up_button">
              <MainButton
                name={translation.reset_password}
                customClass="custom_button width_100"
                type="submit"
              />
            </div>
          </form>
        </div>
      </FormContainer>
    </div>
  );
};
