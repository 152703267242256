import { FC, useRef } from "react";
import { ModalContainer } from "../ModalAuth";
import { IThankYou } from "types";

import closeIcon from "assets/images/close.svg";
import { useTranslation } from "localization";
import { MainButton } from "components/buttons";
import useOutsideClick from "hooks/useOutsideClick";

export const ThankYouModal: FC<IThankYou> = ({
  title,
  congratsMessage,
  infoMessage,
  icon,
  onClose,
  onClick,
}) => {
  const translation = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick({ ref, handler: onClose });

  return (
    <ModalContainer>
      <div className="modal" ref={ref}>
        <div className="logout_header">
          <div className="close_modal">
            <img src={closeIcon} alt="Close" onClick={onClose} />
          </div>
          <div className="logout_header_element">
            <img src={icon} alt="Logout" className="logout_image" />
            <p className="logout_title">{title}</p>
          </div>
        </div>
        <div>
          <div className="logout_body">
            <p className="logout_description">{congratsMessage}</p>
          </div>
          <div className="logout_body">
            <p className="logout_description">{infoMessage}</p>
          </div>
        </div>
        <div className="logout_actions">
          <MainButton
            name={translation.okay}
            customClass="custom_button width_100"
            onClick={onClick}
          />
        </div>
      </div>
    </ModalContainer>
  );
};
