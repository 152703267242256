import { IBalanceHistory } from "types";
import { createSlice } from "@reduxjs/toolkit";

import { getBalanceHistory, withdrawCurrency } from "reduxState";

const initialState: IBalanceHistory = {
  balanceHistory: [],
  balanceLoading: false,
  balanceTotal: 0,
  withdrawalData: {
    address: "",
    amount: "",
    currency: null,
    secret: "",
  },
  isPassedFirstStep: false,
  withdrawLoading: false,
};

export const userSlice = createSlice({
  name: "User",
  initialState: initialState,
  reducers: {
    setWithdrawalData: (state, { payload }) => {
      state.withdrawalData = payload;
    },
    setIsPassedFirstStep: (state, { payload }) => {
      state.isPassedFirstStep = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getBalanceHistory.pending, (state) => {
        state.balanceLoading = true;
      })
      .addCase(getBalanceHistory.fulfilled, (state, { payload }) => {
        state.balanceHistory = payload?.data;
        state.balanceTotal = payload?.total ? Number(payload?.total) : 0;
        state.balanceLoading = false;
      })
      .addCase(getBalanceHistory.rejected, (state) => {
        state.balanceLoading = false;
      });
    builder
      .addCase(withdrawCurrency.pending, (state) => {
        state.withdrawLoading = true;
      })
      .addCase(withdrawCurrency.fulfilled, (state, { payload }) => {
        state.withdrawLoading = false;
      })
      .addCase(withdrawCurrency.rejected, (state) => {
        state.withdrawLoading = false;
      });
  },
});

export const { setWithdrawalData, setIsPassedFirstStep } = userSlice.actions;

export default userSlice.reducer;
