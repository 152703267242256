import {
  Dispatch,
  FC,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Checkbox } from "../inputs";
import { GLOBAL } from "constants/global";

interface IPerPage {
  id: number;
  count: number;
  setPerPage: Dispatch<SetStateAction<number>>;
  perPage: number;
  isChecked: boolean;
  setPage: Dispatch<SetStateAction<number>>;
  handleAction?: () => void;
}

export const PerPageElement: FC<IPerPage> = ({
  count,
  id,
  setPerPage,
  perPage,
  isChecked,
  setPage,
  handleAction,
}) => {
  const [checked, setChecked] = useState<boolean>(perPage === count);

  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);

  const handleSetPerPage = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setPerPage(count);
    setPage(GLOBAL.default_page);
    query.set(GLOBAL.per_page, `${count}`);
    query.set(GLOBAL.page, `${GLOBAL.default_page}`);
    navigate(`?${query.toString()}`);
    setChecked(true);
    if (handleAction) handleAction();
  };

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <div className="per_page_element" onClick={(e) => handleSetPerPage(e)}>
      <span>{count}</span>
      <Checkbox checked={checked} setChecked={setChecked} />
    </div>
  );
};
