import { Dispatch, FC, MouseEvent, SetStateAction, useState } from "react";
import { Checkbox } from "../checkbox";
import { IBank } from "types";

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

interface ISelect {
  element: IBank;
  isChecked: boolean;
  icon?: string;
  handleSelect: (
    e: MouseEvent<HTMLElement>,
    id: number,
    value: string,
    setChecked: Dispatch<SetStateAction<boolean>>
  ) => void;
  isLocalIcon: boolean;
}

export const SelectElement: FC<ISelect> = ({
  handleSelect,
  element,
  isChecked,
  icon,
  isLocalIcon,
}) => {
  const [checked, setChecked] = useState<boolean>(isChecked);
  return (
    <div
      className="select_element"
      onClick={(e) =>
        handleSelect(
          e,
          element.id,
          element.full_title ? element.full_title : element.title,
          setChecked
        )
      }
    >
      <div className="select_left_section">
        {icon && (
          <img
            src={isLocalIcon ? icon : `${imageUrl}${icon}`}
            alt="Icon"
            className="select_icon"
            crossOrigin="anonymous"
          />
        )}
        {element?.full_title ? (
          <div className="element_name_field">
            <p className="element_name">{element.title}</p>
            <span className="currency_line"></span>
            <p className="element_name">{element.full_title}</p>
          </div>
        ) : (
          <p className="element_name">{element.title}</p>
        )}
      </div>
      <div className="select_right_section">
        <Checkbox checked={checked} setChecked={setChecked} />
      </div>
    </div>
  );
};
