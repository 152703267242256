import { FC } from "react";

import { IFormContainer } from "types";

export const FormContainer: FC<IFormContainer> = ({
  children,
  customClass = "",
}) => {
  return <div className={`form_container ${customClass}`}>{children}</div>;
};
