import { useRef } from "react";
import { useLocation } from "react-router-dom";

import { useTranslation } from "localization";
import { ModalContainer } from "../ModalAuth";
import { MainButton } from "components/buttons";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { setShowRequestPopup } from "reduxState/features/main";
import useOutsideClick from "hooks/useOutsideClick";
import { acceptCancelRequest, roomCreateAction } from "reduxState";
import { setOpenToast } from "reduxState/features/toast";
import { GLOBAL } from "constants/global";
import { TRADE_REQUEST_STATUSES } from "constants/trade";

import closeIcon from "assets/images/close.svg";
import activateIcon from "assets/images/trades/activatePopup.svg";
import inactivateIcon from "assets/images/trades/inactivatePopup.svg";

export const TradeRequestAccept = () => {
  const selectedRequestData = useAppSelector(
    (state) => state.main.selectedRequestData
  );
  const loading = useAppSelector((state) => state.trades.changeStatusLoading);
  const user = useAppSelector((state) => state.auth.user);

  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const query = new URLSearchParams(useLocation().search);
  const tradeId = query.get(GLOBAL.id);
  const page = query.get(GLOBAL.page);
  const perPage = query.get(GLOBAL.per_page);

  const ref = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    dispatch(
      setShowRequestPopup({
        isOpen: false,
        selectedRequestData: { id: "", isActivate: false, requester_id: "" },
      })
    );
  };

  const handleConfirm = () => {
    dispatch(
      acceptCancelRequest({
        id: selectedRequestData.id,
        data: {
          status: selectedRequestData.isActivate
            ? TRADE_REQUEST_STATUSES.accepted
            : TRADE_REQUEST_STATUSES.rejected,
        },
        navigate: handleClose,
        tradeId: Number(tradeId),
        params: {
          page: page ? `${page}` : `${GLOBAL.default_page}`,
          per_page: perPage ? `${perPage}` : `${GLOBAL.default_per_page}`,
          filters: {
            status: `${selectedRequestData.activeTab}`,
            state: "open",
          },
        },
      })
    ).then(() => {
      if (selectedRequestData.isActivate) {
        dispatch(
          roomCreateAction({
            data: {
              userIds: [`${user?.id}`, selectedRequestData.requester_id],
              requestId: selectedRequestData.id,
            },
          })
        );
      }

      setTimeout(() => {
        dispatch(setOpenToast(false));
      }, 3000);
    });
  };

  useOutsideClick({ ref, handler: handleClose });

  return (
    <ModalContainer>
      <div className="modal" ref={ref}>
        <div className="logout_header">
          <div className="close_modal">
            <img src={closeIcon} alt="Close" onClick={handleClose} />
          </div>
          <div className="logout_header_element">
            {selectedRequestData.isActivate ? (
              <img
                src={activateIcon}
                alt="Inactivate"
                className="logout_image"
              />
            ) : (
              <img
                src={inactivateIcon}
                alt="Activate"
                className="logout_image"
              />
            )}
            <p className="logout_title">
              {selectedRequestData.isActivate
                ? translation.accept_request
                : translation.cancel_request}
            </p>
          </div>
        </div>
        <div className="logout_body">
          <p className="logout_description">
            {selectedRequestData.isActivate
              ? translation.accept_request_text
              : translation.cancel_request_text}
          </p>
        </div>
        <div className="logout_actions">
          <MainButton
            name={translation.yes}
            customClass="custom_button width_100"
            onClick={handleConfirm}
            loading={loading}
            disabled={loading}
          />
          <MainButton
            name={translation.cancel}
            customClass="log_in_button width_100"
            onClick={handleClose}
            disabled={loading}
          />
        </div>
      </div>
    </ModalContainer>
  );
};
