import { FC } from "react";

import { TableActions } from "./TableActions";
import { IBalanceTableHeader } from "types";
import { MainTab } from "components";
import { BALANCE_TABLE_TABS } from "constants/table";

export const TableHeader: FC<IBalanceTableHeader> = ({
  openFilters,
  setOpenFilters,
  activeTab,
  setActiveTab,
}) => {
  return (
    <div className="table_header">
      <MainTab
        data={BALANCE_TABLE_TABS}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
      />
      <TableActions openFilters={openFilters} setOpenFilters={setOpenFilters} />
    </div>
  );
};
