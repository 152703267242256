import { useEffect } from "react";

import { SetPasswordForm } from "components";
import { GLOBAL } from "constants/global";

export const SetPassword = () => {
  useEffect(() => {
    localStorage.removeItem(GLOBAL.access_token);
  }, []);

  return (
    <div className="sign_up_container">
      <SetPasswordForm />
    </div>
  );
};
