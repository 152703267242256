import { FC } from "react";

interface IPageTitle {
  title: string;
}

export const PageTitle: FC<IPageTitle> = ({ title }) => {
  return (
    <div className="page_title_section">
      <h4 className="page_title">{title}</h4>
      <div className="underline"></div>
    </div>
  );
};
