import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "localization";
import { GLOBAL } from "constants/global";
import { IWallet } from "types";

import bitcoin from "assets/images/wallet/bitcoin.svg";

interface ICurrencyElement {
  element: IWallet;
}

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

export const CurrencyElement: FC<ICurrencyElement> = ({ element }) => {
  const query = new URLSearchParams(useLocation().search);
  const selectedWalletId = query.get(GLOBAL.wallet_id);

  const translation = useTranslation();
  const navigate = useNavigate();

  const handleSelect = () => {
    query.set(GLOBAL.wallet_id, `${element.id}`);
    navigate(`?${query.toString()}`);
  };

  return (
    <div
      className={`currency_element ${
        selectedWalletId === `${element.id}` ? "selected_currency_element" : ""
      }`}
      onClick={handleSelect}
    >
      <div className="currency_info">
        <img
          src={`${imageUrl}${element?.icon}`}
          crossOrigin="anonymous"
          alt="Currency"
          className="currency_icon"
        />
        <div className="currency_name_field">
          <p className="currency_name">{element.full_title}</p>
          <span className="currency_line"></span>
          <p className="currency_name">{element.title}</p>
        </div>
      </div>
      <div className="about_currency">
        <div className="element_info">
          <p className="title">{translation.quantity}</p>
          <p className="quantity_amount">{element.quantity}</p>
        </div>
        <div className="element_info right_element_info">
          <p className="title">{translation.amount}</p>
          <p className="quantity_amount amount_element">{`$${element.amount.toFixed(
            2
          )}`}</p>
        </div>
      </div>
    </div>
  );
};
