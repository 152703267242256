import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import {
  HomePage,
  SignUp,
  SignIn,
  ResetPassword,
  SetPassword,
  MerchantSignUp,
  ForMerchants,
  ProfileAccount,
  TermsOfUse,
  PrivacyPolicy,
  FaqPage,
  OngoingTrades,
  Transactions,
  BalanceHistory,
  Wallets,
  Withdraw,
  ForgotPassword,
} from "pages";
import { Layout } from "layout";
import { useAppDispatch, useAppSelector } from "./reduxState/store";
import { socket } from "./socket";
import { popLastTrade, setTradesFromSocket } from "reduxState/features/trades";
import { GLOBAL } from "constants/global";
import { setCurrenciesRate } from "./reduxState/features/banksCurrencies";

export const PagesRouter = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);

  const query = new URLSearchParams(useLocation().search);
  const perPage = query.get(GLOBAL.per_page) || GLOBAL.default_per_page;

  useEffect(() => {
    socket.on("connect", () => {
      console.log("Web socket is connected");
    });

    socket.on("emitTrade", (data) => {
      dispatch(setTradesFromSocket(data));
      dispatch(popLastTrade({ perPage: Number(perPage) }));
    });

    socket.on("currency_data", (data) => {
      dispatch(setCurrenciesRate(data));
    });

    socket.on("disconnect", () => {
      console.log("Web socket is disconnected");
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, [perPage]);

  useEffect(() => {
    setTimeout(() => setLoading(true), 0);
  }, []);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/for-merchants" element={<ForMerchants />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/set-password" element={<SetPassword />} />
        <Route path="/complete-registration" element={<MerchantSignUp />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/faq" element={<FaqPage />} />
        {!user ? (
          <>
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </>
        ) : (
          <>
            <Route path="/profile/account" element={<ProfileAccount />} />
            <Route path="/profile/transaction" element={<Transactions />} />
            <Route path="/profile/balance" element={<BalanceHistory />} />
            <Route path="/profile/wallet" element={<Wallets />} />
            <Route path="/profile/withdrawal" element={<Withdraw />} />
            <Route path="/ongoing-trades" element={<OngoingTrades />} />
          </>
        )}
        {loading && <Route path="*" element={<Navigate to="/" />} />}
      </Routes>
    </Layout>
  );
};
