import { IContent } from "types";
import { createSlice } from "@reduxjs/toolkit";

import {
  getForMerchants,
  getHomeContent,
  getAboutUs,
  getTermsOfUse,
  getPrivacyPolicy,
  getFaqData,
  getContactUs,
  getSocialLinks,
  sendContactInfo,
} from "reduxState";
import {
  CONTACT_US_DATA,
  CONTENT_DATA,
  SOCIAL_LINKS_DATA,
} from "constants/table";

const initialState: IContent = {
  forMerchants: CONTENT_DATA,
  merchantLoading: false,
  homeContent: CONTENT_DATA,
  homeContentLoading: false,
  aboutUs: CONTENT_DATA,
  aboutUsLoading: false,
  terms: CONTENT_DATA,
  termsLoading: false,
  privacyPolicy: CONTENT_DATA,
  privacyPolicyLoading: false,
  faqData: [],
  faqTotal: null,
  faqLoading: false,
  contactUs: CONTACT_US_DATA,
  contactUsLoading: false,
  socialLinks: [],
  socialLinksLoading: false,
  sendContactUsLoading: false,
};

export const contentSlice = createSlice({
  name: "Content",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getForMerchants.pending, (state) => {
        state.merchantLoading = true;
      })
      .addCase(getForMerchants.fulfilled, (state, { payload }) => {
        state.forMerchants = payload?.data ?? CONTENT_DATA;
        state.merchantLoading = false;
      })
      .addCase(getForMerchants.rejected, (state) => {
        state.merchantLoading = false;
      });
    builder
      .addCase(getHomeContent.pending, (state) => {
        state.homeContentLoading = true;
      })
      .addCase(getHomeContent.fulfilled, (state, { payload }) => {
        state.homeContent = payload?.data ?? CONTENT_DATA;
        state.homeContentLoading = false;
      })
      .addCase(getHomeContent.rejected, (state) => {
        state.homeContentLoading = false;
      });
    builder
      .addCase(getAboutUs.pending, (state) => {
        state.aboutUsLoading = true;
      })
      .addCase(getAboutUs.fulfilled, (state, { payload }) => {
        state.aboutUs = payload?.data ?? CONTENT_DATA;
        state.aboutUsLoading = false;
      })
      .addCase(getAboutUs.rejected, (state) => {
        state.aboutUsLoading = false;
      });
    builder
      .addCase(getTermsOfUse.pending, (state) => {
        state.termsLoading = true;
      })
      .addCase(getTermsOfUse.fulfilled, (state, { payload }) => {
        state.terms = payload?.data ?? CONTENT_DATA;
        state.termsLoading = false;
      })
      .addCase(getTermsOfUse.rejected, (state) => {
        state.termsLoading = false;
      });
    builder
      .addCase(getPrivacyPolicy.pending, (state) => {
        state.privacyPolicyLoading = true;
      })
      .addCase(getPrivacyPolicy.fulfilled, (state, { payload }) => {
        state.privacyPolicy = payload?.data ?? CONTENT_DATA;
        state.privacyPolicyLoading = false;
      })
      .addCase(getPrivacyPolicy.rejected, (state) => {
        state.privacyPolicyLoading = false;
      });
    builder
      .addCase(getFaqData.pending, (state) => {
        state.faqLoading = true;
      })
      .addCase(getFaqData.fulfilled, (state, { payload }) => {
        if (payload.page > 1) {
          state.faqData = [...state.faqData, ...payload?.data?.data];
        } else {
          state.faqData = payload?.data?.data ?? [];
        }

        state.faqTotal = payload?.data?.total;
        state.faqLoading = false;
      })
      .addCase(getFaqData.rejected, (state) => {
        state.faqLoading = false;
      });
    builder
      .addCase(getContactUs.pending, (state) => {
        state.contactUsLoading = true;
      })
      .addCase(getContactUs.fulfilled, (state, { payload }) => {
        state.contactUs = payload?.data ?? CONTACT_US_DATA;
        state.contactUsLoading = false;
      })
      .addCase(getContactUs.rejected, (state) => {
        state.contactUsLoading = false;
      });
    builder
      .addCase(getSocialLinks.pending, (state) => {
        state.socialLinksLoading = true;
      })
      .addCase(getSocialLinks.fulfilled, (state, { payload }) => {
        state.socialLinks = payload?.data ?? [SOCIAL_LINKS_DATA];
        state.socialLinksLoading = false;
      })
      .addCase(getSocialLinks.rejected, (state) => {
        state.socialLinksLoading = false;
      });
    builder
      .addCase(sendContactInfo.pending, (state) => {
        state.sendContactUsLoading = true;
      })
      .addCase(sendContactInfo.fulfilled, (state, { payload }) => {
        state.sendContactUsLoading = false;
      })
      .addCase(sendContactInfo.rejected, (state) => {
        state.sendContactUsLoading = false;
      });
  },
});

export default contentSlice.reducer;
