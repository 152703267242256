import { Dispatch, FC, SetStateAction, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { PAGINATION_PER_PAGE } from "constants/table";
import { PerPageElement } from "./PerPageElement";
import useOutsideClick from "hooks/useOutsideClick";
import { useTranslation } from "localization";
import { GLOBAL } from "constants/global";

import arrowIcon from "assets/images/arrow-down-grey.svg";

interface IPagination {
  perPage: number;
  total: number;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  setPerPage: Dispatch<SetStateAction<number>>;
  handleAction?: () => void;
  isDropDownUp?: boolean;
}

export const Pagination: FC<IPagination> = ({
  perPage,
  total,
  page,
  setPage,
  setPerPage,
  handleAction,
  isDropDownUp,
}) => {
  const [openPerPageSelector, setOpenPerPageSelector] =
    useState<boolean>(false);

  const pagesCount = Math.ceil(total / perPage);
  const ref = useRef<HTMLDivElement>(null);
  const translation = useTranslation();
  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);

  const handlePrevious = () => {
    if (page !== 1) {
      const newPage = page - 1;
      setPage(newPage);
      query.set(GLOBAL.page, `${newPage}`);

      navigate(`?${query.toString()}`);
      if (handleAction) handleAction();
    }
  };

  const handleNext = () => {
    if (page !== pagesCount) {
      const newPage = page + 1;
      setPage(newPage);
      query.set(GLOBAL.page, `${newPage}`);
      navigate(`?${query.toString()}`);
      if (handleAction) handleAction();
    }
  };

  const handleOpenCloseDropdown = () => {
    setOpenPerPageSelector(!openPerPageSelector);
  };

  const handleCloseDropdown = () => {
    setOpenPerPageSelector(false);
  };

  useOutsideClick({ ref, handler: handleCloseDropdown });

  return (
    <div className="pagination">
      <div className="per_page_row">
        <div className="per_page_text">{translation.rows_per_page}</div>
        <div
          className="per_page_selector"
          onClick={handleOpenCloseDropdown}
          ref={ref}
        >
          <span className="per_page_number">{perPage}</span>
          <img
            src={arrowIcon}
            alt="Arrow"
            className={`per_page_icon ${
              openPerPageSelector ? "rotate_icon" : ""
            }`}
          />
          {openPerPageSelector && (
            <div
              className={`per_page_dropdown ${
                isDropDownUp ? "per_page_dropdown_up" : ""
              }`}
            >
              {PAGINATION_PER_PAGE.map((element) => (
                <PerPageElement
                  count={element.count}
                  id={element.id}
                  key={element.id}
                  setPerPage={setPerPage}
                  perPage={perPage}
                  setPage={setPage}
                  isChecked={element.count === perPage}
                  handleAction={handleAction}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="page_selector">
        <img
          src={arrowIcon}
          alt="Arrow"
          className="previous_icon"
          onClick={handlePrevious}
        />
        <div className="selected_page">{`${page}/${pagesCount}`}</div>
        <img
          src={arrowIcon}
          alt="Arrow"
          className="next_icon"
          onClick={handleNext}
        />
      </div>
    </div>
  );
};
