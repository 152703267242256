import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import mainSlice from "./features/main";
import toastSlice from "./features/toast";
import authSlice from "./features/auth";
import contentSlice from "./features/content";
import tradesSlice from "./features/trades";
import banksCurrenciesSlice from "./features/banksCurrencies";
import userSlice from "./features/user";

const store = configureStore({
  reducer: {
    main: mainSlice,
    toast: toastSlice,
    auth: authSlice,
    content: contentSlice,
    trades: tradesSlice,
    banksCurrencies: banksCurrenciesSlice,
    userData: userSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
